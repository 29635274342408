import React, { useState } from "react";

import { SubmitHandler, useForm } from "react-hook-form";
import { FcGoogle } from "react-icons/fc";
import { TiVendorMicrosoft } from "react-icons/ti";
import { useNavigate, useSearchParams } from "react-router-dom";

import useAuthentication from "@services/authentication/useAuthentication";

import { accessTypeButtonStyle } from "./styles";

type CredentialsInput = {
  email: string;
  password: string;
};

type SigninContext = "brand-signin" | "retailer-signin";

export default function SignInForm() {
  const navigate = useNavigate();
  const { microsoftSignIn, googleSignIn, credentialsSignIn } =
    useAuthentication();
  const [searchParams] = useSearchParams();
  const [accessType, setAccessType] = useState<SigninContext>(
    (searchParams.get("context") as SigninContext) || "brand-signin",
  );
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<CredentialsInput>();

  const signInSubmit: SubmitHandler<CredentialsInput> = (data) =>
    credentialsSignIn(accessType, data.email, data.password).catch(() =>
      setError(
        "email",
        { type: "custom", message: "this email and password are invalid" },
        { shouldFocus: true },
      ),
    );

  return (
    <>
      <div className="grid grid-cols-2 gap-2 mb-4 p-2 bg-primaryLightElectricBlue rounded-t-lg">
        <button
          type="button"
          className={accessTypeButtonStyle({
            active: accessType === "brand-signin",
          })}
          onClick={() => setAccessType("brand-signin")}
        >
          I am a brand
        </button>
        <button
          type="button"
          className={accessTypeButtonStyle({
            active: accessType === "retailer-signin",
          })}
          onClick={() => setAccessType("retailer-signin")}
        >
          I am a retailer
        </button>
      </div>
      <div className="rounded-t mb-0 px-6 space-y-4">
        <h6 className="text-center mb-3  text-sm font-bold">Sign in with</h6>
        <div className="flex items-center gap-2 justify-center">
          <button
            className="hover:bg-gray-100 bg-white  px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
            type="button"
            title="google-signin"
            onClick={() => googleSignIn(accessType)}
          >
            <FcGoogle />
            Google
          </button>
          <button
            className="hover:bg-gray-100 bg-white  px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
            type="button"
            title="microsoft-signin"
            onClick={() => microsoftSignIn(accessType)}
          >
            <TiVendorMicrosoft />
            Microsoft
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-2 items-stretch px-4">
        <h6 className="text-center mb-3  text-sm font-bold">
          or with credentials
        </h6>
        <form onSubmit={handleSubmit(signInSubmit)}>
          <div className="relative w-full mb-3">
            <label
              className="block uppercase  text-xs font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              id="email"
              {...register("email", {
                required: "required",
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Entered value does not match email format",
                },
              })}
              type="email"
              className="border-0 px-3 py-3   bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              placeholder="Email"
            />
            {errors.email && (
              <span
                role="alert"
                title="email-alert"
                className="text-primaryRed"
              >
                {errors.email.message}
              </span>
            )}
          </div>
          <div className="relative w-full mb-3">
            <label
              className="block uppercase  text-xs font-bold mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <input
              id="password"
              {...register("password", { required: true })}
              type="password"
              className="border-0 px-3 py-3   bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              placeholder="Password"
            />
          </div>
          {errors.password && (
            <span role="alert" className="text-primaryRed">
              This field is required
            </span>
          )}
          <div className="flex gap-2 items-center">
            <button
              className="hover:underline text-xs uppercase px-6 py-3 rounded mr-1 mb-1 w-full"
              type="button"
              title="password-lost"
              onClick={() => navigate("/reset-password")}
            >
              Password lost ?
            </button>
            <button
              title="credentials-signin"
              className="hover:bg-gray-100 text-sm font-bold uppercase px-6 py-3 rounded shadow outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
              type="submit"
            >
              Sign in
            </button>
          </div>
        </form>
        <div className="flex gap-2 items-center mt-8 mb-4">
          <hr className="grow border-b-1 " />
          <span>OR</span>
          <hr className="grow border-b-1 " />
        </div>
        <div className="flex flex-col gap-2 items-stretch">
          <h6 className=" text-sm font-bold text-center">
            Your company doesn&apos;t have an account on ModaResa?
          </h6>
          <button
            type="button"
            className="hover:bg-gray-100 text-sm font-bold uppercase px-6 py-3 rounded shadow outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
            onClick={() => navigate("/signup")}
          >
            Sign up
          </button>
        </div>
      </div>
    </>
  );
}
