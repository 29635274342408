import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { brandSchema } from "@models/Brand";
import { collectionSchema } from "@models/Collection";
import { Organization } from "@models/Organization";
import {
  AccountStatusEnum,
  organizationAccountSchema,
} from "@models/OrganizationAccount";
import { organizationContactSchema } from "@models/OrganizationContact";
import { organizationRepresentativeSchema } from "@models/OrganizationRepresentative";
import { portfolioSchema } from "@models/Portfolio";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";

export const getFullAccountsItemSchema = organizationAccountSchema
  .pick({
    id: true,
    name: true,
    type: true,
    status: true,
    city: true,
    countryCode: true,
    continentCode: true,
    isKeyClient: true,
    organizationId: true,
  })
  .extend({
    contacts: z.array(organizationContactSchema.pick({ id: true })),
    collections: z.array(
      collectionSchema
        .pick({
          id: true,
          name: true,
          brandId: true,
        })
        .extend({
          status: AccountStatusEnum,
          brand: brandSchema.pick({
            id: true,
            name: true,
          }),
        }),
    ),
    portfolios: z.array(
      portfolioSchema.extend({
        sellers: z.array(
          organizationRepresentativeSchema.pick({
            id: true,
            firstName: true,
            lastName: true,
            role: true,
          }),
        ),
        manager: organizationRepresentativeSchema.pick({
          id: true,
          firstName: true,
          lastName: true,
          role: true,
        }),
      }),
    ),
  });

export type GetFullAccountsResponseItem = z.infer<
  typeof getFullAccountsItemSchema
>;
export type GetFullAccountsResponse = Array<GetFullAccountsResponseItem>;

export const getFullAccountsEndpoint = {
  path: (organizationId: Organization["id"]) =>
    `/organizations/${organizationId}/accounts/full`,
  call: (organizationId: Organization["id"]) =>
    axiosInstance
      .get<GetFullAccountsResponse>(
        getFullAccountsEndpoint.path(organizationId),
      )
      .then((res) => res.data),
};

export type GetFullAccountsParams = Organization["id"];

export const useGetFullAccounts = (organizationId: GetFullAccountsParams) =>
  useQuery({
    queryKey: getAPIQueryKey(getFullAccountsEndpoint.path(organizationId)),
    queryFn: () => getFullAccountsEndpoint.call(organizationId),
  });
