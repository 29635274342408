import React, { useState } from "react";

import { Portal } from "@headlessui/react";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { FaRegHourglassHalf } from "react-icons/fa6";
import { HiOutlinePlusCircle, HiOutlineUpload } from "react-icons/hi";
import {
  HiOutlineBuildingOffice2,
  HiOutlineUserCircle,
  HiOutlineUsers,
} from "react-icons/hi2";
import { MdOutlineUnfoldMore } from "react-icons/md";
import { useMatch, useNavigate } from "react-router-dom";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";

import { useCRMDrawerContext } from "@app/modules/crm/context/crm-drawers";
import { PageHeader, PageTitle } from "@components/data-display/Page";
import Tabs, { TabsProps } from "@components/data-display/Tabs";
import Modal, { useModal } from "@components/feedback/Modal";
import { useBreakpoint } from "@components/layout/Breakpoint";
import { Button } from "@components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@components/ui/dropdown-menu";
import { ImportOrganizationAccounts } from "@services/api/accounts/ImportOrganizationAccounts";
import { useGetFullAccounts } from "@services/api/accounts/get-full-accounts";
import { ImportOrganizationContacts } from "@services/api/contacts/ImportOrganizationContacts";
import { useFetchAccountsCount } from "@services/api/old/accounts/fetch-accounts-count";
import { useFetchAccountsArchivedCount } from "@services/api/old/accounts/fetch-archived-count";
import { useFetchContactsArchivedCount } from "@services/api/old/contacts/fetch-archived-count";
import { useFetchContactsCount } from "@services/api/old/contacts/fetch-contacts-count";
import { GetOrderDeadline } from "@services/api/order-deadline/GetOrderDeadline";
import { useGetCompetitorsGroupsCount } from "@services/api/organization/get-competitors-groups-count";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import { AccessControl } from "@shared/components/access-control";
import {
  ACL_ADMINS_AGENTS_MANAGERS,
  ACL_ADMINS_MANAGERS,
  useUserRole,
} from "@shared/components/access-control/helpers";

import { getNextFourFashionShows } from "../helpers/find-season";
import OrderDeadlineFormCRM from "./OrderDeadlineFormCRM";
import { useAccountImport, useContactImport } from "./SpreadsheetImporter";

export default function CRMHeader() {
  const { t } = useTranslation();
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();

  const [uploadAccountModal, setUploadAccountModal] = useState<boolean>(false);
  const [uploadContactModal, setUploadContactModal] = useState<boolean>(false);

  const { isAdmin, isManager } = useUserRole();

  const navigate = useNavigate();
  const modalState = useModal();
  const match = useMatch("/crm/*");

  const currentFile =
    match && match?.params ? Object.values(match!.params)[0] : "";

  // queries
  const { data: accounts } = useGetFullAccounts(organizationId);
  const { data: accountsCount } = useFetchAccountsCount(organizationId);
  const { data: accountsArchivedCount } =
    useFetchAccountsArchivedCount(organizationId);
  const { data: contactsCount } = useFetchContactsCount(organizationId);
  const { data: contactsArchivedCount } =
    useFetchContactsArchivedCount(organizationId);
  const { data: competitorsGroupsCount } = useGetCompetitorsGroupsCount(
    {
      organizationId,
    },
    {
      enabled: isAdmin || isManager,
    },
  );

  // mutations
  const { mutateAsync: importAccounts } = ImportOrganizationAccounts.useHook({
    organizationId,
  });
  const { mutateAsync: importContacts } = ImportOrganizationContacts.useHook({
    organizationId,
  });
  const crmContext = useCRMDrawerContext();

  const crmTabs: TabsProps["items"] = [
    {
      label: t("CRM.header.tabs.accounts"),
      tab: "accounts",
      count: accountsCount?.count,
      aclRoles: ACL_ADMINS_AGENTS_MANAGERS,
    },
    {
      label: t("CRM.header.tabs.contacts"),
      tab: "contacts",
      count: contactsCount?.count,
      aclRoles: ACL_ADMINS_AGENTS_MANAGERS,
    },
    {
      label: t("CRM.header.tabs.competitors-groups"),
      tab: "competitors",
      count: competitorsGroupsCount?.count,
      aclRoles: ACL_ADMINS_MANAGERS,
    },
    {
      label: t("CRM.header.tabs.accounts-archived"),
      tab: "archived-accounts",
      count: accountsArchivedCount?.count,
      aclRoles: ACL_ADMINS_AGENTS_MANAGERS,
    },
    {
      label: t("CRM.header.tabs.contacts-archived"),
      tab: "archived-contacts",
      count: contactsArchivedCount?.count,
      aclRoles: ACL_ADMINS_AGENTS_MANAGERS,
    },
  ];

  const currentTab = crmTabs.find((item) => item.tab === currentFile)?.tab;

  //* order deadline
  const { organization } = useOrganizationAppContext();
  const brandId = organization.brand?.id;

  const upcomingSeasons = getNextFourFashionShows(new Date(), brandId); // set the today's date as a starting point
  const nextSeason = upcomingSeasons[0];

  const { data: nextOrderDeadline } = GetOrderDeadline.useHook({
    brandId,
    season: nextSeason.season,
    year: nextSeason.year,
  });
  const accountImport = useAccountImport();
  const contactImport = useContactImport({
    accounts: accounts || [],
  });
  const isDesktop = useBreakpoint("md");

  return (
    <>
      <PageHeader>
        <PageTitle>{t("CRM.header.title")}</PageTitle>
        <div className="px-4 flex flex-col md:flex-row gap-2">
          {organization.type === "BRAND" && brandId && (
            <div>
              <Button
                size={isDesktop ? "lg" : "sm"}
                className="rounded-full"
                variant={nextOrderDeadline ? "ghost" : "default"}
                onClick={modalState.open}
              >
                {!nextOrderDeadline ? (
                  t("Shared.order-deadline.add-order-deadlines")
                ) : (
                  <>
                    <div className="text-primaryDarkGrey flex items-center space-x-1">
                      <FaRegHourglassHalf className="my-auto w-2.5 mr-1" />
                      <p>
                        {t("Shared.order-deadline.order-deadline-season", {
                          season: nextOrderDeadline.season,
                          year: nextOrderDeadline.year,
                        })}
                      </p>
                    </div>
                    <p className="flex items-center ">
                      {format(nextOrderDeadline.deadline, "MMM d, yyyy")}
                    </p>
                    <MdOutlineUnfoldMore />
                  </>
                )}
              </Button>
              <Modal
                title={t("Shared.order-deadline.order-deadlines")}
                state={modalState}
                centeredTitle
                padding="p-6"
                aria-label={t("Shared.order-deadline.order-deadlines")}
              >
                <OrderDeadlineFormCRM
                  brandId={brandId}
                  onSuccess={modalState.close}
                  initialValues={{
                    deadline: nextOrderDeadline?.deadline,
                    season: nextSeason.season,
                    year: nextSeason.year,
                  }}
                />
              </Modal>
            </div>
          )}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button className="rounded-full" size="lg">
                <span>{t("CRM.header.add-new")}</span>
                <HiOutlinePlusCircle className="w-6 h-6" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <AccessControl roles={ACL_ADMINS_AGENTS_MANAGERS}>
                <DropdownMenuItem
                  onClick={() => {
                    navigate("/crm/accounts");
                    crmContext.openAccountUpsertDrawer();
                  }}
                >
                  <HiOutlineBuildingOffice2 className="w-6 h-6" />
                  {t("CRM.header.add-new-account")}
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => {
                    navigate("/crm/contacts");
                    crmContext.openContactUpsertDrawer();
                  }}
                >
                  <HiOutlineUserCircle className="w-6 h-6" />
                  {t("CRM.header.add-new-contact")}
                </DropdownMenuItem>
              </AccessControl>
              <AccessControl roles={ACL_ADMINS_MANAGERS}>
                <DropdownMenuItem
                  onClick={() => {
                    navigate("/crm/competitors");
                    crmContext.openCompetitorsGroupUpsertDrawer();
                  }}
                >
                  <HiOutlineUsers className="w-6 h-6" />
                  {t("CRM.header.add-new-competitors-group")}
                </DropdownMenuItem>
              </AccessControl>
              <AccessControl roles={ACL_ADMINS_AGENTS_MANAGERS}>
                <DropdownMenuSeparator />
                <DropdownMenuGroup>
                  <DropdownMenuLabel className="text-gray-300">
                    <HiOutlineUpload className="w-6 h-6" />
                    {t("CRM.header.upload")}
                  </DropdownMenuLabel>
                  <DropdownMenuItem
                    onClick={() => {
                      setUploadAccountModal(true);
                    }}
                  >
                    {t("CRM.header.upload-accounts-spreadsheet")}
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => {
                      setUploadContactModal(true);
                    }}
                  >
                    {t("CRM.header.upload-contacts-spreadsheet")}
                  </DropdownMenuItem>
                </DropdownMenuGroup>
              </AccessControl>
            </DropdownMenuContent>
          </DropdownMenu>
          <Portal>
            <ReactSpreadsheetImport
              autoMapSelectValues
              autoMapHeaders
              autoMapDistance={0.8}
              isOpen={uploadAccountModal}
              onClose={() => setUploadAccountModal(false)}
              fields={accountImport.fields}
              translations={accountImport.translations}
              onSubmit={async (data) => {
                await importAccounts({
                  accounts:
                    data.validData as ImportOrganizationAccounts.Input["accounts"],
                });
                setUploadAccountModal(false);
                setUploadContactModal(true);
              }}
            />
          </Portal>
          <Portal>
            <ReactSpreadsheetImport
              autoMapSelectValues
              autoMapHeaders
              isOpen={uploadContactModal}
              onClose={() => setUploadContactModal(false)}
              onSubmit={async (data) => {
                await importContacts({
                  contacts: data.validData.map((d) => ({
                    ...d,
                    markets: d.markets?.toString().split(",") || [],
                  })) as unknown as ImportOrganizationContacts.Input["contacts"],
                });
                setUploadContactModal(false);
              }}
              fields={contactImport.fields}
              translations={contactImport.translations}
              rowHook={contactImport.rowHook}
            />
          </Portal>
        </div>
      </PageHeader>
      <Tabs
        aria-label="CRM-tabs"
        className="mt-6 mb-4 border-b"
        items={crmTabs}
        tab={currentTab}
        handleClick={(item) => {
          navigate(`/crm/${item.tab}`);
        }}
      />
    </>
  );
}
