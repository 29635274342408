import { useMutation, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { toast } from "react-toastify";
import { z } from "zod";

import { collectionSchema } from "@models/Collection";
import axiosInstance from "@services/api/config";
import { GetOrganizationCollectionsEndpoint } from "@services/api/organization/get-collections";
import { GetOrganizationCollectionsWithCapabilitiesEndpoint } from "@services/api/organization/get-collections-with-capabilities";

export namespace CollectionArchiveEndpoint {
  export const inputSchema = z.object({
    collectionId: z.string().uuid(),
  });
  export type Input = z.infer<typeof inputSchema>;

  export const outputSchema = collectionSchema.pick({
    id: true,
    name: true,
  });
  export type Output = z.infer<typeof outputSchema>;

  interface HookParams {
    organizationId: string;
  }

  interface MutationParams {
    collectionId: string;
  }

  type Params = HookParams & MutationParams;

  export const path = ({ organizationId, collectionId }: Params) =>
    `organizations/${organizationId}/collections/${collectionId}/archive`;

  export const call = (params: Params) =>
    axiosInstance
      .put(path(params), inputSchema.parse(params))
      .then((res) => res.data)
      .catch((error) => {
        // display a toast if the statusCode is 409, the message should be something like "there is an active showroom"
        if (error.statusCode === 409) {
          toast.error(
            "Failed to delete because an active showroom exists for this collection.",
          );
        } else {
          toast.error(
            t("OrganizationProfile.collections.failed.collection-deleted"),
          );
        }
        throw error;
      });
  export function useHook(hookParams: HookParams) {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (mutationParams: MutationParams) =>
        call({
          ...mutationParams,
          ...hookParams,
        }),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: GetOrganizationCollectionsEndpoint.getQueryKeys(hookParams),
        });
        queryClient.invalidateQueries({
          queryKey:
            GetOrganizationCollectionsWithCapabilitiesEndpoint.getQueryKeys(
              hookParams,
            ),
        });
      },
    });
  }
}
