import React, { HTMLProps } from "react";

import { useTranslation } from "react-i18next";

type Props = {
  id?: string;
  htmlFor?: HTMLProps<HTMLLabelElement>["htmlFor"];
  children: React.ReactNode;
  error?: string;
  description?: string;
  addFontWeight?: Boolean;
  className?: string;
};

export default function InputLabel({
  children,
  className,
  htmlFor,
  error,
  description,
  id,
  addFontWeight,
}: Props) {
  const { t } = useTranslation();
  return (
    <label
      id={id}
      className={`flex flex-col ${addFontWeight ? "font-medium" : ""} ${className}`}
      htmlFor={htmlFor}
    >
      <span>
        {children}
        {error && (
          <span className="ml-4 text-xs italic text-primaryRed">
            {t(error)}
          </span>
        )}
      </span>
      {description && (
        <span className="inline-block w-full text-xs text-primaryGrey">
          {description}
        </span>
      )}
    </label>
  );
}
