import { ShowroomSeason, ShowroomSeasonList } from "@models/types/enums";

export interface UpcomingSeason {
  season: ShowroomSeason;
  year: number;
  brandId?: string | undefined;
}

// function to calculate the next four fashion show seasons based on today's date
export const getNextFourFashionShows = (
  currentDate: Date,
  brandId?: string,
) => {
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // adjusted to represent the actual month (1 = January, 12 = December)

  interface FashionSeason {
    name: string;
    startMonth: number;
    endMonth: number;
  }

  const fashionSeasons: FashionSeason[] = [
    { name: ShowroomSeasonList[1], startMonth: 2, endMonth: 3 }, // AW
    { name: ShowroomSeasonList[2], startMonth: 4, endMonth: 5 }, // PRE_SS
    { name: ShowroomSeasonList[3], startMonth: 9, endMonth: 10 }, // SS
    { name: ShowroomSeasonList[0], startMonth: 12, endMonth: 1 }, // PRE_AW
  ];

  // store all the upcoming seasons
  const upcomingSeasons: UpcomingSeason[] = [];

  // find the upcoming season
  const findNextSeason = (referenceMonth: number) => {
    let currentSeason = fashionSeasons.find(
      (season) =>
        (referenceMonth >= season.startMonth &&
          referenceMonth <= season.endMonth) || // falls between start and end
        (season.startMonth > season.endMonth &&
          (referenceMonth >= season.startMonth ||
            referenceMonth <= season.endMonth)), // for seasons spanning year-end (e.g., dec-jan)
    );

    // if the current month is not in any season's range, find the next closest upcoming season
    if (!currentSeason) {
      [currentSeason] = fashionSeasons
        .filter((season) => season.startMonth >= referenceMonth) // filter seasons starting after current month
        .sort((a, b) => a.startMonth - b.startMonth); // find the closest future season

      // if no future season exists (meaning the rest are next year), get the earliest season
      if (!currentSeason) {
        [currentSeason] = fashionSeasons.sort(
          (a, b) => a.startMonth - b.startMonth,
        );
      }
    }

    return currentSeason;
  };

  let referenceMonth = currentMonth;
  let referenceYear = currentYear;

  while (upcomingSeasons.length < 4) {
    const currentSeason = findNextSeason(referenceMonth);

    // fashion weeks are made to showcase future seasonal collections, around 6 months in advance
    let monthsAhead = currentSeason.endMonth + 6;

    if (currentSeason.endMonth < currentSeason.startMonth) {
      // e.g. // PRE_AW -> automatically add one more year since it spans over the year-end (monthsAhead will be less than 12)
      referenceYear += 1;
    }
    if (monthsAhead > 12) {
      referenceYear += 1; // increment referenceYear by 1 if monthsAhead exceeds 12
      monthsAhead %= 12;
    }

    if (currentSeason) {
      const seasonDetails: UpcomingSeason = {
        season: currentSeason.name as ShowroomSeason,
        year:
          currentSeason.name === ShowroomSeasonList[2] // this condition is needed because PRE_SS's endMonth + 6 will always be less than 12
            ? referenceYear + 1 // therefore, it won't change the year, but PRE_SS should always refer to the next year
            : referenceYear,
      };

      if (brandId) {
        seasonDetails.brandId = brandId;
      }

      upcomingSeasons.push(seasonDetails);

      referenceMonth = currentSeason.endMonth + 1; //  use the month after the endMonth as the new reference
    }
  }

  return upcomingSeasons;
};
