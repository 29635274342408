import React, { useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import InputLabel from "@components/data-display/InputLabel";
import Button from "@components/data-entry/Button";
import MultiSelect from "@components/data-entry/MultiSelect";
import TextField from "@components/data-entry/TextField";

import { createCollectionTitle, marketCategoriesGrouped } from "./helpers";

const formValuesSchema = z.object({
  collectionName: z.string().nullable(),
  marketCategories: z.array(z.string()).optional(),
});
type FormValues = z.infer<typeof formValuesSchema>;

const validFormValuesSchema = z.object({
  collectionName: z.string().min(1, "Collection name is required"),
  marketCategories: z
    .array(z.string())
    .min(1, "At least one market category must be selected"),
});

function CollectionForm({
  onSuccess,
  onSubmit,
  initialValues,
}: {
  onSuccess?: () => Promise<void>;
  onSubmit: (data: FormValues) => Promise<void>;
  initialValues: FormValues;
}) {
  const { t } = useTranslation();

  const {
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors: formErrors },
    clearErrors,
  } = useForm<FormValues>({
    defaultValues: initialValues,
    resolver: zodResolver(validFormValuesSchema),
  });

  // handle form submission
  const onSubmitForm = async (data: FormValues) => {
    const parseCollectionValues = validFormValuesSchema.safeParse(data);

    if (parseCollectionValues.success) {
      await onSubmit({
        collectionName: parseCollectionValues.data.collectionName,
        marketCategories: parseCollectionValues.data.marketCategories,
      });

      onSuccess?.();
    }
  };

  // track whether the collectionName autofill should be enabled or disabled
  const [shouldAutofillCollectionName, SetShouldAutofillCollectionName] =
    useState(true);

  // watch the selected market categories
  const selectedMarketCategories = watch("marketCategories");

  const handleMarketCategoriesChange = (selectedValues: string[]) => {
    // generate the collection title and set it, if autofill is enabled
    if (shouldAutofillCollectionName) {
      const collectionTitle = createCollectionTitle(selectedValues, t);
      setValue("collectionName", collectionTitle ?? "");
      clearErrors("collectionName");
    }
  };

  const isUpdating = Boolean(initialValues.collectionName);

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <div className="mb-2 pt-8">
        <InputLabel
          error={formErrors.marketCategories?.message}
          htmlFor="marketCategories"
          addFontWeight
        >
          {t("OrganizationProfile.collections.market-categories")}
        </InputLabel>
      </div>
      <div>
        <Controller
          name="marketCategories"
          control={control}
          render={({ field: { onChange, onBlur } }) => (
            <MultiSelect
              id="marketCategories"
              placeholder={t(
                "OrganizationProfile.collections.market-categories-placeholder",
              )}
              value={selectedMarketCategories?.map((category) => ({
                label: t(`Common.market-category.${category}`),
                value: category,
              }))}
              onChange={(selectedOptions) => {
                const selectedValues = selectedOptions.map(
                  (option) => option.value,
                );
                onChange(selectedValues);
                handleMarketCategoriesChange(selectedValues);
              }}
              onBlur={onBlur}
              options={marketCategoriesGrouped.map((group) => ({
                label: t(`Common.market-category-group.${group.label}`),
                options: group.options.map((option) => ({
                  label: t(`Common.market-category-group.${option.label}`),
                  value: option.value,
                })),
              }))}
              className="min-w-80 max-w-[40rem]"
              aria-label="select market category"
            />
          )}
        />
      </div>
      <div className="mb-2 pt-4">
        <InputLabel
          error={formErrors.collectionName?.message}
          htmlFor="collectionName"
          addFontWeight
        >
          {t("OrganizationProfile.collections.collection-name")}
        </InputLabel>
        <div>
          <Controller
            name="collectionName"
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              <TextField
                className="mt-2 rounded-md"
                id="collectionName"
                placeholder={t(
                  "OrganizationProfile.collections.collection-name-placeholder",
                )}
                value={value || ""}
                onChange={onChange}
                onBlur={onBlur}
                onClearField={() => onBlur()}
                onKeyDown={() => {
                  SetShouldAutofillCollectionName(value === "");
                }}
              />
            )}
          />
        </div>
      </div>

      <Button
        className="whitespace-nowrap text-primaryElectricBlue hover:underline mt-4 flex w-full"
        theme="SECONDARY"
        justify="center"
        type="submit"
      >
        {isUpdating
          ? t("OrganizationProfile.collections.update-collection")
          : t("OrganizationProfile.collections.add-collection")}
      </Button>
    </form>
  );
}

export default CollectionForm;
