import React from "react";

import { TbPlugConnectedX } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

import useAuthentication from "@services/authentication/useAuthentication";

function StopImpersonation() {
  const navigate = useNavigate();
  const { stopImpersonation, impersonation } = useAuthentication();

  if (!impersonation) {
    return null;
  }

  return (
    <div className="fixed left-5 top-5">
      <button
        type="button"
        onClick={() => {
          stopImpersonation();
          navigate("/");
        }}
        className="rounded-full w-12 h-12 bg-statusOrangeDark flex items-center justify-center"
      >
        <TbPlugConnectedX size={30} />
      </button>
    </div>
  );
}

export default StopImpersonation;
