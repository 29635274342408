import React, {
  ChangeEvent,
  HTMLProps,
  KeyboardEvent,
  useCallback,
  useId,
  useReducer,
} from "react";

import { Portal, Transition } from "@headlessui/react";
import { HiOutlineSearch } from "react-icons/hi";
import { IoIosClose } from "react-icons/io";
import { equals } from "remeda";
import { useDebounce, useOnClickOutside, useUpdateEffect } from "usehooks-ts";

import { fadeInOutProps } from "@components/transitions/FadeInOut";
import usePopup from "@helpers/Popup";

type AutocompleteStates = "idle" | "typing" | "suggesting";

interface InputAction {
  type: "input";
  input: string;
}
interface CursorAction {
  type: "cursor";
  cursor: number;
}
interface SelectAction {
  type: "select";
  filter: any;
}
interface ResetAction {
  type: "reset";
  value: any;
}
interface StartSuggestingAction {
  type: "start-suggesting";
  results: any[];
}
interface ErrorAction {
  type: "error";
  error: any;
}
interface BasicActions {
  type: "focus";
}

type Actions =
  | InputAction
  | CursorAction
  | SelectAction
  | ResetAction
  | StartSuggestingAction
  | ErrorAction
  | BasicActions;

export interface AutocompleteState<O> {
  state: AutocompleteStates;
  filter: string;
  results: O[];
  error: string | null;
  cursor: number | null;
  gridHasBeenRendered: boolean;
}

function makeInitialState(filter: string): AutocompleteState<any> {
  return {
    state: "idle",
    filter,
    results: [],
    error: null,
    cursor: null,
    gridHasBeenRendered: false,
  };
}

function autocompleteReducer(
  state: AutocompleteState<any>,
  action: Actions,
): AutocompleteState<any> {
  switch (action.type) {
    case "start-suggesting":
      return {
        ...state,
        state: "suggesting",
        results: action.results,
        gridHasBeenRendered: true,
      };
    case "reset":
      return {
        ...state,
        state: "idle",
        filter: action.value,
      };
    case "cursor":
      return {
        ...state,
        cursor:
          (action.cursor >= 0
            ? action.cursor
            : state.results.length + action.cursor) % state.results.length,
      };
    case "input":
      return { ...state, state: "typing", filter: action.input };
    case "focus":
      return { ...state, filter: "" };
    case "select":
      return {
        ...state,
        state: "idle",
        filter: action.filter,
        gridHasBeenRendered: false,
      };
    case "error":
      return { ...state, error: action.error };
    default:
      throw new Error(
        `AutocompleteSelect - unhandled action : ${JSON.stringify(action)}`,
      );
  }
}

interface UseAutocompleteProps {
  filterValue: string;
  renderValue: AutoCompleteSelectProps<any>["renderValue"];
  filterResults: AutoCompleteSelectProps<any>["filterResults"];
  onChange: AutoCompleteSelectProps<any>["onChange"];
  isDisabled: boolean;
  gridRef: React.MutableRefObject<HTMLElement | null>;
}

function useAutocomplete({
  filterResults,
  filterValue,
  renderValue,
  onChange,
  isDisabled,
  gridRef,
}: UseAutocompleteProps) {
  const [state, dispatch] = useReducer(
    autocompleteReducer,
    makeInitialState(filterValue),
  );
  const debouncedCursor = useDebounce(state.cursor, 100);
  const debouncedFilter = useDebounce<string>(state.filter, 500);

  useUpdateEffect(() => {
    gridRef.current?.children.item(debouncedCursor || 0)?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  }, [debouncedCursor]);

  useUpdateEffect(() => {
    // avoid filtering when selecting an option (which changes the filter value);
    if (state.state === "typing") {
      filterResults(debouncedFilter)
        .then((results) => dispatch({ type: "start-suggesting", results }))
        .catch((error) => dispatch({ type: "error", error }));
    }
  }, [filterResults, debouncedFilter]);

  // FILTER INPUT INTERACTIONS
  const fireFocus = () => {
    if (isDisabled) return;
    dispatch({ type: "focus" });
  };
  const fireInput = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: "input", input: e.target.value });
  };
  const fireReset = useCallback(() => {
    dispatch({ type: "reset", value: filterValue });
  }, [filterValue]);
  const fireClear = () => {
    dispatch({ type: "select", filter: "" });
    onChange(undefined);
  };

  // GRID INTERACTIONS
  const fireSelect = (option: any) => {
    dispatch({ type: "select", filter: renderValue(option) });
    onChange(option);
  };
  const fireCursor = (index: number) => {
    dispatch({ type: "cursor", cursor: index });
  };
  const fireCursorIncrement = () => {
    fireCursor((state.cursor || 0) + 1);
  };
  const fireCursorDecrement = () => {
    fireCursor((state.cursor || 0) - 1);
  };

  function handleKeyDown(e: KeyboardEvent) {
    const capturedKeys = ["ArrowUp", "ArrowDown", "Enter"];
    // don't capture anything but those keys
    if (capturedKeys.indexOf(e.key) === -1) {
      return true;
    }

    e.preventDefault();
    e.stopPropagation();

    const isArrowUp = e.key === "ArrowUp";
    const isArrowDown = e.key === "ArrowDown";
    const isEnter = e.key === "Enter";
    const cursorIsSet = state.cursor !== null;

    if (isArrowDown) {
      return cursorIsSet ? fireCursorIncrement() : fireCursor(0);
    }

    if (isArrowUp) {
      return cursorIsSet
        ? fireCursorDecrement()
        : fireCursor(state.results.length - 1);
    }

    if (isEnter) {
      fireSelect(state.results[state.cursor || 0]);
    }
    return false;
  }

  // Those conditions are used to handle the CSS animations
  // 1. !isGridVisible + !isDisplayingResults is the initial state
  // 2. !isGridVisible + isDisplayingResults is right before the results are to be shown
  // 3. isGridVisible + isDisplayingResults is during results suggestion
  // 4. back to 2.
  // 5. back to 1
  const isDisplayingResults =
    state.state === "suggesting" ||
    (state.state === "typing" && state.gridHasBeenRendered);

  return {
    state,
    gridRef,
    isDisplayingResults,
    fireClear,
    fireReset,
    fireCursor,
    fireCursorDecrement,
    fireCursorIncrement,
    handleKeyDown,
    fireSelect,
    fireInput,
    fireFocus,
  };
}

interface AutoCompleteSelectProps<O>
  extends Omit<HTMLProps<HTMLInputElement>, "value" | "onChange"> {
  onChange: (option: O) => void;
  emptyComponent: () => JSX.Element;
  optionComponent: (props: OptionComponentProps<O>) => JSX.Element;
  filterResults: (filter: AutocompleteState<O>["filter"]) => Promise<O[]>;
  renderValue: (option: O) => string;
  value?: O;
  containerClassName?: string;
}

export default function AutoCompleteSelect<O>({
  value,
  onChange,
  optionComponent: RenderOptionComponent,
  emptyComponent: RenderEmptyComponent,
  filterResults,
  renderValue,
  className,
  containerClassName,
  ...rest
}: AutoCompleteSelectProps<O>) {
  const filterValue = value ? renderValue(value) : "";
  const isDisabled = rest.disabled || rest.readOnly || false;

  const popup = usePopup({
    placement: "bottom",
    hasOffset: [0, 0],
    sameWidth: true,
  });

  const {
    fireClear,
    fireFocus,
    fireInput,
    fireSelect,
    fireReset,
    handleKeyDown,
    isDisplayingResults,
    state,
  } = useAutocomplete({
    filterValue,
    renderValue,
    filterResults,
    onChange,
    isDisabled,
    gridRef: popup.popupElement,
  });
  const id = useId();

  useOnClickOutside(popup.popupElement, fireReset);

  return (
    <div
      className="w-full relative"
      role="group"
      aria-label={rest["aria-label"]}
    >
      <div
        id={`combobox-${id}`}
        className={`flex items-center item border border-primaryGrey enabled:focus:border-primaryElectricBlue enabled:hover:border-primaryElectricBlue cursor-pointer rounded-lg py-1 px-2 ${
          isDisabled ? "bg-primaryLightestGrey cursor-default" : ""
        } ${containerClassName || ""}`}
        ref={popup.setReferenceElement}
      >
        <div className="justify-center -mr-px">
          <HiOutlineSearch className="w-5 h-5 text-gray-600" />
        </div>
        <input
          type="text"
          role="combobox"
          aria-haspopup="grid"
          aria-expanded={isDisplayingResults}
          aria-autocomplete="list"
          aria-controls={`grid-${id}`}
          id={`input-${id}`}
          className={`h-10 px-3 outline-none w-full border-gray-500 ${
            isDisabled ? "bg-primaryLightestGrey" : ""
          } ${className || ""}`}
          onFocus={fireFocus}
          onChange={fireInput}
          value={state.filter}
          onKeyDown={handleKeyDown}
          {...rest}
        />
        {!isDisabled && (
          <button
            type="button"
            name={`clear-textfield-${id}`}
            title="clear field"
            className="cursor-pointer"
            onClick={fireClear}
          >
            <IoIosClose className="w-8 h-8 text-gray-400 mr-1" />
          </button>
        )}
      </div>
      <Portal as="div" className="fixed z-select">
        <div
          ref={popup.setPopupElement}
          style={popup.styles.popper}
          {...popup.attributes.popper}
        >
          <Transition show={isDisplayingResults} {...fadeInOutProps}>
            <div
              role="grid"
              id={`grid-${id}`}
              aria-label={rest["aria-label"]}
              className="w-full bg-white border border-gray-300 rounded flex flex-col overflow-y-auto max-h-80 -t-1 ease-in-out"
            >
              {state.results.length === 0 ? (
                <RenderEmptyComponent />
              ) : (
                state.results.map((option: O, index: number) => (
                  <RenderOptionComponent
                    // eslint-disable-next-line react/no-array-index-key
                    key={`autocomplete-option-${index}`}
                    option={option}
                    isSelected={equals(value, option)}
                    isCursorOn={state.cursor === index}
                    onClick={() => fireSelect(option)}
                  />
                ))
              )}
            </div>
          </Transition>
        </div>
      </Portal>

      {state.error && (
        <p className="mt-1 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
          {state.error}
        </p>
      )}
    </div>
  );
}

export interface OptionComponentProps<O> {
  option: O;
  isSelected: boolean;
  isCursorOn: boolean;
  onClick: () => void;
}
export function OptionComponent<O>({
  isCursorOn,
  isSelected,
  onClick,
  children,
}: Omit<OptionComponentProps<O>, "option"> & {
  children: React.ReactNode;
}) {
  const className = [
    "flex items-center",
    "px-4 py-2",
    "cursor-pointer",
    "hover:bg-gray-100",
  ];
  if (isSelected) {
    className.push("bg-blue-200");
  } else if (isCursorOn) {
    className.push("bg-yellow-100");
  }

  return (
    <button
      type="button"
      role="row"
      onClick={onClick}
      className={className.join(" ")}
    >
      {children}
    </button>
  );
}
