import { z } from "zod";

import { EmailStatusList, MarketTypesList } from "@models/types/enums";

export const organizationContactSchema = z.object({
  id: z.string().uuid(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  emailStatus: z.enum(EmailStatusList),
  accountId: z.string().uuid(),
  phoneNumber: z.string().nullable(),
  position: z.string().nullable(),
  markets: z
    .array(z.enum(MarketTypesList))
    .refine((items) => new Set(items).size === items.length, {
      message: "Must be an array of unique strings",
    }),
});

export type OrganizationContact = z.infer<typeof organizationContactSchema>;
