import React from "react";

import { useQuery } from "@tanstack/react-query";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import { HiLink } from "react-icons/hi2";

import MRTable from "@components/data-display/MRTable";
import {
  Page,
  PageHeader,
  PageSection,
  PageTitle,
} from "@components/data-display/Page";
import { Button } from "@components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@components/ui/tooltip";
import axiosInstance from "@services/api/config";
import useAuthentication from "@services/authentication/useAuthentication";

type Buyer = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: "CONTACT_BUYER";
  phoneNumber: string | null;
};

const columnHelper = createColumnHelper<Buyer>();

function BuyerActionsCell({ row }: CellContext<Buyer, unknown>) {
  const { impersonateBuyer } = useAuthentication();
  return (
    <Button
      variant="ghost"
      size="icon"
      className="rounded-full"
      onClick={() => impersonateBuyer(row.original.email)}
    >
      <HiLink className="size-6" />
    </Button>
  );
}

export default function BackOfficeListBuyersPage() {
  const { data: buyers } = useQuery<Buyer[]>({
    queryKey: ["backoffice", "buyers"],
    queryFn: () =>
      axiosInstance
        .get<Buyer[]>("/backoffice/buyers")
        .then((response) => response.data),
  });

  const columns = [
    columnHelper.accessor("id", { header: "ID" }),
    columnHelper.accessor("firstName", { header: "First Name" }),
    columnHelper.accessor("lastName", { header: "Last Name" }),
    columnHelper.accessor("email", { header: "Email" }),
    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: BuyerActionsCell,
    }),
  ];
  return (
    <TooltipProvider>
      <Page>
        <PageTitle>Buyers</PageTitle>
        <PageSection>
          <PageHeader>
            <Tooltip>
              <TooltipTrigger>
                <Button disabled>Add Buyer</Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Coming soon</p>
              </TooltipContent>
            </Tooltip>
          </PageHeader>
        </PageSection>
        <PageSection>
          <MRTable data={buyers} columns={columns} />
        </PageSection>
      </Page>
    </TooltipProvider>
  );
}
