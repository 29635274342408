import React from "react";

import AppointmentCollectionItem from "@booking/components/appointment/collection-item";
import Tag from "@components/data-display/Tag";
import Button from "@components/data-entry/Button";
import Breakpoint, { useIsMobile } from "@components/layout/Breakpoint";
import { formatDatetimeIntervalAtTimezone } from "@helpers/Date";
import { usePrefixedTranslation } from "@helpers/Translation";
import { Collection } from "@models/Collection";
import { Showroom } from "@models/Showroom";
import { fullName } from "@shared/helpers/formatters";
import { translateAppointmentType } from "@shared/helpers/translater";

import { useBookingContext } from "../hook";

interface AppointmentCollectionListProps {
  activeTab: number;
  showroom: Pick<Showroom, "id" | "timezone" | "specialRequestsAllowed"> & {
    collections: Pick<Collection, "id" | "name">[];
  };
  onSelect: (index: number) => void;
  onClickSpecialRequest: () => void;
}

export default function AppointmentCollectionList({
  showroom,
  onSelect,
  onClickSpecialRequest,
  activeTab,
}: AppointmentCollectionListProps) {
  const { t } = usePrefixedTranslation("");
  const { state, dispatch } = useBookingContext();
  const isMobile = useIsMobile();
  return (
    <ul
      className={`flex grow overscroll-contain overflow-scroll ${
        isMobile ? "flex-row border-b" : "flex-col border-r"
      } border-primaryLightGrey`}
      aria-label="container-slot-select-collections"
    >
      {state.appointments.map((appt, index) => {
        const collection = "collection" in appt ? appt.collection : undefined;
        let status: "PENDING" | "CANCELLED" | "OK" = "PENDING";
        if (appt.cancelled) {
          status = "CANCELLED";
        }
        if (appt.bookedAppointment) {
          status = "OK";
        }
        return (
          <AppointmentCollectionItem
            onDelete={() => {
              dispatch({ type: "CANCEL_APPOINTMENT", index });
            }}
            key={`slot-select-collection-${appt.showroomId}-${
              collection?.id || appt.type
            }`}
            title={collection?.name || t(translateAppointmentType(appt.type))}
            subtitle={
              appt.bookedAppointment
                ? formatDatetimeIntervalAtTimezone(
                    appt.bookedAppointment.startTime,
                    appt.bookedAppointment.endTime,
                    appt.bookedAppointment.showroom.timezone,
                  )
                : undefined
            }
            id={collection?.id || appt.type}
            status={status}
            tags={appt.attendees.map((p) => fullName(p))}
            isSelected={activeTab === index}
            onSelectTab={() => onSelect(index)}
          >
            {appt.specialRequest && (
              <Breakpoint from="lg">
                <Tag size="sm" className="mt-4 w-fit">
                  {t(
                    "Booking.AppointmentBookingSlotSelect.special-request-sent",
                  )}
                </Tag>
              </Breakpoint>
            )}
            {!appt.bookedAppointment &&
              !appt.specialRequest &&
              showroom.specialRequestsAllowed && (
                <Breakpoint from="lg">
                  <Button
                    type="button"
                    theme="SECONDARY"
                    onClick={onClickSpecialRequest}
                    className="mt-4 justify-center"
                  >
                    {t(
                      "Booking.AppointmentBookingSlotSelect.special-request-button",
                    )}
                  </Button>
                </Breakpoint>
              )}
          </AppointmentCollectionItem>
        );
      })}
    </ul>
  );
}
