import React, { HTMLProps, useRef, useState } from "react";

import { Portal, Transition } from "@headlessui/react";
import { cva } from "class-variance-authority";
import { IoIosClose } from "react-icons/io";
import { useToggle } from "usehooks-ts";

import Button from "@components/data-entry/Button";
import Backdrop from "@components/layout/Backdrop";

interface UseDrawerProps {
  initial?: boolean;
  onClose?: () => void;
  backdrop?: boolean;
  needsConfirmation?: boolean;
}

export function useDrawer({
  onClose,
  initial = false,
  backdrop = true,
  needsConfirmation = false,
}: UseDrawerProps = {}) {
  const [isOpen, toggleDrawer, setIsOpen] = useToggle(initial);
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const drawerRef = useRef<HTMLDivElement>(null);

  const openDrawer = () => {
    setIsOpen(true);
  };
  const closeWithoutConfirmation = () => {
    setIsOpen(false);
    setIsConfirming(false);
    if (onClose) onClose();
  };
  const closeWithConfirmation = () => {
    if (needsConfirmation) {
      // asking confirmation
      setIsConfirming(true);
    } else {
      closeWithoutConfirmation();
    }
  };
  const cancelClosingDrawer = () => {
    setIsConfirming(false);
  };

  return {
    // props needed by the Drawer component
    props: {
      closeWithConfirmation,
      drawerRef,
      isOpen,
      backdrop,
    },
    closeWithoutConfirmation,
    drawerRef,
    isOpen,
    isConfirming,
    cancelClosingDrawer,
    closeWithConfirmation,
    onClose: () => {
      if (backdrop) setIsOpen(false);
      if (onClose) onClose();
    },
    openDrawer,
    setIsOpen: (v: boolean) => setIsOpen(v),
    toggleDrawer,
  };
}

type UseDrawerReturn = ReturnType<typeof useDrawer>;
type DrawerSize = "MEDIUM" | "LARGE" | "EXTRALARGE";

interface DrawerProps
  extends Omit<HTMLProps<HTMLDivElement>, "size" | "as" | "ref"> {
  backdrop: boolean;
  isScrollable?: boolean;
  drawerTitle?: React.ReactNode;
  size?: DrawerSize;
  headerPadding?: string;
  isOpen: UseDrawerReturn["isOpen"];
  drawerRef: UseDrawerReturn["drawerRef"];
  closeWithConfirmation: UseDrawerReturn["closeWithConfirmation"];
}

export const drawerSizeVariants = cva("w-full ", {
  variants: {
    size: {
      MEDIUM: "lg:w-[45vw] xl:w-[41vw] 2xl:w-[35vw]",
      LARGE: "lg:w-[44vw] xl:w-[42vw] 2xl:w-[42vw]",
      EXTRALARGE: "lg:w-[60vw] xl:w-[50vw] 2xl:w-[45vw]",
    },
  },
});

export default function Drawer({
  isOpen,
  isScrollable = true,
  drawerRef,
  drawerTitle = undefined,
  headerPadding = "px-10",
  children,
  className,
  name = "drawer",
  backdrop = false,
  size = "MEDIUM",
  closeWithConfirmation,
  ...divProps
}: DrawerProps) {
  const sizeProperties = drawerSizeVariants({ size });

  // drawers come from the bottom of the screen on smaller screens
  const mobileScreen = `h-[80%] max-lg:inset-x-0 max-lg:bottom:0 top-[20%] w-full rounded-t-3xl`;
  // drawers come from the right of the screen on larger screens
  const largerScreen = `lg:h-full lg:rounded-none lg:top-0 lg:right-0 ${sizeProperties}`;

  return (
    <Portal as="div" className="fixed top-0 left-0 z-drawer">
      <Transition.Root show={isOpen} className="w-screen h-screen">
        <Transition.Child
          className="transition-all duration-300 ease-in-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {backdrop && <Backdrop onClick={closeWithConfirmation} />}
        </Transition.Child>
        <Transition.Child
          enterFrom="max-lg:translate-y-full lg:translate-x-full"
          enterTo="max-lg:translate-y-0 lg:translate-x-0"
          leaveFrom="max-lg:translate-y-0 lg:translate-x-0"
          leaveTo="max-lg:translate-y-full lg:translate-x-full"
          aria-label={name}
          ref={drawerRef}
          className={`fixed transition duration-300 ease-in-out ${mobileScreen} ${largerScreen} bg-white shadow-lg`}
        >
          <div
            className={`h-full w-full flex flex-col bg-white overflow-y-auto no-scrollbar ${className}`}
            {...divProps}
          >
            <div className={`flex justify-end pt-10 ${headerPadding}`}>
              {drawerTitle && <div className="w-full">{drawerTitle}</div>}
              <Button
                theme="ICON"
                label={`${name} close button`}
                type="button"
                className="cursor-pointer"
                onClick={closeWithConfirmation}
              >
                <IoIosClose className="w-8 h-8" />
              </Button>
            </div>
            <div
              className={`flex flex-col grow ${
                isScrollable ? "overflow-y-auto" : ""
              }`}
            >
              {children}
            </div>
          </div>
        </Transition.Child>
      </Transition.Root>
    </Portal>
  );
}

export function DrawerBody({
  className,
  children,
  ...rest
}: HTMLProps<HTMLDivElement>) {
  return (
    <div className={`px-4 ${className || ""}`} {...rest}>
      {children}
    </div>
  );
}
