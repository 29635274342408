import React, { useState } from "react";

import { Portal } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { Field } from "react-spreadsheet-import/types/types";

import { Button } from "@components/ui/button";
import { accountSchema } from "@models/Account";
import { AccountStatusEnum } from "@models/OrganizationAccount";
import { MarketType, MarketTypesList } from "@models/types/enums";
import { GetOrganizationCollectionsEndpoint } from "@services/api/organization/get-collections";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import { continentOptions } from "@services/continents";
import { countriesOptions } from "@services/countries";
import { translateAccountType } from "@shared/helpers/translater";

export function useContactImport({
  accounts,
}: {
  accounts: { id: string; name: string }[];
}): {
  fields: Field<string>[];
  translations: Record<string, string>;
  rowHook: (
    row: Record<string, string | boolean | undefined>,
    addError: (
      key: string,
      error: { message: string; level: "info" | "error" },
    ) => void,
  ) => Record<string, string | boolean | undefined>;
} {
  const { t, i18n } = useTranslation();

  const translations = i18n.getResource(
    i18n.language,
    "translation",
    "SpreadsheetImporter.contact.translations",
  );

  // used for validation && transformation
  const rowHook = (
    row: Record<string, string | boolean | undefined>,
    addError: (
      key: string,
      error: { message: string; level: "info" | "error" },
    ) => void,
  ) => {
    const markets = (row.markets?.toString().toUpperCase() || "")
      .split(",")
      .map((m) => m.trim());
    if (markets.length === 0) {
      addError("markets", {
        message: t("SpreadsheetImporter.contact.markets.error.required"),
        level: "error",
      });
    }
    if (
      markets.some(
        (m) => !Object.values(MarketTypesList).includes(m as MarketType),
      )
    ) {
      addError("markets", {
        message: t("SpreadsheetImporter.contact.markets.error.invalid"),
        level: "error",
      });
    }

    return {
      ...row,
      markets: markets.join(","),
      position: row.position || "",
      phoneNumber: row.phoneNumber || "",
    };
  };

  return {
    fields: [
      {
        label: t("SpreadsheetImporter.contact.firstName.label"),
        key: "firstName",
        alternateMatches: [
          "first name",
          "Prénom",
          "Contact first name",
          "firstname",
        ],
        fieldType: {
          type: "input",
        },
      },
      {
        label: t("SpreadsheetImporter.contact.lastname.label"),
        key: "lastName",
        alternateMatches: ["last name", "Nom", "contact last name", "lastname"],
        fieldType: {
          type: "input",
        },
      },
      {
        label: t("SpreadsheetImporter.contact.email.label"),
        key: "email",
        alternateMatches: [
          "email address",
          "Email",
          "email address",
          "contact email",
        ],
        validations: [
          {
            rule: "unique",
            errorMessage: t("SpreadsheetImporter.contact.email.unique"),
            level: "error",
          },
        ],
        fieldType: {
          type: "input",
        },
      },
      {
        label: t("SpreadsheetImporter.contact.phone.label"),
        key: "phoneNumber",
        alternateMatches: [
          "phone number",
          "phone",
          "tel",
          "mobile",
          "contact phone",
        ],
        fieldType: {
          type: "input",
        },
      },
      {
        label: t("SpreadsheetImporter.contact.accountId.label"),
        key: "accountId",
        alternateMatches: [
          "account id",
          "account",
          "compte",
          "nom du compte",
          "account name",
        ],
        validations: [
          {
            rule: "required",
            errorMessage: t("SpreadsheetImporter.contact.accountId.required"),
            level: "error",
          },
        ],
        fieldType: {
          type: "select",
          options: accounts.map(({ id, name }) => ({
            label: name,
            value: id,
          })),
        },
      },
      {
        label: t("SpreadsheetImporter.contact.position.label"),
        key: "position",
        alternateMatches: ["position", "poste", "job", "contact position"],
        fieldType: {
          type: "input",
        },
      },
      {
        label: t("SpreadsheetImporter.contact.markets.label"),
        key: "markets",
        alternateMatches: ["markets", "marchés"],
        fieldType: {
          type: "input",
        },
      },
    ],
    translations,
    rowHook,
  };
}

export function useAccountImport(): {
  fields: Field<string>[];
  translations: Record<string, string>;
} {
  const { t, i18n } = useTranslation();

  const translations = i18n.getResource(
    i18n.language,
    "translation",
    "SpreadsheetImporter.contact.translations",
  );

  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();
  const { data: collections = [] } = GetOrganizationCollectionsEndpoint.useHook(
    {
      organizationId,
    },
  );

  return {
    fields: [
      {
        label: t("SpreadsheetImporter.name.label"),
        key: "name",
        alternateMatches: [
          "Account name",
          "name",
          "company",
          "organization",
          "société",
          "entreprise",
          "nom",
        ],
        fieldType: {
          type: "input",
        },
        example: "Galeries Lafayette",
        validations: [
          {
            rule: "required",
            errorMessage: t("SpreadsheetImporter.name.required"),
            level: "error",
          },
        ],
      },
      {
        label: t("SpreadsheetImporter.isKeyClient.label"),
        key: "isKeyClient",
        alternateMatches: ["key client", "key client?"],
        fieldType: {
          type: "checkbox",
        },
      },
      {
        label: t("SpreadsheetImporter.type.label"),
        key: "type",
        alternateMatches: [
          "Account type",
          "type",
          "type d'entreprise",
          "type d'organisation",
        ],
        fieldType: {
          type: "select",
          options: Object.values(accountSchema.shape.type.Values).map((v) => ({
            label: t(translateAccountType(v)),
            value: v,
          })),
        },
        example: Object.values(accountSchema.shape.type.Values).join(", "),
        validations: [
          {
            rule: "required",
            errorMessage: t("SpreadsheetImporter.type.required"),
            level: "error",
          },
          {
            rule: "regex",
            value: `(${Object.values(accountSchema.shape.type.Values).join(")|(")})`,
            errorMessage: t("SpreadsheetImporter.type.invalid"),
            level: "error",
          },
        ],
      },
      {
        label: t("SpreadsheetImporter.city.label"),
        key: "city",
        alternateMatches: ["Account main city", "city", "ville"],
        example: "Paris, Milan, New York",
        fieldType: {
          type: "input",
        },
      },
      {
        label: t("SpreadsheetImporter.country.label"),
        key: "countryCode",
        alternateMatches: [
          "Account main country",
          "country",
          "pays",
          "country code",
        ],
        example: "FR, IT, US",
        fieldType: {
          type: "select",
          options: countriesOptions,
        },
      },
      {
        label: t("SpreadsheetImporter.continent.label"),
        key: "continentCode",
        alternateMatches: ["continent", "continent code"],
        example: "EU, NA, SA, AS, AF or OC",
        fieldType: {
          type: "select",
          options: continentOptions,
        },
      },
      ...collections.map(
        ({ brand: { name: brandName }, name, id }) =>
          ({
            label: t(
              "SpreadsheetImporter.status.label-{{brandName}}-{{name}}",
              {
                brandName,
                name,
              },
            ),
            key: id,
            alternateMatches: [name],
            example: "ACTIVE, PROSPECT, NOT_ACCESSIBLE",
            fieldType: {
              type: "select",
              options: Object.values(AccountStatusEnum.Values).map(
                (status) => ({
                  label: status,
                  value: status,
                }),
              ),
            },
          }) satisfies Field<string>,
      ),
    ],
    translations,
  };
}

export default function SpreadsheetImporter({
  fields,
}: {
  fields: Field<string>[];
}) {
  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = console.log;
  return (
    <>
      <Button
        className="rounded-full"
        type="button"
        onClick={() => setIsOpen(true)}
      >
        Upload spreadsheet
      </Button>
      <Portal>
        <ReactSpreadsheetImport
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onSubmit={onSubmit}
          fields={fields}
        />
      </Portal>
    </>
  );
}
