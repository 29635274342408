/* eslint-disable react/no-unstable-nested-components */
import React from "react";

import { createColumnHelper } from "@tanstack/react-table";
import { flag } from "country-emoji";

import MRTable from "@components/data-display/MRTable";
import Checkbox from "@components/data-entry/Checkbox";
import { OrganizationAccount } from "@models/OrganizationAccount";

interface AccountOriginCellProps {
  city: string;
  countryCode: string;
}

function AccountOriginCell(props: AccountOriginCellProps) {
  const { city, countryCode } = props;
  return <i>{`${flag(countryCode)} ${city}, ${countryCode}`}</i>;
}

type AccountRow = Pick<
  OrganizationAccount,
  "id" | "name" | "status" | "countryCode" | "city"
> & {
  portfolioName?: string;
  canBeSelected: boolean;
};

interface AccountsTableProps {
  accounts: AccountRow[];
  selectedAccounts: string[];
  onSelectionChange: (selectedAccounts: string[]) => void;
}

function AccountsTable(props: AccountsTableProps) {
  const { accounts, selectedAccounts, onSelectionChange } = props;
  const columnHelper = createColumnHelper<AccountRow>();

  const columns = React.useMemo(
    () => [
      columnHelper.display({
        id: "select",
        cell: ({ row }) =>
          row.getCanSelect() ? (
            <Checkbox
              id={`account-${row.original.id}`}
              name="selectedAccounts[]"
              value={row.original.id}
              checked={row.getIsSelected()}
              onChange={row.getToggleSelectedHandler()}
              aria-label={`${row.getIsSelected() ? "unselect" : "select"} ${
                row.original.name
              }`}
            />
          ) : null,
        header: ({ table }) =>
          table.getRowModel().rows.some((r) => r.getCanSelect()) ? (
            <Checkbox
              aria-label="select/deselect all rows"
              name="all-accounts"
              id="select-all-accounts"
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          ) : null,
      }),
      columnHelper.accessor("name", {
        cell: (info) => info.getValue(),
        header: "Account",
      }),
      columnHelper.accessor("city", {
        id: "origin",
        cell: ({ getValue, row }) => (
          <AccountOriginCell
            city={getValue()}
            countryCode={row.original.countryCode}
          />
        ),
        header: "Origin",
      }),
      columnHelper.accessor("status", {
        cell: (info) =>
          info.getValue() === "PROSPECT" ? "Prospect" : "Active",
        header: "Status",
      }),
      columnHelper.accessor("portfolioName", {
        cell: (info) => info.getValue(),
        header: "Portfolio",
      }),
    ],
    [columnHelper],
  );

  return (
    <div className="flex flex-col border rounded-md">
      <MRTable
        data={accounts}
        columns={columns}
        rowSelectionOption={{
          onSelectionChange,
          initialSelection: selectedAccounts,
          canBeSelected: (row) => row.original.canBeSelected,
        }}
      />
    </div>
  );
}

export default AccountsTable;
