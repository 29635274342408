import React, { PropsWithChildren } from "react";

import { drawerSizeVariants } from "@components/feedback/Drawer";

export function DrawerFooter({ children }: PropsWithChildren) {
  return (
    <footer
      className={drawerSizeVariants({
        size: "MEDIUM",
        className: "fixed bottom-0 right-0 bg-white mt-2",
      })}
    >
      <hr className="border-t border-t-gray-400 py-2" />
      <div className="flex justify-center">
        <div className="flex justify-center">{children}</div>
      </div>
    </footer>
  );
}
