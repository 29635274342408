import React, { useMemo } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { HiOutlinePlusCircle } from "react-icons/hi2";
import { IoIosClose } from "react-icons/io";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import { z } from "zod";

import InputLabel from "@components/data-display/InputLabel";
import Button from "@components/data-entry/Button";
import { inputStyle } from "@components/data-entry/TextField";
import { DrawerFooter } from "@components/layout/DrawerFooter";
import { GetBuyerBrands } from "@services/api/brands/GetBuyerBrands";
import { InviteBuyerBrand } from "@services/api/brands/InviteBuyerBrand";
import { GetAllBrandsEndpoint } from "@services/api/brands/get-all-brands";

const singleBrandFormSchema = z.object({
  brandId: z.string().uuid().nullable(),
  brandName: z.string().default(""),
  email: z.string().email().default(""),
  firstname: z.string().default(""),
  lastname: z.string().default(""),
});
export type SingleBrandFormValues = z.infer<typeof singleBrandFormSchema>;

export const validSingleBrandFormValuesSchema = z.object({
  brandId: z.string().uuid().nullable(),
  brandName: z.string().min(1, { message: "Common.form.this-is-required" }),
  email: z.string().email({ message: "Common.form.this-is-not-valid-email" }),
  firstname: z.string().min(1, { message: "Common.form.this-is-required" }),
  lastname: z.string().min(1, { message: "Common.form.this-is-required" }),
});

export const validMultipleBrandFormValuesSchema = z.object({
  forms: z.array(validSingleBrandFormValuesSchema),
});

const getEmptyBrandForm = (): SingleBrandFormValues => ({
  brandId: null,
  brandName: "",
  email: "",
  firstname: "",
  lastname: "",
});

interface MultipleBrandFormProps {
  onSuccess: () => void;
}

function MultipleBrandForm({ onSuccess }: MultipleBrandFormProps) {
  const { t } = useTranslation();
  const allBrandsQuery = GetAllBrandsEndpoint.useHook();
  const buyerBrandsQuery = GetBuyerBrands.useHook();
  const { mutateAsync: inviteBuyerBrand } = InviteBuyerBrand.useHook();

  const {
    control,
    formState: { errors },
    register,
    watch,
    handleSubmit,
    setValue,
  } = useForm<{ forms: SingleBrandFormValues[] }>({
    resolver: zodResolver(validMultipleBrandFormValuesSchema),
    defaultValues: {
      forms: [getEmptyBrandForm()],
    },
  });
  const { append, remove } = useFieldArray({
    name: "forms",
    control,
    rules: { minLength: 1 },
  });
  const brandForms = watch("forms");

  const { data: brands = [] } = allBrandsQuery;
  const { data: buyerBrands = [] } = buyerBrandsQuery;
  const brandsNotInCrm = useMemo(
    () =>
      brands.filter(
        (brand) =>
          !buyerBrands.some((buyerBrand) => buyerBrand.id === brand.id),
      ),
    [brands, buyerBrands],
  );

  const onSubmitForm = async (data: { forms: SingleBrandFormValues[] }) => {
    const parseNewBrandValues =
      validMultipleBrandFormValuesSchema.safeParse(data);

    if (parseNewBrandValues.success) {
      try {
        await Promise.all(
          parseNewBrandValues.data.forms.map((formData) =>
            inviteBuyerBrand({
              data: {
                brandId: formData.brandId,
                mainContactEmail: formData.email,
                mainContactFirstname: formData.firstname,
                mainContactLastname: formData.lastname,
                name: formData.brandName,
              },
            }),
          ),
        );

        toast.success(
          parseNewBrandValues.data.forms.length === 1
            ? t("BuyerAccount.crm.toast.brand-invited")
            : t("BuyerAccount.crm.toast.brands-invited"),
        );

        onSuccess();
      } catch (error) {
        toast.error(
          parseNewBrandValues.data.forms.length === 1
            ? t("BuyerAccount.crm.toast.failed-to-invite-brand")
            : t("BuyerAccount.crm.toast.failed-to-invite-brands"),
        );
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <div className="mb-4">
        {t("BuyerAccount.crm.create-brand.invite-brand-message")}
      </div>
      <hr className="border-t border-t-gray-400" />
      <div className="mt-4">
        <p className="font-bold text-xl">
          {t("BuyerAccount.crm.create-brand.brand-details")}
        </p>
      </div>

      <div className="pb-2 rounded-lg relative">
        {brandForms.map((singleBrandForm, index) => (
          <div
            key={index}
            className="bg-primaryLightElectricBlue px-4 py-4 my-6 rounded-lg"
          >
            <div className="relative">
              <div className="flex items-center justify-between">
                <InputLabel addFontWeight>
                  <p>
                    {`${t("BuyerAccount.crm.create-brand.brand-label")} #${index + 1}`}
                  </p>
                </InputLabel>
                {/*  remove button */}
                {index > 0 && (
                  <div className="flex items-center">
                    <Button
                      type="button"
                      theme="ICON"
                      onClick={() => remove(index)}
                      className="cursor-pointer"
                      label="Remove brand form"
                    >
                      <IoIosClose className="w-6 h-6" />
                    </Button>
                  </div>
                )}
              </div>
              <div>
                <CreatableSelect
                  noOptionsMessage={() =>
                    t("BuyerAccount.crm.create-brand.type-brand-name")
                  }
                  isClearable
                  className="mt-1"
                  styles={{
                    control: (base) => ({
                      ...base,
                      minHeight: "48px",
                      height: "48px",
                    }),
                  }}
                  options={brandsNotInCrm.map((brand) => ({
                    label: brand.name,
                    value: brand.id,
                  }))}
                  placeholder={t(
                    "BuyerAccount.crm.create-brand.brand-placeholder",
                  )}
                  value={
                    singleBrandForm.brandName
                      ? {
                          label: singleBrandForm.brandName,
                          value: singleBrandForm.brandId || undefined,
                        }
                      : null
                  }
                  onCreateOption={(brandName) => {
                    setValue(`forms.${index}.brandId`, null, {
                      shouldValidate: true,
                    });
                    setValue(`forms.${index}.brandName`, brandName, {
                      shouldValidate: true,
                    });
                  }}
                  name={`forms.${index}.brandName`}
                  onChange={(o) => {
                    if (o?.value) {
                      setValue(`forms.${index}.brandId`, o.value);
                      setValue(`forms.${index}.brandName`, o.label);
                    } else {
                      setValue(`forms.${index}.brandId`, null);
                      setValue(`forms.${index}.brandName`, "");
                    }
                  }}
                  aria-label={t("BuyerAccount.crm.create-brand.brand-label")}
                  aria-invalid={
                    errors.forms?.[index]?.brandName ? "true" : "false"
                  }
                />
                {errors.forms?.[index]?.brandName && (
                  <p className="text-xs italic text-primaryRed mt-1">
                    {t(errors.forms?.[index]?.brandName?.message as string)}
                  </p>
                )}
              </div>
            </div>
            {/* First name */}
            <div className="mt-4 grid grid-cols-2 gap-x-4">
              <input
                type="hidden"
                name={`forms.${index}.brandId`}
                value={singleBrandForm.brandId || ""}
              />
              <div>
                <div>
                  <InputLabel>
                    <p className="font-medium text-lg mb-1">
                      {t("BuyerAccount.crm.create-brand.firstname-label")}
                    </p>
                  </InputLabel>
                  <input
                    className={`${inputStyle({
                      errored: !!errors.forms?.[index]?.firstname,
                    })} h-12 w-full`}
                    id="firstname"
                    placeholder={t(
                      "BuyerAccount.crm.create-brand.firstname-placeholder",
                    )}
                    aria-invalid={
                      errors.forms?.[index]?.firstname ? "true" : "false"
                    }
                    aria-label={t(
                      "BuyerAccount.crm.create-brand.firstname-label",
                    )}
                    {...register(`forms.${index}.firstname`)}
                    value={singleBrandForm.firstname ?? ""}
                  />
                </div>
                {errors.forms?.[index]?.firstname && (
                  <p className="text-xs italic text-primaryRed mt-1">
                    {t(errors.forms?.[index]?.firstname?.message as string)}
                  </p>
                )}
              </div>

              <div>
                <div>
                  <InputLabel>
                    <p className="font-medium text-lg mb-1">
                      {t("BuyerAccount.crm.create-brand.lastname-label")}
                    </p>
                  </InputLabel>
                  <input
                    className={`${inputStyle({
                      errored: !!errors.forms?.[index]?.lastname,
                    })} h-12 w-full`}
                    id="lastname"
                    placeholder={t(
                      "BuyerAccount.crm.create-brand.lastname-placeholder",
                    )}
                    aria-invalid={
                      errors.forms?.[index]?.lastname ? "true" : "false"
                    }
                    aria-label={t(
                      "BuyerAccount.crm.create-brand.lastname-label",
                    )}
                    {...register(`forms.${index}.lastname`)}
                    value={singleBrandForm.lastname ?? ""}
                  />
                </div>
                {errors.forms?.[index]?.lastname && (
                  <p className="text-xs italic text-primaryRed mt-1">
                    {t(errors.forms?.[index]?.lastname?.message as string)}
                  </p>
                )}
              </div>
            </div>
            {/* Email */}
            <div className="mt-4">
              <div>
                <InputLabel>
                  <p className="font-medium text-lg mb-1">
                    {t("BuyerAccount.crm.create-brand.email-label")}
                  </p>
                </InputLabel>
                <input
                  className={`${inputStyle({
                    errored: !!errors.forms?.[index]?.email,
                  })} h-12 w-full`}
                  id="email"
                  placeholder={t(
                    "BuyerAccount.crm.create-brand.email-placeholder",
                  )}
                  {...register(`forms.${index}.email`)}
                  value={singleBrandForm.email ?? ""}
                  aria-invalid={errors.forms?.[index]?.email ? "true" : "false"}
                  aria-label={t("BuyerAccount.crm.create-brand.email-label")}
                />
              </div>
              {errors.forms?.[index]?.email && (
                <p className="text-xs italic text-primaryRed mt-1">
                  {t(errors.forms?.[index]?.email?.message as string)}
                </p>
              )}
            </div>
          </div>
        ))}
        <div className="flex justify-center ">
          <Button
            type="button"
            theme="SECONDARY"
            onClick={() => append(getEmptyBrandForm())}
          >
            {t("BuyerAccount.crm.create-brand.add-another-brand")}
            <HiOutlinePlusCircle className="w-6 h-6" />
          </Button>
        </div>
        <div className="text-gray-400 mt-20 mb-2">
          <span className="font-bold pb-36">
            {t("BuyerAccount.crm.create-brand.why-invite-brand")}
          </span>{" "}
          {t("BuyerAccount.crm.create-brand.enable-brands")}{" "}
          <a
            href="https://www.modaresa.com/Clients"
            className="text-primaryElectricBlue hover:underline"
          >
            {t("BuyerAccount.crm.create-brand.learn-more")}
          </a>
        </div>
      </div>

      <DrawerFooter>
        <Button
          className="whitespace-nowrap hover:underline flex w-98 h-9 mb-6"
          theme="PRIMARY"
          justify="center"
          type="submit"
        >
          {t("BuyerAccount.crm.create-brand.send-invitation")}
        </Button>
      </DrawerFooter>
    </form>
  );
}

export default MultipleBrandForm;
