import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { brandSchema } from "@models/Brand";
import { dateHydratingSchema } from "@models/types/Dates";
import { ShowroomSeasonList } from "@models/types/enums";
import axiosInstance from "@services/api/config";

import { GetOrderDeadline } from "./GetOrderDeadline";

export namespace UpdateOrderDeadline {
  export const outputSchema = z.object({
    brandId: brandSchema.shape.id,
    deadline: dateHydratingSchema,
    season: z.enum(ShowroomSeasonList),
    year: z.number(),
  });

  export type Output = z.infer<typeof outputSchema>;

  export interface HookParams {
    brandId: string;
    season: string;
    year: number;
  }

  export interface MutationParams {
    deadline: Date | undefined;
  }

  export function path({ brandId, season, year }: HookParams) {
    return `/brands/${brandId}/order-deadlines/${season}/${year}`;
  }

  export const call = (
    pathParams: HookParams,
    mutationParams: MutationParams,
  ) =>
    axiosInstance
      .put<Output>(path(pathParams), mutationParams)
      .then((r) => outputSchema.parse(r.data));

  export function useHook(params: HookParams) {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: (mutationParams: MutationParams) =>
        call(params, mutationParams),
      onSuccess: () => {
        // invalidate queries related to the brand's order deadlines to reflect the updated data
        queryClient.invalidateQueries({
          queryKey: GetOrderDeadline.getQueryKeys(params),
        });
      },
    });
  }
}
