import React from "react";

import { t } from "i18next";
import {
  HiOutlineCalendar,
  HiOutlineClock,
  HiOutlineLocationMarker,
  HiOutlinePencil,
  HiOutlineTrash,
  HiOutlineUserGroup,
  HiOutlineVideoCamera,
} from "react-icons/hi";

import Button from "@components/data-entry/Button";
import { Appointment } from "@models/Appointment";
import { Collection } from "@models/Collection";
import { OrganizationContact } from "@models/OrganizationContact";
import { Showroom } from "@models/Showroom";

type Appt = Appointment & {
  collection: Pick<Collection, "id" | "name"> | null;
  attendees: Pick<OrganizationContact, "firstName">[];
  showroom: Pick<Showroom, "id" | "formattedAddress" | "timezone">;
};

interface BookedAppointmentCardProps<A extends Appt> {
  appointment: A;
  onEditAttendees?: (appointment: A) => void;
  onEditAppointment?: (appointment: A) => void;
  onRemoveAppointment?: (appointment: A) => void;
}

export default function BookedAppointmentCard<A extends Appt>({
  appointment,
  onEditAttendees,
  onEditAppointment,
  onRemoveAppointment,
}: BookedAppointmentCardProps<A>) {
  const {
    startTime,
    endTime,
    showroom: { timezone },
  } = appointment;

  return (
    <div
      data-testid={`appointment-card-${appointment.id}`}
      className="flex justify-between p-4 rounded-lg bg-primaryLightElectricBlue"
    >
      <div className="flex flex-col gap-2">
        <div className="flex gap-2 items-center">
          <HiOutlineCalendar className="w-4 h-4" />
          <span>{startTime.formatDateAtTimezone(timezone)}</span>
        </div>
        <div className="flex gap-2 items-center">
          <HiOutlineClock className="w-4 h-4" />
          <span>
            {startTime.formatTimeAtTimezone(timezone)}
            {" - "}
            {endTime.formatTimeAtTimezone(timezone)}
          </span>
        </div>
        <div className="flex gap-2 items-center">
          <HiOutlineUserGroup className="w-5 h-5" />
          <span>
            {appointment.attendees
              .map((contact) => contact.firstName)
              .join(", ")}
          </span>
          {onEditAttendees && (
            <Button
              testId={`edit-attendees-${appointment.id}`}
              theme="NONE"
              type="button"
              className="flex gap-1 items-center text-primaryElectricBlue"
              onClick={() => onEditAttendees(appointment)}
            >
              <HiOutlinePencil className="w-3 h-3 " />
              <span className="underline hidden lg:inline-block">
                {t("Booking.dashboard.appointment-list.edit-attendees")}
              </span>
            </Button>
          )}
        </div>
        <div className="flex gap-2 items-center">
          {appointment.format === "IN_PERSON" && (
            <>
              <HiOutlineLocationMarker className="w-4 h-4" />
              <span>{appointment.showroom.formattedAddress}</span>
            </>
          )}
          {appointment.format === "VIRTUAL" &&
            appointment.virtualMeetingApp && (
              <>
                {/* TODO: NEED TO FETCH THE VIRTUAL URL */}
                <HiOutlineVideoCamera className="w-4 h-4" />
                <a
                  href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_YzEwZjRlZjMtYmU0Zi00YjY1LWIwZjktZjQ2ZjQxZjMxYzEz%40thread.v2/0?context=%7b%22Tid%22%3a%22b6c0b6e5-9f5e-4e8a-8b6c-1a7a2e0a8c7e%22%2c%22Oid%22%3a%22f4c4b4d4-8c8f-4b1f-9b9f-1a8c8f4b1f9b%22%7d"
                  className="underline text-primaryElectricBlue"
                  target="_blank"
                  rel="noreferrer"
                >
                  Virtual&nbsp;-&nbsp;
                  {t(`Common.virtual-tool.${appointment.virtualMeetingApp}`)}
                </a>
              </>
            )}
        </div>
      </div>
      {onEditAppointment && onRemoveAppointment && (
        <div className="flex gap-6 items-start">
          <Button
            testId={`edit-appointment-${appointment.id}`}
            theme="ICON"
            type="button"
            onClick={() => onEditAppointment(appointment)}
          >
            <HiOutlinePencil className="w-4 h-4" />
          </Button>
          <Button
            testId={`delete-appointment-${appointment.id}`}
            theme="ICON"
            type="button"
            onClick={() => onRemoveAppointment(appointment)}
          >
            <HiOutlineTrash className="w-4 h-4 text-statusRedDark" />
          </Button>
        </div>
      )}
    </div>
  );
}
