import React, { useState } from "react";

import { HiChevronRight, HiOutlineCalendar } from "react-icons/hi2";

// import { validShowroomFormDataSchema } from "@app/modules/showroom/form/helpers";
import {
  ShowroomFormData,
  useShowroomForm,
  validShowroomFormDataSchema,
} from "@app/modules/showroom/form/hook";
import Collapse from "@components/data-display/Collapse";
import InputLabel from "@components/data-display/InputLabel";
import AddressAutocomplete from "@components/data-entry/AddressAutocomplete";
import Button from "@components/data-entry/Button";
import CustomDatePicker from "@components/data-entry/CustomDatepicker";
import SeasonSelect from "@components/data-entry/SeasonSelect";
import TextField from "@components/data-entry/TextField";
import TimePicker from "@components/data-entry/TimePicker";
import BottomBar from "@components/layout/BottomBar";
import { usePrefixedTranslation } from "@helpers/Translation";
import CustomOpeningHoursByDayPicker from "@sales-campaign/components/showroom/custom-opening-hours-by-day-picker";
import TimezoneAutocomplete from "@shared/components/form/timezone-autocomplete";

const globalInformationsSchema = validShowroomFormDataSchema
  .pick({
    name: true,
    address: true,
    season: true,
    year: true,
    timezone: true,
    directions: true,
    openingDays: true,
    openingHour: true,
    closingHour: true,
    lunchStartingHour: true,
    lunchEndingHour: true,
  })
  .passthrough();
interface Props {
  defaultValues?: Partial<ShowroomFormData>;
  onSubmit: (d: ShowroomFormData) => void;
}

export default function ShowroomGlobalInformationsForm({
  onSubmit,
  defaultValues,
}: Props) {
  const {
    form,
    setOpeningHour,
    setLunchStartingHour,
    setName,
    setSeasonYear,
    setAddress,
    setDirections,
    setTimezone,
    setLunchEndingHour,
    setClosingHour,
    setOpeningDays,
  } = useShowroomForm({ defaultValues, schema: globalInformationsSchema });
  const { pt, t } = usePrefixedTranslation(
    "Showroom.ShowroomGlobalInformationsForm",
  );
  const [collapsed, setCollapsed] = useState(false);

  const {
    name,
    season,
    year,
    address,
    openingDays,
    timezone,
    openingHour,
    closingHour,
    directions,
    lunchEndingHour,
    lunchStartingHour,
  } = form.watch();

  const handleSubmit = form.handleSubmit(onSubmit);

  return (
    <>
      <div className="grow p-4 space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <InputLabel
              htmlFor="showroom-name"
              error={
                form.formState.errors.name
                  ? pt("fill-showroom-name")
                  : undefined
              }
            >
              {pt("showroom-name-label")}
            </InputLabel>

            <TextField
              hasError={!!form.formState.errors.name}
              id="showroom-name"
              value={name}
              onChange={setName}
            />
          </div>

          <div>
            <InputLabel
              id="showroom-season-year"
              error={
                form.formState.errors.season || form.formState.errors.year
                  ? pt("fill-showroom-season")
                  : undefined
              }
            >
              {pt("showroom-season-label")}
            </InputLabel>
            <SeasonSelect
              aria-labelledby="showroom-season-year"
              value={{ season, year }}
              onChange={setSeasonYear}
              aria-label={pt("showroom-season-label")}
            />
          </div>
        </div>

        <div>
          <InputLabel
            htmlFor="showroom-address"
            error={
              form.formState.errors.address
                ? pt("fill-showroom-address")
                : undefined
            }
          >
            {pt("showroom-address-label")}
          </InputLabel>
          <AddressAutocomplete
            className={form.formState.errors.address ? "border-primaryRed" : ""}
            placeholder={pt("showroom-address-placeholder")}
            id="showroom-address"
            defaultValue={address}
            onChange={(a) => setAddress(a || null)}
          />
        </div>

        <div>
          <InputLabel
            htmlFor="showroom-timezone"
            error={
              form.formState.errors.timezone
                ? pt("select-showroom-timezone")
                : undefined
            }
          >
            {pt("showroom-timezone-label")}
          </InputLabel>
          <TimezoneAutocomplete
            className={
              form.formState.errors.timezone ? "border-primaryRed" : ""
            }
            id="showroom-timezone"
            onChange={setTimezone}
            value={timezone || ""}
          />
        </div>

        <div>
          <label className="py-1 font-medium" id="showroom-directions">
            {pt("showroom-directions-label")}
            <div className="text-xs mb-1 text-primaryGrey">
              {pt("showroom-directions-description")}
            </div>
          </label>
          <TextField
            aria-labelledby="showroom-directions"
            value={directions}
            onChange={setDirections}
          />
        </div>
        <div>
          <InputLabel
            htmlFor="showroom-opening-days"
            error={
              form.formState.errors.openingDays
                ? pt("select-opening-days")
                : undefined
            }
            description={pt("showroom-opening-days-description")}
          >
            {pt("showroom-opening-days-label")}
          </InputLabel>
          <CustomDatePicker
            id="showroom-opening-days"
            placeholder={
              <div className="flex items-center gap-2 w-74">
                <HiOutlineCalendar />{" "}
                <span className="text-grey">
                  {pt("showroom-opening-days-placeholder")}
                </span>
              </div>
            }
            value={openingDays.map((v) => v.day)}
            multiple
            onChange={setOpeningDays}
            intlOptions={{
              month: "numeric",
              day: "numeric",
              year: "numeric",
            }}
            minDate={Math.min(
              Date.now(),
              ...(openingDays.map((d) => d.day) || []).map((d) => d.getTime()),
            )}
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <label id="showroom-opening-hour">
            {pt("showroom-opening-hour-label")}
          </label>
          <label id="showroom-closing-hour">
            {pt("showroom-closing-hour-label")}
          </label>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <TimePicker
            aria-labelledby="showroom-opening-hour"
            className="inline w-34"
            placeholder={pt("showroom-openingHour-placeholder")}
            name="showroomOpeningHour"
            options={{
              firstHourOfDay: 6,
              totalHours: 16,
              timeStep: 15,
            }}
            onChange={(newOpeningHour) => {
              if (!newOpeningHour) {
                return; // should not be able to unselect openingHour
              }
              setOpeningHour(newOpeningHour);
            }}
            defaultValue={openingHour}
          />
          <TimePicker
            aria-labelledby="showroom-closing-hour"
            className="inline w-34"
            placeholder={pt("showroom-closing-hour-placeholder")}
            name="showroomClosingHour"
            onChange={setClosingHour}
            minTime={openingHour}
            options={{
              firstHourOfDay: 6,
              totalHours: 16,
              timeStep: 15,
            }}
            defaultValue={closingHour}
          />
        </div>

        <div>
          <Collapse
            title={pt("showroom-customHours.collapse-title")}
            collapsed={collapsed}
            onToggle={() => setCollapsed(!collapsed)}
            disabled={
              !(
                openingDays &&
                openingDays.length > 0 &&
                openingHour &&
                closingHour
              )
            }
            className="bg-primaryLightestGrey border border-primaryGrey font-normal"
            divider
          >
            <div className="flex flex-col gap-4">
              {openingDays &&
                openingDays.length !== 0 &&
                openingDays.map((openingDay, index) => (
                  <CustomOpeningHoursByDayPicker
                    index={index}
                    form={form}
                    key={`customOpenings-${openingDay.day}`}
                    day={openingDay.day}
                    defaultOpeningHour={openingHour}
                    defaultClosingHour={closingHour}
                  />
                ))}
            </div>
          </Collapse>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label id="showroom-lunch-starting-hour">
              {pt("showroom-lunch-starting-hour-label")}
            </label>
            <TimePicker
              aria-labelledby="showroom-lunch-starting-hour"
              className="inline w-34"
              placeholder={pt("showroom-lunch-starting-hour-placeholder")}
              name="showroomLunchStartingHour"
              onChange={setLunchStartingHour}
              minTime={openingHour}
              maxTime={lunchEndingHour || closingHour}
              defaultValue={lunchStartingHour}
              nullable
              options={{
                firstHourOfDay: 11,
                totalHours: 4,
                timeStep: 15,
              }}
            />
          </div>
          <div>
            <label id="showroom-lunch-ending-hour">
              {pt("showroom-lunch-ending-hour-label")}
            </label>
            <TimePicker
              aria-labelledby="showroom-lunch-ending-hour"
              className="inline w-34"
              placeholder={pt("showroom-lunch-ending-hour-placeholder")}
              name="showroomLunchEndingHour"
              onChange={setLunchEndingHour}
              defaultValue={lunchEndingHour}
              minTime={lunchStartingHour || openingHour}
              nullable
              maxTime={closingHour}
              options={{
                firstHourOfDay: lunchStartingHour ? 11 : 0,
                totalHours: lunchStartingHour ? 4 : 0,
                timeStep: lunchStartingHour ? 15 : 0,
              }}
            />
          </div>
        </div>
      </div>
      <BottomBar alignment="center">
        <Button
          theme="PRIMARY"
          // disabled={
          //   !globalInformationsSchema.safeParse(form.getValues()).success
          // }
          // onClick={() => onSubmit(form.getValues())}
          onClick={handleSubmit}
        >
          {t("Common.next")}
          <HiChevronRight />
        </Button>
      </BottomBar>
    </>
  );
}
