import React, { useId } from "react";

import { HiChevronDown, HiChevronUp } from "react-icons/hi";

import Checkbox from "@components/data-entry/Checkbox";
import { MultiOptionItemTree } from "@helpers/MultiOptionItemTree";

interface TreeItemProps {
  name: string;
  isOpen?: boolean;
  isSelected?: boolean;
  item: MultiOptionItemTree;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    item: MultiOptionItemTree,
  ) => void;
  onToggle: (item: MultiOptionItemTree, isOpen: boolean) => void;
  children?: React.ReactNode;
}

function TreeItem(props: TreeItemProps) {
  const key = useId();
  const {
    name,
    isOpen = false,
    isSelected = false,
    item,
    onChange,
    onToggle,
    children,
  } = props;
  const hasOptions = item.options && item.options.length > 0;

  return (
    <li
      key={`${key}_${item.value}`}
      className="flex flex-col text-primaryBlack"
    >
      <div
        className={`py-2 item hover:bg-gray-200 ${isSelected && "bg-blue-200"}`}
      >
        <div className="flex flex-row items-center gap-3 px-4">
          <Checkbox
            label={item.label}
            id={`${key}_${item.value}`}
            aria-label={`${item.value} for ${name}`}
            name={`${key}_${item.value}`}
            checked={isSelected || false}
            onChange={(ev) => onChange(ev, item)}
          />
          {isOpen !== undefined && hasOptions && (
            <button
              type="button"
              onClick={() => {
                onToggle(item, isOpen);
              }}
            >
              {isOpen ? <HiChevronUp /> : <HiChevronDown />}
            </button>
          )}
        </div>
      </div>
      <div className={`pl-6 item ${!isOpen ? "hidden" : "block"}`}>
        {children}
      </div>
    </li>
  );
}

export default TreeItem;
