import React, { useEffect } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
// import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { z } from "zod";

import InputLabel from "@components/data-display/InputLabel";
import CustomDatePicker from "@components/data-entry/CustomDatepicker";
import SeasonSelect from "@components/data-entry/SeasonSelect";
import { Button } from "@components/ui/button";
import { ShowroomSeason, ShowroomSeasonList } from "@models/types/enums";
import { GetOrderDeadline } from "@services/api/order-deadline/GetOrderDeadline";
import { SendOrderDeadline } from "@services/api/order-deadline/SendOrderDeadline";
import { UpdateOrderDeadline } from "@services/api/order-deadline/UpdateOrderDeadline";

import { getNextFourFashionShows } from "../helpers/find-season";

// create zod schema for form validation
const formValuesSchema = z.object({
  deadline: z.date().optional(),
  season: z.enum(ShowroomSeasonList),
  year: z.number(),
});

type FormValues = z.infer<typeof formValuesSchema>;
export interface SeasonWithDeadline {
  brandId?: string;
  season: ShowroomSeason;
  year: number;
  deadline?: Date | undefined;
}

export interface OrderDeadlineFormCRMProps {
  brandId: string;
  initialValues?: FormValues;
  onSuccess?: () => void;
}

const defaultInitialValues: FormValues = {
  deadline: undefined,
  season: getNextFourFashionShows(new Date())[0].season,
  year: getNextFourFashionShows(new Date())[0].year,
};

function OrderDeadlineFormCRM({
  brandId,
  initialValues,
  onSuccess,
}: OrderDeadlineFormCRMProps) {
  const { t } = useTranslation();

  const form = useForm<FormValues>({
    resolver: zodResolver(formValuesSchema),
    defaultValues: {
      ...defaultInitialValues,
      ...initialValues,
    },
  });

  const { setValue, watch } = form;
  const season = watch("season");
  const year = watch("year");

  const { mutateAsync: updateOrderDeadline } = UpdateOrderDeadline.useHook({
    brandId,
    season,
    year,
  });
  const { mutateAsync: sendOrderDeadline } = SendOrderDeadline.useHook({
    brandId,
    season,
    year,
  });

  const { data: savedDeadline } = GetOrderDeadline.useHook({
    brandId,
    season,
    year,
  });

  useEffect(() => {
    if (savedDeadline) {
      setValue("deadline", savedDeadline.deadline);
    } else {
      setValue("deadline", undefined);
    }
  }, [setValue, savedDeadline]);

  const saveDeadline = async (data: FormValues) => {
    try {
      await updateOrderDeadline({ deadline: data.deadline });
      toast.success(t("Shared.order-deadline.updated"));
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      toast.error(t("Shared.order-deadline.update-error"));
    }
  };

  const sendDeadline = async (data: FormValues) => {
    try {
      await sendOrderDeadline({ deadline: data.deadline });
      toast.success(t("Shared.order-deadline.sent"));
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      toast.error(t("Shared.order-deadline.update-error"));
    }
  };

  return (
    <>
      <form className="flex flex-col">
        {form.formState.errors.root?.message && (
          <span className="text-xs italic text-primaryRed">
            {t(form.formState.errors.root?.message)}
          </span>
        )}
        <InputLabel
          htmlFor="season"
          error={
            form.formState.errors.season?.message ||
            form.formState.errors.year?.message
          }
        >
          {t("Shared.order-deadline.season")}
        </InputLabel>
        <SeasonSelect
          id="season"
          value={{ season, year }}
          onChange={(s, y) => {
            form.setValue("season", s);
            form.setValue("year", y);
          }}
        />
        <InputLabel
          className="mt-4"
          htmlFor="deadline"
          error={form.formState.errors.deadline?.message}
        >
          {t("Shared.order-deadline.deadline")}
        </InputLabel>
        <CustomDatePicker
          className="w-full"
          id="deadline"
          placeholder={t("Shared.order-deadline.placeholder")}
          value={form.watch("deadline")}
          onChange={(date) => {
            if (date && !Array.isArray(date)) {
              form.setValue("deadline", date);
            }
          }}
        />
      </form>
      <div className="mt-4 flex gap-2">
        <Button
          onClick={form.handleSubmit(saveDeadline)}
          variant="outline"
          className="rounded-none w-full"
          type="submit"
        >
          {t("Shared.order-deadline.save-deadline")}
        </Button>
        <Button
          onClick={form.handleSubmit(sendDeadline)}
          className="rounded-none w-full"
          type="submit"
        >
          {t("Shared.order-deadline.send-deadlines")}
        </Button>
      </div>
    </>
  );
}

export default OrderDeadlineFormCRM;
