import React, { useRef, useState } from "react";

import { t } from "i18next";
import { Trans } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import AccountsFilters, {
  AccountFilters,
} from "@app/modules/crm/components/accounts/accounts-filter";
import AccountsTable from "@app/modules/crm/components/accounts/accounts-table";
import UpsertAccountForm from "@app/modules/crm/components/accounts/upsert-account-form";
import CRMHeader from "@app/modules/crm/components/header";
import { useCRMDrawerContext } from "@app/modules/crm/context/crm-drawers";
import filterAccounts from "@app/modules/crm/helpers/filter-accounts";
import queryClient from "@app/queryClient";
import CalloutBox from "@components/feedback/CalloutBox";
import ConfirmModal from "@components/feedback/ConfirmModal";
import Drawer from "@components/feedback/Drawer";
import InformationModal from "@components/feedback/InformationModal";
import Loading from "@components/feedback/Loading";
import { AccountStatusEnum } from "@models/OrganizationAccount";
import { AccountWithCollections } from "@models/old/Account";
import {
  getFullAccountsEndpoint,
  useGetFullAccounts,
} from "@services/api/accounts/get-full-accounts";
import { getAPIQueryKey } from "@services/api/helper";
import { queryKeysFetchAccountsCount } from "@services/api/old/accounts/fetch-accounts-count";
import { queryKeysFetchAccountsArchivedCount } from "@services/api/old/accounts/fetch-archived-count";
import { usePutArchiveAccount } from "@services/api/old/accounts/put-archive-account";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import LogService from "@services/log/service";
import { pageWithAccessControl } from "@shared/components/access-control";
import { ACL_ADMINS_AGENTS_MANAGERS } from "@shared/components/access-control/helpers";

/**
 * Returns an ellipsed array, with a maximum of n elements
 * 2 scenarios :
 * - if the array length is less or equal to n, return the array
 * - if the array length is greated than n, return the first n-1 and the last element groups every remaining elements (at least 2 elements)
 * @param array
 * @param n
 */
interface EllipsedItem<T> {
  type: "item";
  item: T;
}
interface EllipsedItems<T> {
  type: "items";
  items: T[];
}
function ellipseArray<Item>(
  array: Item[],
  n: number,
): (EllipsedItem<Item> | EllipsedItems<Item>)[] {
  if (array.length <= n) {
    return array.map((item) => ({ type: "item", item }));
  }
  return [
    ...array.slice(0, n - 1).map((item) => ({ type: "item" as const, item })),
    { type: "items" as const, items: array.slice(n - 1) },
  ];
}

function useAccountStatusFilter() {
  const [params] = useSearchParams();
  const status = params.get("status");
  const result = AccountStatusEnum.safeParse(status?.toUpperCase());
  if (result.success) {
    return {
      accountStatus: result.data,
    };
  }
  return {};
}

function CRMAccounts() {
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();

  const [salesCampaignConflicted, setSalesCampaignConflicted] = useState<
    string[] | undefined
  >();

  const crmContext = useCRMDrawerContext();

  const drawerRef = useRef(null);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<AccountFilters>(
    useAccountStatusFilter(),
  );

  const [selectedAccountToArchive, setSelectedAccountToArchive] = useState<
    AccountWithCollections | undefined
  >();
  const [selectedAccountToEdit, setSelectedAccountToEdit] = useState<
    AccountWithCollections | undefined
  >();

  const { mutateAsync: putArchiveAccount } =
    usePutArchiveAccount(organizationId);

  const { data: accountList, isLoading: isLoadingList } =
    useGetFullAccounts(organizationId);

  const handleConfirmArchiveAccount = () => {
    if (!selectedAccountToArchive) return;

    putArchiveAccount(selectedAccountToArchive?.id)
      .then(async () => {
        await queryClient.invalidateQueries({
          queryKey: getAPIQueryKey(
            getFullAccountsEndpoint.path(organizationId),
          ),
        });
        await queryClient.invalidateQueries({
          queryKey: queryKeysFetchAccountsCount(organizationId),
        });
        await queryClient.invalidateQueries({
          queryKey: queryKeysFetchAccountsArchivedCount(organizationId),
        });
        setIsConfirmModalOpen(false);
        toast.success(t("CRM.accounts.confirm-account-archive"));
      })
      .catch((err) => {
        setIsConfirmModalOpen(false);
        if (err.statusCode === 409 && err.message) {
          setSalesCampaignConflicted(err.message);
          setIsInfoModalOpen(true);
        } else {
          LogService.error(err);
        }
      });
  };

  const accountsWithoutContacts = (accountList || []).filter(
    (account) => account.contacts.length === 0,
  );
  const accountsWithoutContactsToDisplay = ellipseArray(
    accountsWithoutContacts,
    5,
  );

  const filteredAccounts = accountList && filterAccounts(accountList, filters);

  const closeDrawer = () => {
    setSelectedAccountToEdit(undefined);
    crmContext.closeDrawer();
  };

  return (
    <>
      <CRMHeader />
      {isLoadingList && (
        <div className="flex items-end justify-center">
          <Loading type="screen" />
        </div>
      )}
      {!isLoadingList && (
        <div className="flex flex-col gap-6 p-6">
          {accountsWithoutContacts.length > 0 && (
            <CalloutBox closable type="WARNING" className="">
              <strong className="font-bold text-lg">
                {t(
                  "CRM.accounts.accounts-with-no-contacts-warning.{{count}}-title",
                  {
                    count: accountsWithoutContacts.length,
                  },
                )}
              </strong>
              <p>
                {t(
                  "CRM.accounts.accounts-with-no-contacts-warning.description",
                )}
              </p>
              <ul className="list-disc pl-7">
                {accountsWithoutContactsToDisplay.map((display) =>
                  display.type === "item" ? (
                    <li key={display.item.id}>{display.item.name}</li>
                  ) : (
                    <li>
                      {t(
                        "CRM.accounts.accounts-with-no-contacts-warning.{{count}}-more",
                        {
                          count: display.items.length,
                        },
                      )}
                    </li>
                  ),
                )}
              </ul>
            </CalloutBox>
          )}
          <AccountsFilters filters={filters} onChange={setFilters} />
          <AccountsTable
            accounts={
              filteredAccounts?.sort((a, b) => a.name.localeCompare(b.name)) ||
              []
            }
            onClickEdit={(account) => {
              setSelectedAccountToEdit(account);
              crmContext.openAccountUpsertDrawer();
            }}
            onClickDelete={(account) => {
              setSelectedAccountToArchive(account);
              setIsConfirmModalOpen(true);
            }}
          />
        </div>
      )}
      <Drawer
        drawerRef={drawerRef}
        closeWithConfirmation={closeDrawer}
        name="creating or updating new account drawer"
        backdrop
        isOpen={crmContext.drawer === "upsert-account"}
        size="LARGE"
        drawerTitle={
          <h2 className="heading-2-mobile lg:heading-2">
            {selectedAccountToEdit?.id
              ? selectedAccountToEdit.name
              : t("CRM.accounts.form.new-account")}
          </h2>
        }
      >
        <UpsertAccountForm
          selectedAccount={selectedAccountToEdit}
          onCancel={closeDrawer}
          onSuccess={closeDrawer}
          onError={closeDrawer}
        />
      </Drawer>
      <InformationModal
        show={isInfoModalOpen}
        onClose={() => {
          setIsInfoModalOpen(false);
        }}
        title={t("CRM.accounts.archive-account-error-title")}
      >
        <p>{t("CRM.accounts.archive-account-error-content")}</p>
        <ul className="my-4 list-disc pl-7">
          {salesCampaignConflicted?.map((campaign) => (
            <li key={`sales_key_${campaign}`}>{campaign}</li>
          ))}
        </ul>
        <p>{t("CRM.accounts.archive-account-error-wait")}</p>
      </InformationModal>
      <ConfirmModal
        show={isConfirmModalOpen}
        title={t("CRM.accounts.archive-account-title")}
        confirmLabel={t("CRM.accounts.archive-account-confirm-button")}
        cancelLabel={t("Components.buttons.cancel")}
        theme="DANGER"
        onCancel={() => {
          setIsConfirmModalOpen(false);
          setSelectedAccountToArchive(undefined);
        }}
        onConfirm={() => {
          handleConfirmArchiveAccount();
          setSelectedAccountToArchive(undefined);
        }}
      >
        <Trans
          i18nKey="CRM.accounts.archive-account-content"
          values={{
            accountName: selectedAccountToArchive?.name,
          }}
          components={{
            bold: <strong className="font-bold" />,
            break: <br />,
          }}
        >
          CRM.accounts.archive-account-content
        </Trans>
      </ConfirmModal>
    </>
  );
}

export default pageWithAccessControl(ACL_ADMINS_AGENTS_MANAGERS, CRMAccounts);
