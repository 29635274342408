/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";

import { cva } from "class-variance-authority";
import { HiCheck, HiExclamation, HiLightBulb, HiX } from "react-icons/hi";

import { cn } from "@app/lib/utils";
import { Button } from "@components/ui/button";
import { addAccessControlProps } from "@shared/components/access-control";

type CalloutType = "INFORMATIVE" | "WARNING" | "ERROR" | "SUCCESS";

const boxStyle = cva("p-4 border rounded-lg", {
  variants: {
    type: {
      ERROR: "bg-statusRedLight border-statusRedDark",
      SUCCESS: "bg-statusGreenLight border-statusGreenDark",
      WARNING: "bg-statusOrangeLight border-statusOrangeDark text-orange-700",
      INFORMATIVE: "bg-statusBlueLight border-statusBlueDark",
    },
  },
});

const iconStyle = cva("p-2 lg:p-4 rounded-full", {
  variants: {
    type: {
      ERROR: "bg-statusRed",
      SUCCESS: "bg-statusGreen",
      WARNING: "bg-statusOrange",
      INFORMATIVE: "bg-statusBlue",
    },
  },
});

const closeStyle = cva("size-6", {
  variants: {
    type: {
      ERROR: "fill-statusRedDark",
      SUCCESS: "fill-statusGreenDark",
      WARNING: "fill-statusOrangeDark",
      INFORMATIVE: "fill-statusBlueDark",
    },
  },
});

interface CalloutBoxProps {
  type?: CalloutType;
  className?: string;
  children: React.ReactNode;
  closable?: boolean;
}

function Icon({ type }: { type: CalloutType }) {
  switch (type) {
    case "ERROR":
      return <HiX className="w-6 h-6 fill-statusRedDark" />;
    case "SUCCESS":
      return <HiCheck className="w-6 h-6 fill-statusGreenDark" />;
    case "WARNING":
      return <HiExclamation className="w-6 h-6 fill-statusOrangeDark" />;
    case "INFORMATIVE":
    default:
      return <HiLightBulb className="w-6 h-6 fill-statusBlueDark" />;
  }
}

function CalloutBox(props: CalloutBoxProps) {
  const { type = "INFORMATIVE", className, children, closable = false } = props;
  const [closed, setClosed] = useState(false);

  return closed ? null : (
    <div
      aria-label={`calloutbox-of-${type}`}
      className={cn("relative", className)}
    >
      <div className={boxStyle({ type })}>
        {closable && (
          <Button
            className="absolute top-1 right-1 rounded-full size-6"
            variant="link"
            size="icon"
            onClick={() => setClosed(true)}
          >
            <HiX className={closeStyle({ type })} />
          </Button>
        )}
        <div className="flex items-center gap-6">
          <div className={iconStyle({ type })}>
            <Icon type={type} />
          </div>
          <div className="w-full">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default addAccessControlProps(CalloutBox);
