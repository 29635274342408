/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PropsWithChildren, useRef, useState } from "react";

import { ScrollArea } from "@radix-ui/react-scroll-area";
import { cva } from "class-variance-authority";
import {
  differenceInHours,
  format,
  getDay,
  max,
  min,
  startOfWeek,
} from "date-fns";
import {
  EventProps,
  EventWrapperProps,
  Calendar as ReactCalendar,
  ResourceHeaderProps,
  dateFnsLocalizer,
} from "react-big-calendar";
import withDragAndDrop, {
  EventInteractionArgs,
} from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useTranslation } from "react-i18next";
import { HiDotsVertical } from "react-icons/hi";
import { HiEyeSlash, HiOutlinePencil, HiPencilSquare } from "react-icons/hi2";
import { VscTrash } from "react-icons/vsc";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";

import { cn } from "@app/lib/utils";
import AppointmentDialogEdit, {
  AccountAppointmentFormData,
  AccountAppointmentFormDataSubmitted,
  appointmentToFormData,
} from "@calendar/components/appointment/dialog-edit";
import AppointmentDialogView from "@calendar/components/appointment/dialog-view";
import BusySlotCreateForm from "@calendar/components/busy/create-form";
import {
  BusyAppointmentEditForm,
  BusySlotEditForm,
} from "@calendar/components/busy/edit-form";
import { useCalendarFilters } from "@calendar/components/filters";
import { Calendar } from "@calendar/types";
import Button from "@components/data-entry/Button";
import ConfirmModal from "@components/feedback/ConfirmModal";
import Drawer, { DrawerBody, useDrawer } from "@components/feedback/Drawer";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@components/ui/dropdown-menu";
import { arrayFilterDuplicateIds } from "@helpers/Array";
import {
  LocalDate,
  ZonedDate,
  combineDateAndTime,
  formatDateToISO,
} from "@helpers/Date";
import { Appointment } from "@models/Appointment";
import {
  AppointmentTypeEnum,
  BusyAppointmentTypeList,
  ShowroomSeason,
} from "@models/types/enums";
import { GetAppointmentsForInterval } from "@services/api/appointments/GetAppointmentsForInterval";
import useAppointmentCheckin from "@services/api/appointments/checkin";
import { DeleteAppointmentEndpoint } from "@services/api/appointments/delete-appointment";
import { UpsertAppointmentEndpoint } from "@services/api/appointments/upsert-appointment";
import { CreateBusySlotsEndpoint } from "@services/api/busy-slots/create-busy-slots";
import { GetOrderDeadline } from "@services/api/order-deadline/GetOrderDeadline";
import { GetOngoingShowroomsEndpoint } from "@services/api/showroom/get-ongoing-showrooms";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import { useAuthenticatedRepresentative } from "@services/authentication/useAuthentication";
import LogService from "@services/log/service";
import { fullName } from "@shared/helpers/formatters";
import OrderDeadlineIndicator from "@shared/order-deadline/OrderDeadlineIndicator";
import { getShowroomHoursOnDate } from "@shared/showroom/helpers";

type CalendarEvent = {
  id: string;
  title: string | null;
  start: Date;
  end: Date;
  resourceId: string | null;
  isDraggable: boolean;
  color: string;

  format: Appointment["format"];
  status: Appointment["status"];
  organizationId: string;
  accountId: string | null;
  isMeetingReportFullyFilled: boolean;
};

type CalendarResource = {
  resourceId: string;
  resourceTitle: string;
};

const CalendarComponent = withDragAndDrop(
  ReactCalendar<CalendarEvent, CalendarResource>,
);

const checkinBorder = cva("", {
  variants: {
    status: {
      PLANNED: "",
      BUYER_ARRIVED: "border-dashed border-green-400",
      ONGOING: "border border-green-400",
      DONE: "opacity-25",
      CANCELLED: "opacity-25 border border-red-500",
    },
  },
});
function EventWrapperComponent({
  style,
  children,
  event,
  className,
}: PropsWithChildren<EventWrapperProps<CalendarEvent>>) {
  const c = checkinBorder({ status: event.status });

  return (
    <div className={cn("group", c, className)} style={{ ...style }}>
      {children}
    </div>
  );
}

function makeResourceHeader({
  onToggle,
}: {
  onToggle: (resourceId: string) => void;
}) {
  return function ResourceHeader({
    label,
    resource,
  }: ResourceHeaderProps<CalendarResource>) {
    return (
      <button
        className="group flex items-center gap-3"
        type="button"
        onClick={() => onToggle(resource.resourceId)}
      >
        {label}
        <span className="group-hover:opacity-100 transition-opacity duration-200 opacity-0">
          <HiEyeSlash className="w-4 h-4" />
        </span>
      </button>
    );
  };
}

function CalendarEventComponent({ event }: EventProps<CalendarEvent>) {
  const navigate = useNavigate();
  const checkinMutation = useAppointmentCheckin({
    organizationId: event.organizationId,
  });

  return (
    <div>
      {event.title}
      <DropdownMenu>
        <DropdownMenuTrigger className="group-hover:opacity-100 opacity-0 transition-opacity duration-200  bg-gray-200 rounded-full p-1 hover:bg-gray-300">
          <HiDotsVertical className="w-4 h-4" />
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              navigate(
                `/crm/accounts/${event.accountId}/meeting-report/${event.id}`,
              );
            }}
          >
            <HiPencilSquare
              className={
                event.isMeetingReportFullyFilled
                  ? "text-statusGreenDark"
                  : "text-primaryDarkGrey"
              }
            />
            Meeting report
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuLabel className="text-gray-400">
              Mark as
            </DropdownMenuLabel>
            <DropdownMenuCheckboxItem
              checked={event.status === "BUYER_ARRIVED"}
              onClick={() =>
                checkinMutation.mutateAsync({
                  appointmentId: event.id,
                  method: "soft-check-in",
                })
              }
            >
              <span className="ml-2">Client arrived</span>
            </DropdownMenuCheckboxItem>
            <DropdownMenuCheckboxItem
              checked={event.status === "ONGOING"}
              onClick={() =>
                checkinMutation.mutateAsync({
                  appointmentId: event.id,
                  method: "check-in",
                })
              }
            >
              <span className="ml-2">Started</span>
            </DropdownMenuCheckboxItem>
            <DropdownMenuCheckboxItem
              checked={event.status === "DONE"}
              onClick={() =>
                checkinMutation.mutateAsync({
                  appointmentId: event.id,
                  method: "check-out",
                })
              }
            >
              <span className="ml-2">Completed</span>
            </DropdownMenuCheckboxItem>
            <DropdownMenuCheckboxItem
              checked={event.status === "CANCELLED"}
              onClick={() =>
                checkinMutation.mutateAsync({
                  appointmentId: event.id,
                  method: "cancel",
                })
              }
            >
              <span className="ml-2">Cancelled</span>
            </DropdownMenuCheckboxItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}

export function useSellerSorting<
  Seller extends { id: string; firstName: string; lastName: string },
>(sellers: Seller[] | undefined) {
  const { associatedOrganizations } = useAuthenticatedRepresentative();
  const representativeIds = associatedOrganizations.map(
    (b) => b.representativeId,
  );

  if (!sellers) {
    return [];
  }

  sellers.sort((seller1, seller2) => {
    // the current user is always first
    if (representativeIds.includes(seller1.id)) {
      return -1;
    }
    if (representativeIds.includes(seller2.id)) {
      return 1;
    }
    // otherwise they are sorted by name
    return fullName(seller1).localeCompare(fullName(seller2));
  });

  return sellers;
}

export const SALES_CAMPAIGN_DAILY_CALENDAR_PAGE_PATHNAME =
  "/sales-campaigns/:salesCampaignId/calendar/daily/:day";

function findShowroom<S extends { id: string }>(id: string, showrooms: S[]) {
  const showroom = showrooms.find((s) => s.id === id);
  if (!showroom) {
    throw new Error(`showroom with id ${id} not found`);
  }
  return showroom;
}

export default function DailyCalendarPage() {
  const { t } = useTranslation();

  /* GET PARAMS FROM URL/CONTEXT */
  const { season, year, day } = useParams<{
    season: ShowroomSeason;
    year: string;
    day: string;
  }>();

  if (!season || !year || !day) {
    throw new Error("bad URL");
  }

  // to track the selected season and year for the order deadline
  const [selectedSeason] = useState(season);
  const [selectedYear] = useState<number>(parseInt(year, 10));
  const [selectedDay, setSelectedDay] = useState<Date>(
    day ? new Date(day) : new Date(),
  );

  const selectedDayAsString: string = formatDateToISO(selectedDay);
  const {
    organization: { id: organizationId, brand: organizationBrand },
  } = useOrganizationAppContext();

  /* UI HOOKS */
  const [wildcardFilter, setWildcardFilter] = useState("");
  const [isOpenCancelDialog, setIsOpenCancelDialog] = useState<boolean>(false);

  /* DATA FETCHING */
  const {
    data: allOngoingShowrooms = [],
    status: allOngoingShowroomsStatus,
    error: allOngoingShowroomsError,
  } = GetOngoingShowroomsEndpoint.useHook({ organizationId });

  //! TODO REMOVE THIS VARIABLE
  const allSeasonShowrooms = allOngoingShowrooms;

  const calendarFilters = useCalendarFilters({
    showrooms: allSeasonShowrooms,
    onFilterChange: setWildcardFilter,
  });

  const selectedShowrooms = allSeasonShowrooms.filter((s) =>
    calendarFilters.selectedShowroomIds.includes(s.id),
  );

  // fetch the appointments from today
  const { data: appointments = [] } = GetAppointmentsForInterval.useHook({
    organizationId,
    dayAsString: formatDateToISO(selectedDay),
  });

  const allSellers = allSeasonShowrooms
    .flatMap((s) => s.sellers)
    .filter(arrayFilterDuplicateIds);
  const sortedSellers = useSellerSorting(allSellers);

  const componentRef = useRef<HTMLDivElement | null>(null);
  const reactToPrintContent = React.useCallback(() => componentRef.current, []);

  const pageWidth = sortedSellers ? sortedSellers.length * 50 : 100;
  const minOpeningHour = min(
    selectedShowrooms.map(
      (s) => getShowroomHoursOnDate(selectedDay, s).opening,
    ),
  );
  const maxClosingHour = max(
    selectedShowrooms.map(
      (s) => getShowroomHoursOnDate(selectedDay, s).closing,
    ),
  );

  const pageHeight = differenceInHours(maxClosingHour, minOpeningHour) * 40;

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    pageStyle: `
    @media print {
      body { 
        -webkit-print-color-adjust: exact;
      }
      
      @page {
        size: ${pageWidth}mm ${pageHeight}mm;
      }
    }`,
  });

  // order deadlines
  const { data: orderDeadline } = GetOrderDeadline.useHook({
    brandId: organizationBrand?.id,
    season: selectedSeason,
    year: selectedYear,
  });

  /* DATA COMPUTING */
  const isError = allOngoingShowroomsStatus === "error";
  const error =
    allOngoingShowroomsStatus === "error"
      ? allOngoingShowroomsError
      : undefined;

  const filteredSellers = sortedSellers?.filter((seller) =>
    wildcardFilter.length > 0
      ? fullName(seller).toLowerCase().includes(wildcardFilter.toLowerCase())
      : true,
  );

  const filteredAppointments = appointments
    .filter((appointment) =>
      filteredSellers?.some((s) => s.id === appointment.seller.id),
    )
    .filter((appointment) => {
      // an appointment has to be from the same showroom and the same collection
      const combinations = Object.entries(
        calendarFilters.filterManager.values,
      ).flatMap(([showroomId, brandCollectionFilter]) =>
        Object.values(brandCollectionFilter).flatMap((collectionIds) => ({
          showroomId,
          collectionIds,
        })),
      );

      return (
        appointment.type !== AppointmentTypeEnum.BUYING_APPOINTMENT ||
        combinations.find(
          (combination) =>
            combination.showroomId === appointment.showroom.id &&
            combination.collectionIds.includes(
              appointment.collection?.id || "",
            ),
        )
      );
    });

  const [selectedAccountAppointment, setSelectedAccountAppointment] =
    useState<Calendar.AccountAppointment>();
  const [selectedBusyAppointment, setSelectedBusyAppointment] =
    useState<Calendar.BusyAppointment>();
  const [selectedAvailableSlot] = useState<{
    start: Date;
    end: Date;
    sellerId: string;
    sellerName: string;
  }>();
  const [accountAppointmentFormData, setAccountAppointmentFormData] =
    useState<AccountAppointmentFormData>();
  const appointmentDrawer = useDrawer({
    backdrop: true,
    onClose: () => {
      setSelectedAccountAppointment(undefined);
      setAccountAppointmentFormData(undefined);
    },
  });
  const busyAppointmentDrawer = useDrawer({
    backdrop: true,
    onClose: () => {
      setSelectedBusyAppointment(undefined);
    },
  });

  /* MUTATIONS */
  const createBusySlotMutation = CreateBusySlotsEndpoint.useHook({
    organizationId,
  });

  const upsertMutation = UpsertAppointmentEndpoint.useHook({
    organizationId,
    dayAsString: selectedDayAsString,
  });
  const deleteMutation = DeleteAppointmentEndpoint.useHook({
    organizationId,
    dayAsString: selectedDayAsString,
  });

  const handleEditSelectedAccountAppointment = (
    appointment: Calendar.AccountAppointment,
  ) => {
    setAccountAppointmentFormData(appointmentToFormData(appointment));
  };

  const handleClickAppointmentSlot = (id: string) => {
    const appointment = filteredAppointments.find((a) => a.id === id);
    if (!appointment) {
      toast.error(`no appointment found with id : ${id}`);
      return;
    }
    if (Calendar.checkIsBusyAppointment(appointment)) {
      setSelectedBusyAppointment(appointment);
      busyAppointmentDrawer.openDrawer();
    } else {
      setAccountAppointmentFormData(undefined);
      setSelectedAccountAppointment(appointment);
      appointmentDrawer.openDrawer();
    }
  };

  const handleClickAvailableSlot = (
    startTime: Date,
    endTime: Date,
    sellerId: string,
  ) => {
    setSelectedAccountAppointment(undefined);
    setAccountAppointmentFormData({
      id: null,
      showroomId: selectedShowrooms[0].id,
      accountId: null,
      collectionId: null,
      contacts: [],
      date: selectedDay,
      format: null,
      sellerId,
      startTime,
      endTime,
      type: null,
      virtualMeetingApp: null,
      accountOtb: null,
    });
    appointmentDrawer.openDrawer();
  };

  // const handleBlockAvailableSlot = (startTime: Date, sellerId: string) => {
  //   const sellerName = fullName(
  //     allOngoingShowrooms
  //       .flatMap((s) => s.sellers)
  //       .find((s) => s.id === sellerId) || {
  //       firstName: "---",
  //       lastName: "---",
  //     },
  //   );

  //   setSelectedBusyAppointment(undefined);
  //   setSelectedAvailableSlot({
  //     start: startTime,
  //     end: addMinutes(startTime, 60),
  //     sellerId,
  //     sellerName,
  //   });
  //   busyAppointmentDrawer.openDrawer();
  // };

  const handleCancelAppointment = (appointment: Calendar.Appointment) => {
    deleteMutation.mutate(
      { appointmentId: appointment.id },
      {
        onSuccess: appointmentDrawer.closeWithoutConfirmation,
      },
    );
  };

  const handleSubmitAppointment = (
    formData:
      | AccountAppointmentFormDataSubmitted
      | BusyAppointmentEditForm.FormDataValidated,
  ): Promise<void> =>
    new Promise<void>((resolve, reject) => {
      const showroom = allSeasonShowrooms.find(
        (s) => s.id === formData.showroomId,
      );
      if (!showroom) {
        throw new Error("appointment creation did not match any showroom");
      }

      // add account form data if it is set
      const typeSpecificData =
        "type" in formData
          ? {
              account: {
                id: formData.accountId,
              },
              attendees: formData.contacts.map((c) => ({
                id: c.value,
              })),
              collection: formData.collectionId
                ? { id: formData.collectionId }
                : null,
              format: formData.format,
              virtualMeetingApp: formData.virtualMeetingApp,
              type: formData.type,
              title: null,
            }
          : {
              title: formData.title,
              account: null,
              attendees: [],
              collection: null,
              format: null,
              virtualMeetingApp: null,
              type: BusyAppointmentTypeList[0],
            };

      const mutationData = {
        appointment: {
          ...typeSpecificData,
          id: formData.id,
          startTime: ZonedDate.fromLocalDate(
            formData.startTime as LocalDate,
            showroom.timezone,
          ),
          endTime: ZonedDate.fromLocalDate(
            formData.endTime as LocalDate,
            showroom.timezone,
          ),
          showroom: {
            id: showroom.id,
            timezone: showroom.timezone,
          },
          seller: {
            id: formData.sellerId,
          },
          accountOtb: formData.accountOtb,
        },
      };

      upsertMutation.mutate(mutationData, {
        onSuccess: () => {
          appointmentDrawer.closeWithoutConfirmation();
          resolve();
        },
        onError: (err) => {
          reject(err);
        },
      });
    });

  const [hiddenResources, setHiddenResources] = useState<string[]>([]);

  const handleHideResource = (resourceId: string) => {
    setHiddenResources((old) => [...old, resourceId]);
  };
  const handleViewAllResources = () => setHiddenResources([]);

  if (isError) {
    LogService.error("error displaying sales campaign calendar");
    LogService.error(error);
    return (
      <div className="flex items-center justify-center my-10">
        {isError && <div>{`Ooops ${error}`}</div>}
      </div>
    );
  }

  const handleEventInteraction = (e: EventInteractionArgs<object>) => {
    const appt = filteredAppointments.find(
      (a) => "id" in e.event && a.id === e.event.id,
    );
    if (!appt) return;

    let apptData:
      | AccountAppointmentFormDataSubmitted
      | BusyAppointmentEditForm.FormDataValidated;
    if (appt.type === AppointmentTypeEnum.BUSY) {
      apptData = {
        date: new Date(e.start),
        id: appt.id,
        startTime: new Date(e.start),
        endTime: new Date(e.end),
        showroomId: appt.showroom.id,
        sellerId: `${e.resourceId}` || appt.seller.id,
        accountOtb: appt.accountOtb,
        title: appt.title || "",
      } satisfies BusyAppointmentEditForm.FormDataValidated;
    } else {
      apptData = {
        type: appt.type,
        accountId: appt.account.id,
        accountOtb: appt.accountOtb,
        collectionId: appt.collection?.id || null,
        date: appt.startTime.toLocalDate(appt.showroom.timezone),
        id: appt.id,
        startTime: new Date(e.start),
        endTime: new Date(e.end),
        showroomId: appt.showroom.id,
        sellerId: `${e.resourceId}` || appt.seller.id,
        contacts: appt.attendees.map((c) => ({
          value: c.id,
          label: fullName(c),
        })),
        format: appt.format || "IN_PERSON",
        virtualMeetingApp: appt.virtualMeetingApp,
      } satisfies AccountAppointmentFormDataSubmitted;
    }

    handleSubmitAppointment(apptData);
  };

  const resources = filteredSellers
    .filter((s) => !hiddenResources.includes(s.id))
    .map((s) => ({
      resourceId: s.id,
      resourceTitle: fullName(s),
    })) satisfies CalendarResource[];

  const events = filteredAppointments.map((a) => ({
    id: a.id,
    color: a.portfolios[0].color || "#a1b6e8",
    title: a.account ? a.account.name : a.title,
    start: a.startTime.toLocalDate(a.showroom.timezone),
    end: a.endTime.toLocalDate(a.showroom.timezone),
    resourceId: a.seller.id,
    isDraggable: true,
    accountId: a.account?.id || null,
    isMeetingReportFullyFilled:
      !!a.meetingReport && !!a.meetingReport.actualBudget,
    organizationId,
    status: a.status,
    format: a.format,
  })) satisfies CalendarEvent[];

  return (
    <>
      <div className="flex items-center gap-4 p-4">
        <span className="text-2xl font-bold">{t("Calendar.page-title")}</span>

        {/* show the order deadline or a clickable exclamation mark icon to add a deadline */}
        <OrderDeadlineIndicator
          deadline={orderDeadline?.deadline}
          brandId={organizationBrand?.id}
          season={selectedSeason}
          year={selectedYear}
        />
        <button type="button" onClick={handlePrint}>
          Print
        </button>
        {hiddenResources.length > 0 ? (
          <button onClick={handleViewAllResources} type="button">
            Reset resource filter
          </button>
        ) : null}
      </div>
      <ScrollArea className="w-full">
        <CalendarComponent
          // data
          resources={resources}
          events={events}
          date={selectedDay}
          // styling
          slotPropGetter={() => ({
            style: {
              border: "none",
            },
          })}
          components={{
            resourceHeader: makeResourceHeader({
              onToggle: handleHideResource,
            }),
            eventWrapper: EventWrapperComponent,
            event: CalendarEventComponent,
          }}
          // steps of 15 minutes, big line every 60min = 4 steps
          step={15}
          timeslots={4}
          // min/max hours
          min={new Date(1970, 0, 1, 2, 0, 0, 0)}
          max={new Date(1970, 0, 1, 18, 0, 0, 0)}
          // configuration
          resizable
          resizableAccessor={(e) => e.isDraggable}
          selectable
          draggableAccessor="isDraggable"
          localizer={dateFnsLocalizer({
            startOfWeek,
            getDay,
            format,
            locales: ["fr", "en"],
          })}
          resourceIdAccessor={(r) => ("resourceId" in r ? r.resourceId : "")}
          resourceTitleAccessor={(r) =>
            "resourceTitle" in r ? r.resourceTitle : ""
          }
          defaultView="day"
          // handlers
          onEventDrop={handleEventInteraction}
          onEventResize={handleEventInteraction}
          onNavigate={(d) => setSelectedDay(d)}
          onSelectEvent={(event) =>
            "id" in event
              ? handleClickAppointmentSlot(event.id as string)
              : null
          }
          onSelectSlot={(slotInfo) =>
            slotInfo.resourceId
              ? handleClickAvailableSlot(
                  slotInfo.start,
                  slotInfo.end,
                  `${slotInfo.resourceId}`,
                )
              : null
          }
        />
      </ScrollArea>

      <Drawer {...appointmentDrawer.props}>
        <DrawerBody>
          {accountAppointmentFormData && (
            <AppointmentDialogEdit
              showrooms={allSeasonShowrooms}
              otherAppointments={appointments}
              defaultValues={accountAppointmentFormData}
              appointment={selectedAccountAppointment}
              organizationId={organizationId}
              onCancel={() => {
                if (!selectedAccountAppointment?.id) {
                  appointmentDrawer.closeWithoutConfirmation();
                } else {
                  // go back to viewing the appointment
                  setAccountAppointmentFormData(undefined);
                }
              }}
              onSubmit={handleSubmitAppointment}
            />
          )}
          {!accountAppointmentFormData && selectedAccountAppointment && (
            <>
              <AppointmentDialogView appointment={selectedAccountAppointment} />
              <div className="flex justify-end w-full gap-5">
                <button
                  type="button"
                  className="flex items-center justify-center h-10 gap-2 cursor-pointer text-primaryRed w-54"
                  onClick={() => setIsOpenCancelDialog(true)}
                >
                  <VscTrash />
                  <span>{t("Calendar.appointment.button.cancel")}</span>
                </button>
                <Button
                  onClick={() =>
                    handleEditSelectedAccountAppointment(
                      selectedAccountAppointment,
                    )
                  }
                  theme="PRIMARY"
                >
                  <HiOutlinePencil />
                  <span>{t("Calendar.appointment.button.edit")}</span>
                </Button>
              </div>
            </>
          )}
        </DrawerBody>
      </Drawer>

      <Drawer
        {...busyAppointmentDrawer.props}
        drawerTitle={
          <h2 className="text-3xl font-medium">
            {t(
              selectedBusyAppointment
                ? selectedBusyAppointment.title
                : "Calendar.DailyCalendarPage.block-appointment-drawer.title",
            )}
          </h2>
        }
      >
        <DrawerBody>
          {selectedBusyAppointment ? (
            <BusySlotEditForm
              appointment={selectedBusyAppointment}
              organizationId={organizationId}
              onCancel={busyAppointmentDrawer.closeWithoutConfirmation}
              onDelete={busyAppointmentDrawer.closeWithoutConfirmation}
              showroomDates={allOngoingShowrooms.flatMap((s) =>
                s.openingDays.map((od) => od.day),
              )}
              sellers={allOngoingShowrooms
                .flatMap((s) => s.sellers)
                .filter(arrayFilterDuplicateIds)}
              onSubmit={busyAppointmentDrawer.closeWithoutConfirmation}
              initialValues={BusyAppointmentEditForm.toFormData(
                selectedBusyAppointment,
              )}
            />
          ) : (
            <BusySlotCreateForm
              onCancel={busyAppointmentDrawer.closeWithoutConfirmation}
              onSubmit={(values) => {
                Promise.allSettled(
                  values.showroomIds.map((showroomId) => {
                    const showroom = findShowroom(
                      showroomId,
                      allSeasonShowrooms,
                    );
                    return createBusySlotMutation.mutateAsync({
                      showroomId,
                      title: values.title,
                      sellerIds: values.sellers.map((s) => s.value),
                      slots: values.dates.map((d) => ({
                        startTime: ZonedDate.fromLocalDate(
                          combineDateAndTime(d, values.start) as LocalDate,
                          showroom.timezone,
                        ),
                        endTime: ZonedDate.fromLocalDate(
                          combineDateAndTime(d, values.end) as LocalDate,
                          showroom.timezone,
                        ),
                      })),
                    });
                  }),
                ).then(busyAppointmentDrawer.closeWithoutConfirmation);
              }}
              defaultValues={
                selectedAvailableSlot
                  ? {
                      dates: [selectedAvailableSlot.start],
                      sellers: [
                        {
                          label: selectedAvailableSlot.sellerName,
                          value: selectedAvailableSlot.sellerId,
                        },
                      ],
                      start: selectedAvailableSlot.start,
                      end: selectedAvailableSlot.end,
                      showroomIds: calendarFilters.selectedShowroomIds,
                    }
                  : {}
              }
              showrooms={allSeasonShowrooms}
              sellers={allSeasonShowrooms.flatMap((s) => s.sellers)}
            />
          )}
        </DrawerBody>
      </Drawer>

      {selectedAccountAppointment && (
        <ConfirmModal
          show={isOpenCancelDialog}
          title={t("Calendar.appointment.delete-dialog.title")}
          confirmLabel="Yes, delete"
          onCancel={() => {
            setIsOpenCancelDialog(false);
          }}
          onConfirm={() => handleCancelAppointment(selectedAccountAppointment)}
          isConfirmLoading={deleteMutation.isPending}
        >
          <span>
            {t("Calendar.appointment.delete-dialog.appointment-date")}&nbsp;
            <strong className="font-medium">
              {selectedAccountAppointment.startTime.formatLocalizedDateAtTimezone(
                selectedAccountAppointment?.showroom.timezone,
              )}
            </strong>
            &nbsp;
            {t("Calendar.appointment.delete-dialog.appointment-name")}&nbsp;
            <strong className="font-medium">
              {selectedAccountAppointment.account.name}
            </strong>
            .&nbsp;
            {t("Calendar.appointment.delete-dialog.question-are-you-sure")}
          </span>
        </ConfirmModal>
      )}
    </>
  );
}
