import React from "react";

import { useTranslation } from "react-i18next";
import { HiOutlineLogout } from "react-icons/hi";
import { NavLink, Outlet, useNavigation } from "react-router-dom";

import modaResaLogo from "@app/assets/logos/moda-resa-small-logo.png";
import useAuthentication from "@services/authentication/useAuthentication";
import ErrorBoundary from "@services/error/error-boundary";
import AdminOnly from "@shared/components/guards/admin-only";

import Loading from "../../components/feedback/Loading";

function BackOfficeLayout() {
  const navigation = useNavigation();
  const { signOut } = useAuthentication();

  const { t } = useTranslation();

  const buildClassName = ({ isActive }: { isActive: boolean }) =>
    `rounded-2xl p-2 text-center w-full ${
      isActive
        ? "bg-primaryElectricBlue text-white"
        : "hover:bg-primaryLightElectricBlue"
    }`;

  return (
    <AdminOnly>
      <div className="flex flex-row h-full">
        <div className="sticky max-h-screen top-0 w-1/6 p-5 flex flex-col gap-8 place-items-center border-r border-primaryLightGrey">
          <img
            className="py-6"
            alt="modaresa logo"
            width="200"
            src={modaResaLogo}
          />
          <NavLink
            to="/backoffice/list-organizations"
            className={buildClassName}
          >
            {t("BackOffice.layout.list-organizations")}
          </NavLink>
          <NavLink
            to="/backoffice/onboard-organization"
            className={buildClassName}
          >
            {t("BackOffice.layout.onboard-organization")}
          </NavLink>
          <NavLink to="/backoffice/list-buyers" className={buildClassName}>
            {t("BackOffice.layout.list-buyers")}
          </NavLink>
          <button
            type="button"
            onClick={() => signOut()}
            className="flex items-center gap-2 p-2"
          >
            <HiOutlineLogout /> {t("Components.sidebar.logout")}
          </button>
        </div>
        <div className="w-5/6 h-full">
          {navigation.state !== "idle" && <Loading type="screen" />}
          {navigation.state === "idle" && (
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
          )}
        </div>
      </div>
    </AdminOnly>
  );
}

export default BackOfficeLayout;
