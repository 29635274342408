import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { appointmentHydratingSchema } from "@models/Appointment";
import { brandSchema } from "@models/Brand";
import { collectionSchema } from "@models/Collection";
import { organizationContactSchema } from "@models/OrganizationContact";
import { showroomSchema } from "@models/Showroom";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey, handleParsingError } from "@services/api/helper";

export namespace BookingGetBookedAppointmentsEndpoint {
  export const outputSchema = z.array(
    appointmentHydratingSchema
      .pick({
        id: true,
        accountOtb: true,
        startTime: true,
        endTime: true,
        format: true,
        type: true,
        virtualMeetingApp: true,
        warnings: true,
        bookedThrough: true,
        status: true,
        title: true,
      })
      .extend({
        attendees: z.array(
          organizationContactSchema.pick({
            id: true,
            firstName: true,
            lastName: true,
            email: true,
            markets: true,
            phoneNumber: true,
            position: true,
          }),
        ),
        showroom: showroomSchema.pick({
          id: true,
          timezone: true,
          formattedAddress: true,
        }),
        collection: collectionSchema
          .pick({
            id: true,
            name: true,
          })
          .extend({
            brand: brandSchema.pick({
              id: true,
              name: true,
            }),
          })
          .nullable(),
      }),
  );
  export type Output = z.infer<typeof outputSchema>;

  export interface HookParams {
    invitationId: string;
  }

  // @todo add path
  export function path({ invitationId }: HookParams) {
    return `/bookings/${invitationId}/appointments`;
  }
  export const getQueryKeys = (p: HookParams) => getAPIQueryKey(path(p));

  export function call(params: HookParams) {
    return axiosInstance
      .get<Output>(path(params))
      .then((r) =>
        handleParsingError(
          outputSchema,
          r.data,
          "Error parsing booked appointments",
        ),
      );
  }

  export function useHook(params: HookParams) {
    return useQuery({
      queryKey: getAPIQueryKey(path(params)),
      queryFn: () => call(params),
    });
  }
}
