import React, { useEffect, useId, useState } from "react";

import { Portal, Transition } from "@headlessui/react";
import { IoIosClose } from "react-icons/io";

import { fadeSlideInOutProps } from "@components/transitions/FadeInOut";
import {
  MultiOptionItemTree,
  hasOption,
  optionEquality,
} from "@helpers/MultiOptionItemTree";
import usePopup from "@helpers/Popup";

import TreeItem from "./TreeItem";

const expandOptions = (
  option: MultiOptionItemTree,
  current: MultiOptionItemTree[],
): MultiOptionItemTree[] => {
  let expanded = [option, ...current];
  option.options?.forEach((o) => {
    expanded = expandOptions(o, expanded);
  });
  return expanded;
};

const recursiveSelect = (
  option: MultiOptionItemTree,
): MultiOptionItemTree[] => {
  if (option.options) {
    const childOptions: MultiOptionItemTree[] = option.options.flatMap((o) =>
      recursiveSelect(o),
    );
    return [option, ...childOptions];
  }
  return [option];
};

const recursiveDeselect = (
  option: MultiOptionItemTree,
  current: MultiOptionItemTree[],
): MultiOptionItemTree[] => {
  let remaining = current.filter((o) => o.value !== option.value);
  option.options?.forEach((o) => {
    remaining = recursiveDeselect(o, remaining);
  });
  return remaining;
};

const parentDeselect = (
  option: MultiOptionItemTree,
  remaining: MultiOptionItemTree[],
): MultiOptionItemTree[] => {
  if (option.parent !== undefined) {
    const { parent } = option;
    return parentDeselect(
      option.parent,
      remaining.filter((o) => o.value !== parent.value),
    );
  }
  return remaining;
};

interface MultiSelectProps {
  name: string;
  className?: string;
  id?: string;
  placeholder?: string;
  defaultValues?: MultiOptionItemTree[];
  options: MultiOptionItemTree[];
  onChange: (options: MultiOptionItemTree[]) => void;
}

function MultiSelectTree(props: MultiSelectProps) {
  const key = useId();
  const {
    id,
    name,
    className,
    placeholder = "Select an item",
    defaultValues = [],
    onChange,
    options,
  } = props;

  const [IsOpen, setIsOpen] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<MultiOptionItemTree[]>(
    [],
  );
  const [openItems, setOpenItems] = useState<MultiOptionItemTree[]>([]);
  const popup = usePopup({
    placement: "bottom",
    sameWidth: true,
    hasOffset: [0, 0],
  });
  useEffect(() => {
    let expanded: MultiOptionItemTree[] = [];
    defaultValues?.forEach((o) => {
      expanded = expandOptions(o, expanded);
    });
    setSelectedOptions(expanded);
  }, [defaultValues]);

  const handleOnSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    option: MultiOptionItemTree,
  ) => {
    const {
      target: { checked },
    } = event;

    if (checked) {
      const newSelectedOptions = recursiveSelect(option);
      setSelectedOptions([...selectedOptions, ...newSelectedOptions]);
    } else {
      let remaining = recursiveDeselect(option, selectedOptions);
      remaining = parentDeselect(option, remaining);
      setSelectedOptions(remaining);
    }
  };

  const handleOnToggleItem = (option: MultiOptionItemTree, isOpen: boolean) => {
    if (isOpen) {
      setOpenItems([...openItems.filter((o) => !optionEquality(option, o))]);
    } else {
      setOpenItems([...openItems, option]);
    }
  };

  const collapseOptions = (
    option: MultiOptionItemTree,
    optionsToCollapse: MultiOptionItemTree[],
  ): MultiOptionItemTree[] => {
    let collapsed = optionsToCollapse;
    if (option.options) {
      option.options.forEach((childOption) => {
        collapsed = collapsed.filter((o) => !optionEquality(childOption, o));
        collapsed = collapseOptions(childOption, collapsed);
      });
    }
    return collapsed;
  };

  const handleOnApply = () => {
    let collapsed = selectedOptions;
    selectedOptions.forEach((o) => {
      collapsed = collapseOptions(o, collapsed);
    });
    onChange(collapsed);
    setIsOpen(false);
  };

  const handleOnCancel = () => {
    setSelectedOptions(defaultValues || []);
    setIsOpen(false);
  };

  const handleOnRemoveItem = (item: MultiOptionItemTree) => {
    const remaining = (defaultValues || []).filter(
      (o) => !optionEquality(o, item),
    );
    onChange(remaining);
  };

  return (
    <div className="relative w-full h-full text-gray-700">
      <div
        className={`flex flex-col rounded-lg shadow cursor-pointer form-select bg-multiselect ${
          className || ""
        }`}
        ref={popup.setReferenceElement}
      >
        <button
          id={id}
          aria-labelledby={id}
          onClick={() => {
            setIsOpen(true);
          }}
          type="button"
          className="flex justify-start w-full h-full item focus:border-gray-700 focus:ring-gray-700"
        >
          <span className="capitalize">{placeholder}</span>
        </button>
        {defaultValues && defaultValues.length > 0 && (
          <div className="flex flex-wrap w-full gap-1 mt-2 item">
            {defaultValues.map((item) => (
              <span
                key={`${key}_default_${item.value}`}
                className="flex items-center gap-2 px-4 py-1 bg-gray-300 rounded-full"
              >
                <span className="capitalize">{item.label}</span>
                <button
                  onClick={() => {
                    handleOnRemoveItem(item);
                  }}
                  aria-label={`remove ${item.value} from ${name}`}
                  type="button"
                >
                  <IoIosClose className="w-5 h-5" />
                </button>
              </span>
            ))}
          </div>
        )}
      </div>
      <Portal
        as="div"
        className="fixed z-select"
        ref={popup.setPopupElement}
        style={popup.styles.popper}
        {...popup.attributes.popper}
      >
        <Transition {...fadeSlideInOutProps} show={IsOpen}>
          <nav
            aria-label={`options for ${name}`}
            className="bg-white border border-gray-300 rounded-b-lg w-full top-8 py-2"
          >
            <ul className="w-full py-2 overflow-y-auto max-h-44">
              {options.map((continent: MultiOptionItemTree) => (
                <TreeItem
                  key={`${key}_${continent.value}`}
                  name={name}
                  item={continent}
                  isOpen={hasOption(openItems, continent)}
                  isSelected={hasOption(selectedOptions, continent)}
                  onChange={handleOnSelect}
                  onToggle={handleOnToggleItem}
                >
                  {continent.options && (
                    <ul>
                      {continent.options.map((country: MultiOptionItemTree) => (
                        <TreeItem
                          key={`${key}_${country.value}`}
                          name={name}
                          item={country}
                          isOpen={hasOption(openItems, country)}
                          isSelected={hasOption(selectedOptions, country)}
                          onChange={handleOnSelect}
                          onToggle={handleOnToggleItem}
                        >
                          {country.options && (
                            <ul>
                              {country.options.map(
                                (city: MultiOptionItemTree) => (
                                  <TreeItem
                                    key={`${key}_${city.value}`}
                                    name={name}
                                    item={city}
                                    isSelected={hasOption(
                                      selectedOptions,
                                      city,
                                    )}
                                    onChange={handleOnSelect}
                                    onToggle={handleOnToggleItem}
                                  />
                                ),
                              )}
                            </ul>
                          )}
                        </TreeItem>
                      ))}
                    </ul>
                  )}
                </TreeItem>
              ))}
            </ul>
            <hr />
            <div className="flex flex-row gap-2 px-2 pt-2">
              <button
                type="button"
                aria-label={`cancel ${name} selection`}
                className="w-full h-10 text-primaryDarkGrey border border-primaryLightGrey rounded-full cursor-pointer"
                onClick={handleOnCancel}
              >
                <span>Cancel</span>
              </button>
              <button
                type="button"
                aria-label={`apply ${name} selection`}
                className="w-full h-10 text-white rounded-full shadow-sm cursor-pointer bg-primaryElectricBlue"
                onClick={handleOnApply}
              >
                <span>Apply</span>
              </button>
            </div>
          </nav>
        </Transition>
      </Portal>
    </div>
  );
}

export default MultiSelectTree;
