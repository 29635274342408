import React, { useCallback } from "react";

import { filesize } from "filesize";
import { FileDrop } from "react-file-drop";
import { Trans, useTranslation } from "react-i18next";
import { HiOutlineTrash, HiOutlineXCircle } from "react-icons/hi";
import { MdCheckCircleOutline } from "react-icons/md";

import TextField from "@components/data-entry/TextField";

import Button from "./Button";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getFileDetails(filename: string): { name: string; extension: string } {
  // Use a regular expression to match the last dot in the filename
  const dotMatch = filename.match(/(.*?)\.(.+)$/);

  if (dotMatch) {
    const name = dotMatch[1];
    const extension = dotMatch[2];
    return { name, extension };
  }
  // If no dot is found, consider the whole string as the filename and extension as empty
  return { name: filename, extension: "" };
}

export interface FileToUpload {
  file: File;
  name: string;
  status: "ok" | "error";
}

function FileUpload({
  file,
  language,
  handleFileNameChange,
  handleFileDelete,
}: {
  file: FileToUpload;
  language: string;
  handleFileNameChange: (name: string) => void;
  handleFileDelete: () => void;
}) {
  const details = getFileDetails(file.file.name);
  return (
    <div
      key={`uploaded-${file.file.size}`}
      className="flex items-center justify-between mt-4"
    >
      <span className="flex items-center">
        {file.status === "ok" && (
          <span role="img" aria-label="ok">
            <MdCheckCircleOutline className="mr-1 text-statusGreenDark" />
          </span>
        )}
        {file.status === "error" && (
          <span role="img" aria-label="error">
            <HiOutlineXCircle className="mr-1 text-statusRedDark" />
          </span>
        )}
        <TextField
          aria-label="file name"
          hasError={file.name === ""}
          className="h-8"
          clearable={false}
          value={details.name}
          onChange={(name) => {
            handleFileNameChange(`${name}.${details.extension}`);
          }}
        />
        <span className="whitespace-nowrap bg-primaryLightElectricBlue text-base px-3 py-0.5 rounded-full ml-2">
          {details.extension}
        </span>
        <span className="whitespace-nowrap bg-primaryLightElectricBlue text-base px-3 py-0.5 rounded-full ml-2">
          {
            filesize(file.file.size, {
              locale: language,
            }) as string
          }
        </span>
      </span>
      <Button
        theme="ICON"
        label={`remove file ${file.file.name}`}
        type="button"
        onClick={() => handleFileDelete()}
      >
        <HiOutlineTrash className="text-statusRedDark" />
      </Button>
    </div>
  );
}

interface FileUploadInputProps {
  onFileChange: (files: FileToUpload[]) => void;
  parentFiles: FileToUpload[];
  authorizedFileExtension: string[];
  label?: string;
  description?: string;
  className?: string;
  disabled?: boolean;
  maxFileNumber?: number;
  maxFileSize?: number;
}

function FilesUploadInput({
  onFileChange,
  parentFiles,
  authorizedFileExtension,
  label,
  description,
  className,
  disabled,
  maxFileNumber = 1,
  maxFileSize,
}: FileUploadInputProps) {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const { i18n, t } = useTranslation();

  const borderColor = disabled ? "border-primaryDarkGrey" : "border-indigo-600";
  const currentSize = parentFiles.reduce((acc, cur) => acc + cur.file.size, 0);

  const handleClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleFileChange = (inputFiles: FileList | null) => {
    if (!inputFiles) return;
    const tmpSize =
      currentSize +
      Array.from(inputFiles).reduce((acc, cur) => acc + cur.size, 0);
    if (maxFileSize !== undefined && tmpSize > maxFileSize) return;
    if (
      maxFileNumber !== undefined &&
      inputFiles.length + parentFiles.length > maxFileNumber
    )
      return;

    const newFiles: FileToUpload[] = [];
    Array.from(inputFiles).forEach((inputFile) => {
      newFiles.push({
        file: inputFile,
        name: inputFile.name,
        status: "ok",
      });
    });

    const newValue = [...parentFiles, ...newFiles];
    onFileChange(newValue);
  };

  const handleFileNameChange = (index: number, value: string) => {
    const filesWithNames = Array.from(parentFiles).map((fileToUpload, i) => {
      const blob = fileToUpload.file.slice(
        0,
        fileToUpload.file.size,
        fileToUpload.file.type,
      );
      return index === i
        ? {
            ...fileToUpload,
            name: value,
            // we update the name of the file
            file: new File([blob], value, {
              type: fileToUpload.file.type,
            }),
          }
        : fileToUpload;
    });
    onFileChange(filesWithNames);
  };

  const handleFileDelete = useCallback(
    (index: number) => {
      const newFileList = [...parentFiles];
      newFileList.splice(index, 1);
      onFileChange(newFileList);
      if (hiddenFileInput?.current) {
        hiddenFileInput.current.value = "";
      }
    },
    [parentFiles, onFileChange],
  );

  return (
    <div className={`flex flex-col my-4 ${className}`}>
      {label && (
        <label htmlFor="upload" className="font-neueMontreal-Medium mb-2">
          {label}
          {description && (
            <div className="mt-1 text-xs text-primaryDarkGrey">
              {description}
            </div>
          )}
        </label>
      )}
      <FileDrop
        onDrop={(files) => handleFileChange(files)}
        className={`${borderColor} inline-block hover:bg-primaryLightElectricBlue px-8 py-6 rounded-lg border-dashed border-2`}
      >
        <div className="flex justify-center items-center gap-2">
          <label htmlFor="upload">
            <Trans
              i18nKey="Components.FileUploadInput.text"
              values={{ format: authorizedFileExtension.join(",  ") }}
            >
              Components.FileUploadInput<strong className="font-bold">.</strong>
              text
            </Trans>
          </label>
          <input
            id="upload"
            type="file"
            multiple={maxFileNumber > 1}
            ref={hiddenFileInput}
            onChange={(e) => handleFileChange(e.target.files)}
            className="hidden text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
            accept={authorizedFileExtension.join(",")}
            disabled={disabled}
          />
          <Button
            theme="PRIMARY"
            type="button"
            onClick={handleClick}
            disabled={disabled}
          >
            {t("Components.FileUploadInput.browse-file")}
          </Button>
        </div>
        {(maxFileNumber > 1 || maxFileSize) && (
          <div className="text-sm flex justify-center mt-4">
            {maxFileSize && (
              <span>
                {t("Components.FileUploadInput.max-file-size")}
                {
                  filesize(currentSize, {
                    locale: i18n.language,
                  }) as string
                }
                {" / "}
                {
                  filesize(maxFileSize, {
                    locale: i18n.language,
                  }) as string
                }
              </span>
            )}
            {maxFileNumber > 1 && maxFileSize && (
              <span className="mx-1">|</span>
            )}
            {maxFileNumber > 1 && (
              <span>
                {t("Components.FileUploadInput.max-file-number")}
                {maxFileNumber}
              </span>
            )}
          </div>
        )}
        {parentFiles.length > 0 && (
          <>
            <div className="h-px mt-4 bg-primaryElectricBlue" />
            <div className="text-sm">
              {parentFiles.map((parentFile, index) => (
                <FileUpload
                  key={`file-to-upload-${parentFile.file.size}`}
                  file={parentFile}
                  language={i18n.language}
                  handleFileNameChange={(name) =>
                    handleFileNameChange(index, name)
                  }
                  handleFileDelete={() => handleFileDelete(index)}
                />
              ))}
            </div>
          </>
        )}
      </FileDrop>
    </div>
  );
}

export default FilesUploadInput;
