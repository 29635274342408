import React, { useEffect } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import { z } from "zod";

import modaresaLogo from "@app/assets/logos/moda-resa.svg";
import InputLabel from "@components/data-display/InputLabel";
import Tag from "@components/data-display/Tag";
import AddressAutocomplete from "@components/data-entry/AddressAutocomplete";
import Button from "@components/data-entry/Button";
import MultiSelect from "@components/data-entry/MultiSelect";
import Select from "@components/data-entry/Select";
import { SelectOptionBase } from "@components/data-entry/wrapper/ReactSelect";
import { organizationSchema } from "@models/Organization";
import { GetAllBrandsEndpoint } from "@services/api/brands/get-all-brands";
import { CreateOrganization } from "@services/api/organization/CreateOrganization";
import { auth } from "@services/authentication/firebase";
import useAuthentication from "@services/authentication/useAuthentication";
import { marketCategoriesGrouped } from "@shared/collections/helpers";
import TimezoneAutocomplete from "@shared/components/form/timezone-autocomplete";

export function useBrandSignupLink() {
  const { brandId } = useParams();

  useEffect(() => {
    if (brandId) {
      localStorage.setItem("signup-as-brand", brandId || "");
    }
  }, [brandId]);

  return [
    localStorage.getItem("signup-as-brand"),
    () => localStorage.removeItem("signup-as-brand"),
  ] as const;
}

const seasonsOptions = [
  { label: "Autumn / Winter - Pre collection", value: "PRE_AW" },
  { label: "Autumn / Winter", value: "AW" },
  { label: "Spring / Summer - Pre collection", value: "PRE_SS" },
  { label: "Spring / Summer", value: "SS" },
];

const activeLocationsOptions = [
  {
    label: "Paris",
    value: "PARIS",
  },
  {
    label: "London",
    value: "LONDON",
  },
  {
    label: "Milan",
    value: "MILAN",
  },
  {
    label: "New York",
    value: "NEW_YORK",
  },
  {
    label: "Tokyo",
    value: "TOKYO",
  },
];

const createOrganizationFormSchema = z.object({
  name: organizationSchema.shape.name.min(1, { message: "Required" }),
  type: organizationSchema.shape.type,
  address: organizationSchema.shape.address,
  timezone: organizationSchema.shape.timezone,
  brand: z
    .object({
      id: z.string().uuid().optional(),
      numberOfStockists: z.string(),
      activeSeasons: z.array(z.string()).min(1, { message: "Required" }),
      numberOfYearlyCollections: z.string().min(1, { message: "Required" }),
      activeMarkets: z.array(z.string()).min(1, { message: "Required" }),
      activeLocations: z
        .array(
          z.object({
            label: z.string(),
            value: z.string(),
          }),
        )
        .min(1, { message: "Required" }),
    })
    .optional(),
});
type CreateOrganizationFormData = z.infer<typeof createOrganizationFormSchema>;

const yearlyCollectionsOptions = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
  { label: "> 10", value: "11+" },
];

function MultiValueContainer({
  data,
}: {
  data: { label: string; value: string };
}) {
  return <Tag className="mx-0.5">{data.label}</Tag>;
}

const marketsCategoriesOptionsFlat = marketCategoriesGrouped.flatMap(
  (group) => group.options,
);

const getOptionBackground = (state: {
  isSelected: boolean;
  isFocused: boolean;
}) => {
  if (state.isSelected) {
    return "#93C5FD";
  }
  if (state.isFocused) {
    return "#F5F5F5";
  }
  return "transparent";
};

const selectControlStyles = (state: { isFocused: boolean }) => ({
  [`@media (min-width: 1029px)`]: {
    height: "48px",
    minHeight: "48px",
    maxHeight: "48px",
  },
  [`@media (min-width: 1019px) and (max-width: 1028px)`]: {
    height: "40px",
    minHeight: "40px",
    maxHeight: "40px",
  },
  [`@media (max-width: 756px)`]: {
    height: "32px",
    minHeight: "32px",
    maxHeight: "32px",
  },
  borderColor: state.isFocused ? "#7B83EB" : "#B0B0C0",
  borderWidth: "1px",
  outline: state.isFocused ? "1px solid #7B83EB" : "none",
  borderRadius: "0.5rem",
  "&:hover": {
    borderColor: "#7B83EB",
  },
});

export default function CreateOrganizationPage() {
  const { t } = useTranslation();
  const marketsCategoriesOptions = marketCategoriesGrouped.map((group) => ({
    label: t(`Common.market-category-group.${group.label}`),
    options: group.options.map((option) => ({
      label: t(`Common.market-category-group.${option.label}`),
      value: option.value,
    })),
  }));

  const [brandSignupId, removeBrandSignupId] = useBrandSignupLink();
  const { updateUser } = useAuthentication();
  const navigate = useNavigate();
  const { data: brands = [] } = GetAllBrandsEndpoint.useHook();

  // display only brands that aren't already registered as an organisation
  const filteredBrands = brands.filter((b) => b.organizationId === null);

  const canChangeBrand =
    !brandSignupId || !filteredBrands.find((b) => b.id === brandSignupId);

  const form = useForm<CreateOrganizationFormData>({
    resolver: zodResolver(createOrganizationFormSchema),
    defaultValues: {
      name: "",
      type: "BRAND",
      brand: {
        id: brandSignupId || undefined,
        activeMarkets: [],
        activeLocations: [],
        activeSeasons: [],
      },
    },
  });

  const { mutateAsync: createOrganization } = CreateOrganization.useHook();

  const submit = async (formData: CreateOrganizationFormData) => {
    let data: CreateOrganization.Input;
    if (formData.type === "BRAND") {
      if (!formData.brand) {
        throw new Error("Brand is required");
      }
      data = {
        name: formData.name,
        type: formData.type,
        address: formData.address,
        timezone: formData.timezone,
        brand: {
          numberOfStockists: formData.brand.numberOfStockists,
          activeSeasons: formData.brand.activeSeasons,
          numberOfYearlyCollections: formData.brand.numberOfYearlyCollections,
          activeMarkets: formData.brand.activeMarkets,
          activeLocations: formData.brand.activeLocations.map((l) => l.value),
        },
      };
    } else {
      data = {
        name: formData.name,
        type: formData.type,
        address: formData.address,
        timezone: formData.timezone,
      };
    }

    const organization = await createOrganization({
      data,
    });

    const idTokenResult = await auth.currentUser?.getIdTokenResult();
    if (idTokenResult) {
      removeBrandSignupId();
      await updateUser(idTokenResult.token);
      toast.success(
        t("Toast.organization-{{name}}-created", { name: organization.name }),
      );

      if (organization.currentPlan === "PRO") {
        navigate("/dashboard");
      }

      if (organization.currentPlan === "LITE") {
        navigate("/crm/accounts");
      }
    }
  };

  const {
    formState: { errors },
  } = form;
  const address = form.watch("address");
  const timezone = form.watch("timezone");
  const activeSeasons = form.watch("brand.activeSeasons");
  const activeMarkets = form.watch("brand.activeMarkets");
  const activeLocations = form.watch("brand.activeLocations");
  const name = form.watch("name");
  const brandId = form.watch("brand.id");
  const type = form.watch("type");

  return (
    <div className="h-screen">
      <div className="absolute blur-sm pointer-events-none" />
      <div className="bg-white rounded-lg shadow-lg flex flex-col gap-4 p-4 pt-8 w-1/2 mx-auto mt-12">
        <img src={modaresaLogo} alt="Modaresa" className="w-48" />
        <h4 className="flex items-center gap-2 text-2xl font-bold">
          {t("CreateOrganizationPage.title")}
        </h4>
        <form
          onSubmit={form.handleSubmit(submit)}
          className="flex flex-col gap-2"
        >
          <div className="grid grid-cols-2 gap-2">
            <InputLabel htmlFor="type" error={errors.type?.message}>
              {t("CreateOrganizationPage.type-label")}
            </InputLabel>
            <InputLabel htmlFor="brand.id" error={errors.name?.message}>
              {t("CreateOrganizationPage.name-label")}
            </InputLabel>
            <Controller
              name="type"
              control={form.control}
              render={({ field }) => (
                <Select
                  id="type"
                  name="type"
                  options={[
                    {
                      key: "types",
                      label: "Types",
                      options: [
                        { label: "Brand", value: "BRAND" },
                        { label: "Agency", value: "AGENCY" },
                      ],
                    },
                  ]}
                  onChange={field.onChange}
                  defaultValue={
                    field.value ? { value: field.value } : undefined
                  }
                />
              )}
            />
            <CreatableSelect
              // React Select components require styling their internal container elements
              styles={{
                control: (base, state) => ({
                  ...base,
                  ...selectControlStyles(state),
                }),
                indicatorSeparator: () => ({
                  display: "none",
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: getOptionBackground(state),
                  color: state.isSelected ? "white" : "inherit",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: state.isSelected ? "#93C5FD" : "#F5F5F5",
                  },
                }),
                valueContainer: (base) => ({
                  ...base,
                  [`@media (min-width: 1020px)`]: {
                    padding: "0.5rem",
                    height: "46px",
                  },
                  [`@media (max-width: 1019px)`]: {
                    padding: "2px 8px",
                    height: "30px",
                  },
                }),
                input: (base) => ({
                  ...base,
                  margin: 0,
                  padding: 0,
                }),
                indicatorsContainer: (base) => ({
                  ...base,
                  [`@media (min-width: 1020px)`]: {
                    height: "46px",
                  },
                  [`@media (max-width: 1019px)`]: {
                    height: "30px",
                  },
                }),
                dropdownIndicator: (base) => ({
                  ...base,
                  color: "#B0B0C0",
                  "&:hover": {
                    color: "#7B83EB",
                  },
                }),
              }}
              isDisabled={!canChangeBrand}
              options={filteredBrands.map((b) => ({
                label: b.name,
                value: b.id,
              }))}
              id="name"
              placeholder="Select an item"
              value={
                brandId
                  ? {
                      label:
                        filteredBrands.find((b) => b.id === brandId)?.name ??
                        "",
                      value: brandId,
                    }
                  : {
                      label: name,
                      value: name,
                    }
              }
              onCreateOption={(inputValue) => {
                form.setValue("name", inputValue, { shouldValidate: true });
                form.setValue("brand.id", undefined, { shouldValidate: true });
              }}
              onChange={(v) => {
                if (v) {
                  form.setValue("name", v.label, { shouldValidate: true });
                  form.setValue("brand.id", v.value, { shouldValidate: true });
                }
              }}
            />
          </div>

          <InputLabel htmlFor="address" error={errors.address?.message}>
            {t("CreateOrganizationPage.address-label")}
          </InputLabel>
          <AddressAutocomplete
            id="address"
            defaultValue={address}
            onChange={(v) => {
              if (v) {
                form.setValue("address", v, { shouldValidate: true });
              }
            }}
          />

          <InputLabel htmlFor="timezone" error={errors.timezone?.message}>
            {t("CreateOrganizationPage.timezone-label")}
          </InputLabel>
          <TimezoneAutocomplete
            id="timezone"
            value={timezone}
            onChange={(v) => {
              if (v) {
                form.setValue("timezone", v, { shouldValidate: true });
              }
            }}
          />

          {type === "BRAND" && (
            <>
              {/** number of stockists */}
              <InputLabel
                htmlFor="brand.numberOfStockists"
                error={errors.brand?.numberOfStockists?.message}
              >
                {t("CreateOrganizationPage.number-of-stockists-label")}
              </InputLabel>

              <Controller
                name="brand.numberOfStockists"
                control={form.control}
                render={({ field }) => (
                  <Select
                    id="brand.numberOfStockists"
                    name="brand.numberOfStockists"
                    options={[
                      {
                        key: "stockists",
                        label: "Number of stockists",
                        options: [
                          { label: "1-50", value: "1-50" },
                          { label: "51-100", value: "51-100" },
                          { label: "101-200", value: "101-200" },
                          { label: "201-500", value: "201-500" },
                          { label: "501-1000", value: "501-1000" },
                          { label: "1001-2000", value: "1001-2000" },
                          { label: "2001-5000", value: "2001-5000" },
                          { label: "> 5000", value: "5001+" },
                        ],
                      },
                    ]}
                    onChange={field.onChange}
                    defaultValue={
                      field.value ? { value: field.value } : undefined
                    }
                    sort="none"
                  />
                )}
              />

              {/** active seasons */}
              <InputLabel
                htmlFor="brand.activeSeasons"
                error={errors.brand?.activeSeasons?.message}
              >
                {t("CreateOrganizationPage.active-seasons-label")}
              </InputLabel>
              <MultiSelect
                placeholder="Select an item"
                options={seasonsOptions}
                hideSelectedOptions
                id="brand.activeSeasons"
                styles={{
                  control: (base, state) => ({
                    ...base,
                    ...selectControlStyles(state),
                  }),
                  valueContainer: (base) => ({
                    ...base,
                    [`@media (min-width: 1020px)`]: {
                      padding: "0.5rem",
                      height: "46px",
                    },
                    [`@media (max-width: 1019px)`]: {
                      padding: "2px 8px",
                      height: "30px",
                    },
                  }),
                  input: (base) => ({
                    ...base,
                    margin: 0,
                    padding: 0,
                  }),
                  indicatorsContainer: (base) => ({
                    ...base,
                    [`@media (min-width: 1020px)`]: {
                      height: "46px",
                    },
                    [`@media (max-width: 1019px)`]: {
                      height: "30px",
                    },
                  }),
                }}
                value={activeSeasons
                  .map((v) => seasonsOptions.find((o) => o.value === v))
                  .filter((v): v is SelectOptionBase => !!v)}
                onChange={(v) => {
                  if (v) {
                    form.setValue(
                      "brand.activeSeasons",
                      v.map((o) => o.value),
                      { shouldValidate: true },
                    );
                  }
                }}
              />

              {/** nb exhibitions per year */}
              <InputLabel
                htmlFor="brand.numberOfYearlyCollections"
                error={errors.brand?.numberOfYearlyCollections?.message}
              >
                {t(
                  "CreateOrganizationPage.number-of-exhibitions-per-year-label",
                )}
              </InputLabel>
              <Controller
                name="brand.numberOfYearlyCollections"
                control={form.control}
                render={({ field }) => (
                  <Select
                    id="brand.numberOfYearlyCollections"
                    name="brand.numberOfYearlyCollections"
                    sort="none"
                    options={[
                      {
                        key: "yearlyCollections",
                        label: "Number of yearly collections",
                        options: yearlyCollectionsOptions,
                      },
                    ]}
                    onChange={field.onChange}
                    defaultValue={
                      field.value ? { value: field.value } : undefined
                    }
                  />
                )}
              />

              {/** active markets & product categories */}
              <InputLabel
                htmlFor="brand.activeMarkets"
                error={errors.brand?.activeMarkets?.message}
              >
                {t("CreateOrganizationPage.active-markets-label")}
              </InputLabel>
              <MultiSelect
                id="brand.activeMarkets"
                placeholder="Select an item"
                options={marketsCategoriesOptions}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    ...selectControlStyles(state),
                  }),
                  valueContainer: (base) => ({
                    ...base,
                    [`@media (min-width: 1020px)`]: {
                      padding: "0.5rem",
                      height: "46px",
                    },
                    [`@media (max-width: 1019px)`]: {
                      padding: "2px 8px",
                      height: "30px",
                    },
                  }),
                  input: (base) => ({
                    ...base,
                    margin: 0,
                    padding: 0,
                  }),
                  indicatorsContainer: (base) => ({
                    ...base,
                    [`@media (min-width: 1020px)`]: {
                      height: "46px",
                    },
                    [`@media (max-width: 1019px)`]: {
                      height: "30px",
                    },
                  }),
                }}
                value={activeMarkets
                  .map((v) => {
                    const option = marketsCategoriesOptionsFlat.find(
                      (o) => o.value === v,
                    );
                    if (!option) return undefined;
                    return {
                      label: t(`Common.market-category.${option.value}`),
                      value: option.value as string,
                    };
                  })
                  .filter((v): v is SelectOptionBase => v !== undefined)}
                onChange={(v) => {
                  if (v) {
                    form.setValue(
                      "brand.activeMarkets",
                      v.map((o) => o.value),
                      { shouldValidate: true },
                    );
                  }
                }}
              />

              {/** active locations */}
              <InputLabel
                htmlFor="brand.activeLocations"
                error={errors.brand?.activeLocations?.message}
              >
                {t("CreateOrganizationPage.active-locations-label")}
              </InputLabel>
              <CreatableSelect<{ label: string; value: string }, true>
                id="brand.activeLocations"
                placeholder="Select an item"
                styles={{
                  control: (base: any, state: any) => ({
                    ...base,
                    ...selectControlStyles(state),
                    cursor: "pointer",
                    outline: state.isFocused ? "1px solid #7B83EB" : "none",
                    borderRadius: "0.5rem",
                    "&:hover": {
                      borderColor: "#7B83EB",
                    },
                  }),
                  indicatorSeparator: () => ({
                    display: "none",
                  }),
                  dropdownIndicator: (base) => ({
                    ...base,
                    color: "#B0B0C0",
                    "&:hover": {
                      color: "#7B83EB",
                    },
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: getOptionBackground(state),
                    color: state.isSelected ? "white" : "inherit",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: state.isSelected ? "#93C5FD" : "#F5F5F5",
                    },
                  }),
                  valueContainer: (base) => ({
                    ...base,
                    [`@media (min-width: 1020px)`]: {
                      padding: "0.5rem",
                      height: "46px",
                    },
                    [`@media (max-width: 1019px)`]: {
                      padding: "2px 8px",
                      height: "30px",
                    },
                  }),
                  input: (base) => ({
                    ...base,
                    margin: 0,
                    padding: 0,
                    color: "black",
                  }),
                  indicatorsContainer: (base) => ({
                    ...base,
                    [`@media (min-width: 1020px)`]: {
                      height: "46px",
                    },
                    [`@media (max-width: 1019px)`]: {
                      height: "30px",
                    },
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: "#A6A6B0",
                  }),
                }}
                isDisabled={!canChangeBrand}
                isMulti
                options={activeLocationsOptions}
                value={activeLocations}
                components={{
                  MultiValue: MultiValueContainer,
                }}
                getNewOptionData={(v) => ({
                  label: v,
                  value: v.toUpperCase(),
                })}
                onChange={(v) => {
                  if (v) {
                    form.setValue(
                      "brand.activeLocations",
                      v as { label: string; value: string }[],
                      { shouldValidate: true },
                    );
                  }
                }}
              />
            </>
          )}
          <Button theme="PRIMARY" type="submit" className="mt-4">
            {t("CreateOrganizationPage.submit")}
          </Button>
        </form>
      </div>
    </div>
  );
}
