import React, { useMemo, useState } from "react";

import { useQueries } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { HiCheck, HiOutlinePlusCircle } from "react-icons/hi2";
import { toast } from "react-toastify";

import modaResaLogo from "@app/assets/logos/moda-resa-small-logo.png";
import BuyerBrandsRowsDisplay from "@app/modules/brand/BuyerBrandsRowsDisplay";
import MultipleBrandForm from "@app/modules/brand/MultipleBrandForm";
import { getNextFourFashionShows } from "@app/modules/crm/helpers/find-season";
import Collapse from "@components/data-display/Collapse";
import { PageHeader, PageTitle } from "@components/data-display/Page";
import Tag from "@components/data-display/Tag";
import Tooltip from "@components/data-display/Tooltip";
import Button from "@components/data-entry/Button";
import Drawer, { useDrawer } from "@components/feedback/Drawer";
import Loading from "@components/feedback/Loading";
import { ShowroomSeason } from "@models/types/enums";
import { GetBuyerBrands } from "@services/api/brands/GetBuyerBrands";
import { CreateOrderDeadlineRequest } from "@services/api/order-deadline-request/CreateOrderDeadlineRequest";
import { GetOrderDeadline } from "@services/api/order-deadline/GetOrderDeadline";
import { withBuyerOnly } from "@shared/components/guards/authentication";

interface CollapseStates {
  [key: string]: boolean;
}

function BuyerOrderDeadlinesPage() {
  const {
    data: buyerBrands,
    status: buyerBrandsStatus,
    error: buyerBrandsError,
  } = GetBuyerBrands.useHook();

  const { mutate: createOrderDeadlineRequest } =
    CreateOrderDeadlineRequest.useHook();

  // get the upcoming four fashion seasons
  const upcomingFourFashionSeasons = getNextFourFashionShows(new Date());

  // get all brands ids, curent season and current year
  const allBrandsIds = new Set(buyerBrands?.map((brand) => brand.id));
  const currentSeason = upcomingFourFashionSeasons[0].season;
  const currentYear = upcomingFourFashionSeasons[0].year;

  const inviteBrandsDrawer = useDrawer();
  // create the query array
  const queryArray = Array.from(allBrandsIds).map((id) => ({
    queryKey: GetOrderDeadline.getQueryKeys({
      brandId: id,
      season: currentSeason,
      year: currentYear,
    }),
    queryFn: () =>
      GetOrderDeadline.call({
        brandId: id,
        season: currentSeason,
        year: currentYear,
      }),
  }));

  // fetch data using useQueries
  const { data: orderDeadlines } = useQueries({
    queries: queryArray,
    combine: (queries) => ({
      data: queries.flatMap((q) => q.data || []),
      error: queries.find((q) => q.error)?.error,
      status:
        queries.find((q) => q.status === "error")?.status ||
        queries.find((q) => q.status === "pending")?.status ||
        "success",
    }),
  });

  const { t } = useTranslation();

  // track collapse states
  const [collapseStates, setCollapseStates] = useState<CollapseStates>(() => {
    if (buyerBrands) {
      return buyerBrands.reduce((acc, _, index) => {
        const key = `isCollapsed-${index}`;
        return { ...acc, [key]: false };
      }, {});
    }
    return {};
  });

  // filter buyerBrands to only include showrooms from the upcoming four fashion seasons
  const filteredBuyerBrands = useMemo(
    () =>
      buyerBrands?.map((brand) => {
        const filteredShowrooms =
          brand.organization?.showrooms.filter((showroom) =>
            upcomingFourFashionSeasons.some(
              (season) =>
                season.year === showroom.year &&
                season.season === showroom.season,
            ),
          ) || [];

        return {
          ...brand,
          organization: {
            ...brand.organization,
            showrooms: filteredShowrooms,
          },
        };
      }),
    [buyerBrands, upcomingFourFashionSeasons],
  );

  const handleCreateOrderDeadlineRequest = ({
    brandId,
    season,
    year,
  }: {
    brandId: string;
    season: ShowroomSeason;
    year: number;
  }) => {
    createOrderDeadlineRequest({
      data: {
        brandId,
        season,
        year,
      },
    });
    toast.success(t("BuyerAccount.crm.toast.order-deadline-requested"));
  };

  if (buyerBrandsStatus === "pending") {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loading type="screen" />
      </div>
    );
  }

  if (buyerBrandsError) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center">
          <p className="text-3xl mb-4 font-bold text-primaryElectricBlue">
            {t("BuyerAccount.crm.error.title")}
          </p>
          <p className="text-lg">{t("BuyerAccount.crm.error.sub-title")}</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <PageHeader>
        <div className="flex flex-row">
          <PageTitle>{t("BuyerAccount.crm.title")}</PageTitle>
        </div>
        <div className="pr-4">
          <Button
            type="button"
            theme="PRIMARY"
            className="self-end w-full"
            onClick={inviteBrandsDrawer.openDrawer}
          >
            {t("BuyerAccount.crm.create-brand.add-new-brand")}
            <HiOutlinePlusCircle className="w-6 h-6" />
          </Button>

          <Drawer
            {...inviteBrandsDrawer.props}
            isOpen={inviteBrandsDrawer.isOpen}
            name="invite-brands"
            backdrop
            drawerTitle={
              <h2 className="heading-2-mobile lg:heading-2 mb-6">
                {t("BuyerAccount.crm.create-brand.add-new-brand")}
              </h2>
            }
            size="MEDIUM"
            isScrollable
            headerPadding="px-7"
          >
            <div className="px-7 pt-5 min-h-full relative">
              <MultipleBrandForm
                onSuccess={inviteBrandsDrawer.closeWithoutConfirmation}
              />
            </div>
          </Drawer>
        </div>
      </PageHeader>
      <div className="w-full">
        <div className="pl-8 mt-7 mb-2 grid grid-cols-6">
          <p className="pl-2 font-light text-left px-3 text-sm col-span-4">
            {t("BuyerAccount.crm.main-table-header.brand-name")}
          </p>
          <p className="pl-2 font-light text-left px-3 text-sm col-span-2">
            Order deadline for {upcomingFourFashionSeasons[0].season}{" "}
            {upcomingFourFashionSeasons[0].year}
          </p>
        </div>
        <hr />
        <div className="px-8 pt-1 mt-5">
          {(filteredBuyerBrands || []).length > 0 ? (
            (filteredBuyerBrands || []).map((buyerBrand, index) => {
              const allShowrooms = buyerBrand.organization.showrooms;

              const matchingOrderDeadline = orderDeadlines.find(
                (deadline) => deadline.brandId === buyerBrand.id,
              )?.deadline;

              const matchingOrderDeadlineRequest =
                buyerBrand.OrderDeadlineRequest.find(
                  (request) =>
                    request.brandId === buyerBrand.id &&
                    request.season === currentSeason &&
                    request.year === currentYear,
                );

              return (
                <Collapse
                  key={buyerBrand.id}
                  buttonWidth="w-32"
                  title={
                    <div className="flex justify-between w-full">
                      <div className="flex items-center">
                        <div className="pl-4 text-left font-medium m-1">
                          {buyerBrand.name}
                        </div>
                        {buyerBrand.organization?.id && (
                          <div>
                            <Tooltip
                              content={t("This brand has a ModaResa account")}
                              fallbackProp="children"
                            >
                              <img
                                alt="modaresa logo"
                                width="40"
                                src={modaResaLogo}
                                className="ml-3"
                              />
                            </Tooltip>
                          </div>
                        )}
                      </div>
                      <div className="flex items-center justify-center pr-6">
                        {matchingOrderDeadline ? (
                          <Tag
                            className="ml-2 shadow-sm border-[0.5px] py-2 w-44 sm:text-sm text-primaryElectricBlue"
                            justify="center"
                          >
                            {matchingOrderDeadline?.toLocaleDateString(
                              "en-US",
                              {
                                month: "short",
                                day: "numeric",
                                year: "numeric",
                              },
                            )}
                          </Tag>
                        ) : (
                          <Button
                            disabled={!!matchingOrderDeadlineRequest}
                            justify="center"
                            theme="SECONDARY"
                            className="text-sm rounded-full"
                            onClick={() =>
                              handleCreateOrderDeadlineRequest({
                                brandId: buyerBrand.id,
                                season: currentSeason,
                                year: currentYear,
                              })
                            }
                          >
                            {matchingOrderDeadlineRequest ? (
                              <>
                                <HiCheck className="size-4" />
                                {t("BuyerAccount.crm.button.already-requested")}
                              </>
                            ) : (
                              t("BuyerAccount.crm.button.request-deadline")
                            )}
                          </Button>
                        )}
                      </div>
                    </div>
                  }
                  className={`px-4 w-full py-2 ${
                    collapseStates[`isCollapsed-${index}`]
                      ? "border border-dashed rounded-lg block border-primaryElectricBlue"
                      : "border rounded-lg border-gray-300"
                  }`}
                  buttonClassName="!px-0 !pt-0 "
                  contentClassName="!px-0 !pb-0 mb-4 "
                  collapsed={collapseStates[`isCollapsed-${index}`]}
                  onToggle={() => {
                    setCollapseStates((prevStates) => ({
                      ...prevStates,
                      [`isCollapsed-${index}`]:
                        !prevStates[`isCollapsed-${index}`],
                    }));
                  }}
                  titleJustify="between"
                  addText
                  removeTitleSpacing
                >
                  <BuyerBrandsRowsDisplay
                    key={buyerBrand.id}
                    buyerBrand={{
                      ...buyerBrand,
                      organization: {
                        ...buyerBrand.organization,
                        showrooms: allShowrooms.slice().sort((a, b) => {
                          // display newest showroom on top
                          const dayA = Number(a.openingDays[0]?.day) || 0;
                          const dayB = Number(b.openingDays[0]?.day) || 0;
                          return dayB - dayA;
                        }),
                      },
                    }}
                  />
                </Collapse>
              );
            })
          ) : (
            <div className="flex justify-center h-screen">
              <div className="text-center">
                <p className="text-3xl mb-4 font-bold text-primaryElectricBlue">
                  {t("BuyerAccount.crm.empty.title")}
                </p>
                <p className="text-lg">
                  {t("BuyerAccount.crm.empty.sub-title")}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default withBuyerOnly(BuyerOrderDeadlinesPage);
