import React, { useEffect, useId, useState } from "react";

import { Portal, Transition } from "@headlessui/react";
import { t } from "i18next";
import { useOnClickOutside } from "usehooks-ts";

import { fadeSlideInOutProps } from "@components/transitions/FadeInOut";
import { setTimeOfDate } from "@helpers/Date";
import usePopup from "@helpers/Popup";

export const SORT_LABEL = "label";
export const SORT_NONE = "none";
export type Sorting = typeof SORT_LABEL | typeof SORT_NONE;

function getSortFn(sort: Sorting) {
  switch (sort) {
    case SORT_LABEL:
      return (a: OptionItem, b: OptionItem) => a.label.localeCompare(b.label);
    case SORT_NONE:
      // always returns 0 so that the array is not sorted
      return () => 0;
    default:
      return () => 0;
  }
}

export type OptionGroupItem = {
  key: string;
  label: string;
  options: OptionItem[];
};

export type OptionItem = {
  key?: string;
  icon?: JSX.Element;
  label: string;
  subLabel?: string;
  disabled?: boolean;
  value: any;
};

interface AutocompleteInputProps {
  id?: string;
  name: string;
  placeholder?: string;
  options: OptionGroupItem[];
  onChange: (options: OptionGroupItem[]) => void;
}

function AutocompleteInput({
  id,
  name,
  placeholder,
  options,
  onChange,
}: AutocompleteInputProps) {
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    const filtered = options
      .map((group) => {
        const filteredGroup = group.options.filter((option) =>
          option.label.toLowerCase().includes(search.toLowerCase()),
        );
        return filteredGroup.length > 0
          ? { ...group, options: filteredGroup }
          : null;
      })
      .filter((group) => group !== null);
    onChange(filtered as OptionGroupItem[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, options]);

  return (
    <input
      id={id}
      name={name}
      title={`input text to search for ${name}`}
      type="text"
      className="w-full outline-none border border-primaryGrey focus:border-primaryElectricBlue focus:ring-grey shadow rounded-lg form-select"
      placeholder={placeholder}
      autoComplete="off"
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
      onChange={(e) => setSearch(e.target.value)}
    />
  );
}

interface DefaultItem {
  icon?: JSX.Element;
  label?: string;
  value: any;
}

interface SelectProps {
  id?: string;
  name: string;
  icon?: JSX.Element;
  className?: string;
  placeholder?: string;
  showGroups?: boolean;
  sort?: Sorting;
  autocomplete?: boolean;
  placeholderInput?: string;
  disabled?: boolean;
  firstOption?: DefaultItem;
  defaultValue?: DefaultItem | null;
  width?: "FULL" | "FIT";
  onChange: (value: any) => void;
  onBlur?: () => void;
  options: OptionGroupItem[];
}

function Select(props: SelectProps) {
  const key = useId();
  const {
    id,
    name,
    placeholder = "Select an item",
    placeholderInput,
    defaultValue,
    onChange,
    onBlur,
    options,
    className,
    showGroups,
    sort = SORT_LABEL,
    autocomplete = false,
    disabled = false,
    firstOption,
    icon,
    width = "FULL",
  } = props;
  const { label: defLabel, value, icon: valueIcon } = defaultValue || {};

  const label = value
    ? defLabel ||
      options.flatMap((g) => g.options.filter((o) => o.value === value))[0]
        ?.label
    : undefined;

  const [currentOptions, setCurrentOptions] =
    useState<OptionGroupItem[]>(options);

  // In case the options has any delay to contains the data
  useEffect(() => {
    // flattens to get an array of all possible values
    const optionsLabels = options.flatMap((g) =>
      g.options.flatMap((o) => o.label),
    );
    const currentOptionsLabels = currentOptions.flatMap((g) =>
      g.options.flatMap((o) => o.label),
    );
    // order the possible values
    optionsLabels.sort();
    currentOptionsLabels.sort();

    // check if the strings of concatenated values are equal
    if (optionsLabels.join("-") !== currentOptionsLabels.join("-")) {
      setCurrentOptions(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const [IsOpen, setIsOpen] = useState<boolean>(false);

  const popup = usePopup({
    placement: "bottom",
    hasOffset: [0, 0],
    sameWidth: true,
  });

  const isMatchTime = (optValue: Date) =>
    setTimeOfDate(optValue).toTimeString() ===
    setTimeOfDate(value).toTimeString();

  useOnClickOutside(popup.popupElement, () => {
    if (IsOpen && onBlur) onBlur();
    setIsOpen(false);
  });

  const selectBoxClassNames = width === "FIT" && "min-w-fit";
  const listClassNames = width === "FIT" && "min-w-max";

  return (
    <div className="w-full text-gray-700">
      <div ref={popup.setReferenceElement}>
        {IsOpen && autocomplete && (
          <AutocompleteInput
            id={id}
            name={name}
            placeholder={placeholderInput}
            options={options}
            onChange={(values) => {
              setCurrentOptions(values);
            }}
          />
        )}

        {((autocomplete && !IsOpen) || !autocomplete) && (
          <button
            onClick={() => {
              setIsOpen(!IsOpen);
            }}
            id={id}
            disabled={disabled}
            type="button"
            className={`flex text-left items-center justify-start w-full h-12 gap-2 border rounded-lg form-select focus:border-primaryElectricBlue ${
              !value && "text-grey"
            } ${
              !disabled ? "cursor-pointer" : "bg-primaryLightestGrey"
            } ${className}`}
          >
            {!valueIcon && icon && icon}
            {valueIcon && valueIcon}
            <span className="whitespace-nowrap">{label || placeholder}</span>
          </button>
        )}
      </div>
      <Portal as="div" className="fixed z-select">
        <div
          ref={popup.setPopupElement}
          {...popup.attributes.popper}
          style={popup.styles.popper}
        >
          <Transition
            show={IsOpen}
            className={`bg-white border border-primaryGrey rounded w-full overflow-x-hidden overflow-y-auto max-h-44 ${selectBoxClassNames}`}
            {...fadeSlideInOutProps}
          >
            <nav title={`options for ${name}`}>
              <ul className={`w-full min-w-max py-2 ${listClassNames}`}>
                {firstOption && (
                  <li
                    key={`${key}_first_option`}
                    className="flex items-center justify-start w-full gap-2 px-4 py-2 text-primaryBlack bg-white cursor-pointer hover:bg-gray-100"
                  >
                    <button
                      type="button"
                      onClick={() => {
                        onChange(firstOption.value);
                        setIsOpen(false);
                      }}
                    >
                      {firstOption.icon && firstOption.icon}
                      <span>{firstOption.label}</span>
                    </button>
                  </li>
                )}
                {currentOptions.length > 0 ? (
                  currentOptions.map((group: OptionGroupItem) => (
                    <li key={`${key}_${group.key}`}>
                      {showGroups && (
                        <span className="p-2 leading-9 uppercase text-primaryDarkGrey">
                          {group.label} ({group.options.length})
                        </span>
                      )}
                      {group.options && (
                        <ul>
                          {group.options
                            .sort(getSortFn(sort))
                            .map((option: OptionItem) => {
                              const isSelected =
                                value instanceof Date &&
                                option.value instanceof Date
                                  ? isMatchTime(option.value)
                                  : option.value === value;

                              const buttonClass = `flex items-center text-primaryBlack justify-start w-full gap-2 px-4 py-2 cursor-pointer ${
                                // eslint-disable-next-line no-nested-ternary
                                isSelected
                                  ? "bg-blue-200"
                                  : option.disabled
                                    ? "cursor-not-allowed line-through bg-primaryLightestGrey"
                                    : "bg-white hover:bg-gray-100"
                              } ${showGroups && "pl-6"}`;
                              return (
                                <li key={`${key}_${option.value}`}>
                                  <button
                                    type="button"
                                    onClick={() => {
                                      if (!option.disabled) {
                                        if (!isSelected) onChange(option.value);
                                        setIsOpen(false);
                                      }
                                    }}
                                    className={buttonClass}
                                  >
                                    {option.icon && option.icon}
                                    <span className="text-left">
                                      {option.label}
                                    </span>
                                    {option.subLabel && (
                                      <span className="px-3 text-sm border rounded-full bg-primaryLightElectricBlue">
                                        {option.subLabel}
                                      </span>
                                    )}
                                  </button>
                                </li>
                              );
                            })}
                        </ul>
                      )}
                    </li>
                  ))
                ) : (
                  <li className="w-full px-2">
                    <span className="py-2 pl-2 leading-8 text-grey">
                      {t("Common.no-options-available")}
                    </span>
                  </li>
                )}
              </ul>
            </nav>
          </Transition>
        </div>
      </Portal>
    </div>
  );
}

export default Select;
