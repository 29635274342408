import React, { useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi2";
import { toast } from "react-toastify";

import modaResaLogo from "@app/assets/logos/moda-resa-small-logo.png";
import { BlueRow } from "@components/data-display/BlueRow";
import Tag from "@components/data-display/Tag";
import Tooltip from "@components/data-display/Tooltip";
import Button from "@components/data-entry/Button";
import ConfirmModal from "@components/feedback/ConfirmModal";
import Loading from "@components/feedback/Loading";
import Modal, { useModal } from "@components/feedback/Modal";
import { CollectionArchiveEndpoint } from "@services/api/collections/archive";
import { CollectionCreateEndpoint } from "@services/api/collections/create";
import { UpdateCollectionEndpoint } from "@services/api/collections/update";
import { GetOrganizationCollectionsWithCapabilitiesEndpoint } from "@services/api/organization/get-collections-with-capabilities";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";

import CollectionForm from "./CollectionForm";
import { marketCategoriesGrouped } from "./helpers";

interface Collection {
  id: string;
  name: string;
  tags: string[];
}

interface BrandWithCollections {
  brandName: string;
  brandId: string;
  organizationId: string | null;
  collections: Collection[];
}

export interface DeleteCollectionMutationParams {
  collectionId: string;
}

export interface DeleteCollectionHookParams {
  organizationId: string;
}

export function useCollectionList() {
  const createModalState = useModal();
  const editModalState = useModal();
  const [selectedBrandId, setSelectedBrandId] = useState<string | null>(null);
  const [collectionToEdit, setCollectionToEdit] = useState<Collection | null>(
    null,
  );

  const { organization } = useOrganizationAppContext();

  const {
    data: collections = [],
    status: collectionListStatus,
    error: collectionListError,
  } = GetOrganizationCollectionsWithCapabilitiesEndpoint.useHook({
    organizationId: organization.id,
  });

  const openCreateModalWithBrand = (brandId: string) => {
    setSelectedBrandId(brandId);
    createModalState.open();
  };

  const openEditModalWithCollection = (
    collection: Collection,
    brandId: string,
  ) => {
    setCollectionToEdit(collection);
    setSelectedBrandId(brandId);
    editModalState.open();
  };

  const brandsWithCollections = collections.reduce<BrandWithCollections[]>(
    (acc, coll) => {
      if (coll.brand) {
        const { name: brandName, id: brandId, organizationId } = coll.brand;
        const collectionData = {
          id: coll.id,
          name: coll.name,
          tags: coll.tags,
        };
        // check if the brand already exists in the accumulator
        const existingBrand = acc.find(
          (brand) => brand.brandName === brandName,
        );

        // if the brand doesn't exist - add a new entry for the brand
        if (!existingBrand) {
          acc.push({
            brandName,
            brandId,
            organizationId,
            collections: [collectionData],
          });

          // if the brand exists - push the collection data to the brand's collections array
        } else {
          existingBrand.collections.push(collectionData);
        }
      }
      return acc;
    },
    [],
  );

  return {
    brandsWithCollections,
    collectionListStatus,
    collectionListError,
    selectedBrandId,
    setSelectedBrandId,
    openCreateModalWithBrand,
    openEditModalWithCollection,
    createModalState,
    editModalState,
    collectionToEdit,
    setCollectionToEdit,
    organization,
  };
}

type CollectionListProps = ReturnType<typeof useCollectionList>;

function CollectionList({
  brandsWithCollections,
  collectionListStatus,
  collectionListError,
  selectedBrandId,
  openCreateModalWithBrand,
  openEditModalWithCollection,
  createModalState,
  editModalState,
  collectionToEdit,
  setCollectionToEdit,
  organization,
}: CollectionListProps) {
  const { t } = useTranslation();
  const { id: organizationId, brand: organizationBrand } = organization;

  const [collectionToDelete, setCollectionToDelete] = useState<
    Collection | undefined
  >();

  const { mutateAsync: deleteCollectionMutation } =
    CollectionArchiveEndpoint.useHook({
      organizationId: organization.id,
    });

  const { mutateAsync: createCollectionMutation } =
    CollectionCreateEndpoint.useHook({
      organizationId,
    });

  const { mutateAsync: updateCollectionMutation } =
    UpdateCollectionEndpoint.useHook({
      organizationId,
    });

  const deleteCollection = (collection: Collection) => {
    deleteCollectionMutation({ collectionId: collection.id }).then(() => {
      setCollectionToDelete(undefined);
      toast.success(
        t("OrganizationProfile.collections.success.collection-deleted"),
      );
    });
  };

  if (collectionListError) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center">
          <p className="text-3xl mb-4 font-bold text-primaryElectricBlue">
            {t("Shared.AgencyCollectionForm.error-title")}
          </p>
          <p className="text-lg">
            {t("Shared.AgencyCollectionForm.error-subtitle")}
          </p>
        </div>
      </div>
    );
  }

  if (collectionListStatus === "pending") {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loading type="screen" />
      </div>
    );
  }

  return (
    <div>
      {organizationBrand && brandsWithCollections.length === 0 && (
        <Button
          className="mb-4"
          theme="PRIMARY"
          onClick={() => openCreateModalWithBrand(organizationBrand.id)}
        >
          {t("OrganizationProfile.collections.add-new-collection")}
        </Button>
      )}
      {brandsWithCollections.length > 0 && (
        <>
          <div className="grid grid-cols-2 mb-2">
            <p className="font-medium">
              {t("Shared.AgencyCollectionForm.collection-name")}
            </p>
            <p className="font-medium pl-4">
              {t("Shared.AgencyCollectionForm.market-categories")}
            </p>
          </div>
          <hr />
          {(brandsWithCollections || []).map((brand) => (
            <div className="mt-8" key={brand.brandId}>
              <div className="flex items-center">
                <p className="pl-1 pb-1 text-xl font-bold text-primaryElectricBlue mr-2">
                  {brand.brandName}
                </p>
                <Button
                  theme="SECONDARY"
                  className="px-3 py-1"
                  onClick={() => openCreateModalWithBrand(brand.brandId)}
                  label={t("OrganizationProfile.collections.add-collection")}
                >
                  +
                </Button>

                {brand.organizationId && organization.type === "AGENCY" && (
                  <div>
                    <Tooltip
                      content={t("This brand has a ModaResa account")}
                      fallbackProp="children"
                    >
                      <img
                        alt="modaresa logo"
                        width="40"
                        src={modaResaLogo}
                        className="ml-3"
                      />
                    </Tooltip>
                  </div>
                )}
              </div>
              {brand.collections.map((collection) => (
                <BlueRow key={collection.id} colsClass="grid-cols-4">
                  <div className="py-2 px-4 flex items-center col-span-2">
                    <p>{collection.name}</p>
                  </div>
                  <div className="py-2 px-3 flex flex-wrap items-center col-span-1">
                    {collection.tags?.map((tag) => (
                      <div
                        key={`${collection.id}-${tag}`}
                        className="mx-0.5 my-0.5"
                      >
                        <Tag
                          className="text-white text-sm bg-opacity-90"
                          theme="PRIMARY-ELECTRIC-BLUE"
                        >
                          <span key={tag}>
                            {marketCategoriesGrouped
                              .flatMap((category) =>
                                category.options.map((option) => {
                                  if (option.value === tag) {
                                    // construct translation key without duplicating "HOME"
                                    const translationKey =
                                      category.label === "HOME"
                                        ? `Common.market-category.HOME`
                                        : `Common.market-category.${category.label}_${option.label}`;
                                    return t(translationKey);
                                  }
                                  return null;
                                }),
                              )
                              .filter((label) => label)
                              .join(" | ")}
                          </span>
                        </Tag>
                      </div>
                    ))}
                  </div>
                  {/* agencies can edit and delete collections only for brands that don't have a ModaResa account */}
                  {((organization.type === "AGENCY" && !brand.organizationId) ||
                    organization.type === "BRAND") && (
                    <div className="col-span-1 flex items-center justify-end">
                      <div className="flex items-center justify-end gap-4 p-2">
                        <Button
                          type="button"
                          theme="ICON"
                          onClick={() =>
                            openEditModalWithCollection(
                              collection,
                              brand.brandId,
                            )
                          }
                          label={t(
                            "OrganizationProfile.collections.edit-collection",
                          )}
                        >
                          <HiOutlinePencil className="w-4 h-4 text-primaryElectricBlue" />
                        </Button>
                        <Button
                          type="button"
                          theme="ICON"
                          onClick={() => setCollectionToDelete(collection)}
                          label={t(
                            "OrganizationProfile.collections.delete-collection",
                          )}
                        >
                          <HiOutlineTrash className="w-4 h-4 text-statusRedDark" />
                        </Button>
                      </div>
                    </div>
                  )}
                </BlueRow>
              ))}
            </div>
          ))}
          {/* Moved from collections.tsx to this location to prevent the <hr> from displaying when there are no collections */}
          <hr className="mt-4" />
        </>
      )}

      <Modal
        title={t("OrganizationProfile.collections.new-collection")}
        state={createModalState}
        centeredTitle
        padding="p-9"
        aria-label={t("OrganizationProfile.collections.new-collection")}
      >
        <div className="px-28">
          <CollectionForm
            onSuccess={async () => {
              createModalState.close();
              toast.success(
                t("OrganizationProfile.collections.success.collection-created"),
              );
            }}
            initialValues={{
              collectionName: "",
              marketCategories: [],
            }}
            onSubmit={async (data) => {
              createCollectionMutation({
                data: {
                  brandId: selectedBrandId || "",
                  name: data.collectionName || "",
                  tags: data.marketCategories || [],
                },
              }).catch(() => {
                toast.error(
                  t(
                    "OrganizationProfile.collections.failed.collection-created",
                  ),
                );
              });
            }}
          />
        </div>
      </Modal>
      <Modal
        title={`Edit collection ${collectionToEdit?.name}`}
        state={editModalState}
        centeredTitle
        padding="p-9"
        aria-label={t("OrganizationProfile.collections.edit-collection")}
      >
        <div className="px-28">
          <CollectionForm
            initialValues={{
              collectionName: collectionToEdit?.name || "",
              marketCategories: collectionToEdit?.tags || [],
            }}
            onSuccess={async () => {
              editModalState.close();
              setCollectionToEdit(null);
              toast.success(
                t("OrganizationProfile.collections.success.collection-updated"),
              );
            }}
            onSubmit={async (data) => {
              updateCollectionMutation({
                collectionId: collectionToEdit?.id || "",
                data: {
                  id: collectionToEdit?.id || "",
                  brandId: selectedBrandId || "",
                  name: data.collectionName || "",
                  tags: data.marketCategories || [],
                },
              }).catch(() => {
                toast.error(
                  t(
                    "OrganizationProfile.collections.failed.collection-updated",
                  ),
                );
              });
            }}
          />
        </div>
      </Modal>
      <ConfirmModal
        show={collectionToDelete !== undefined}
        onConfirm={
          () =>
            collectionToDelete
              ? deleteCollection(collectionToDelete)
              : setCollectionToDelete(undefined) // force to undefined in case we had a falsy value for some reason
        }
        onCancel={() => setCollectionToDelete(undefined)}
        title={t("OrganizationProfile.collections.delete.title")}
        confirmLabel={t("Common.yes-delete")}
        aria-label={t("OrganizationProfile.collections.delete-collection")}
      >
        <Trans
          i18nKey="OrganizationProfile.collections.delete.content"
          values={{ name: collectionToDelete?.name || "" }}
        >
          OrganizationProfile.collections.delete.content
          <strong className="font-medium">collection</strong>.
          <br />
        </Trans>
      </ConfirmModal>
    </div>
  );
}

export default CollectionList;
