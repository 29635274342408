import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { collectionSchema } from "@models/Collection";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";
import { GetOrganizationCollectionsEndpoint } from "@services/api/organization/get-collections";
import { GetOrganizationCollectionsWithCapabilitiesEndpoint } from "@services/api/organization/get-collections-with-capabilities";

// @todo rename endpoint
export namespace CollectionCreateEndpoint {
  export const inputSchema = collectionSchema.pick({
    name: true,
    brandId: true,
    tags: true,
  });
  export type Input = z.infer<typeof inputSchema>;

  export const outputSchema = collectionSchema.pick({
    name: true,
  });
  export type Output = z.infer<typeof outputSchema>;

  interface HookParams {
    organizationId: string;
  }

  interface MutationParams {
    data: Input;
  }

  type Params = HookParams & MutationParams;

  export const path = ({ organizationId }: Params) =>
    `/organizations/${organizationId}/collections`;

  export const call = (params: Params) =>
    axiosInstance
      .post(path(params), inputSchema.parse(params.data))
      .then((res) => outputSchema.parse(res.data));

  export function useHook(hookParams: HookParams) {
    const { organizationId } = hookParams;
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (mutationParams: MutationParams) =>
        call({
          ...mutationParams,
          ...hookParams,
        }),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getAPIQueryKey(
            GetOrganizationCollectionsEndpoint.path({ organizationId }),
          ),
        });
        queryClient.invalidateQueries({
          queryKey: getAPIQueryKey(
            GetOrganizationCollectionsWithCapabilitiesEndpoint.path({
              organizationId,
            }),
          ),
        });
      },
    });
  }
}
