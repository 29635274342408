import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { organizationContactSchema } from "@models/OrganizationContact";
import axiosInstance from "@services/api/config";

import { getFullAccountsEndpoint } from "../accounts/get-full-accounts";
import { getAPIQueryKey } from "../helper";

export namespace ImportOrganizationContacts {
  export const inputSerializingSchema = z.object({
    contacts: z.array(
      organizationContactSchema.pick({
        firstName: true,
        lastName: true,
        email: true,
        accountId: true,
        position: true,
        phoneNumber: true,
        markets: true,
      }),
    ),
  });
  export type Input = z.input<typeof inputSerializingSchema>;

  export const outputHydratingSchema = z.object({});
  export type Output = z.output<typeof outputHydratingSchema>;

  interface HookParams {
    organizationId: string;
  }

  interface MutationParams extends Input {}

  type Params = HookParams & MutationParams;

  export const path = ({ organizationId }: Params) =>
    `/organizations/${organizationId}/contacts/import`;

  export const call = (params: Params) =>
    axiosInstance
      .post(path(params), inputSerializingSchema.parse(params))
      .then((res) => outputHydratingSchema.parse(res.data));

  export function useHook(hookParams: HookParams) {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (mutationParams: MutationParams) =>
        call({
          ...mutationParams,
          ...hookParams,
        }),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getAPIQueryKey(
            getFullAccountsEndpoint.path(hookParams.organizationId),
          ),
        });
      },
    });
  }
}
