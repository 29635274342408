import React, { useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { FcGoogle } from "react-icons/fc";
import { HiExternalLink } from "react-icons/hi";
import { TiVendorMicrosoft } from "react-icons/ti";
import { useNavigate, useSearchParams } from "react-router-dom";

import InputLabel from "@components/data-display/InputLabel";
import Checkbox from "@components/data-entry/Checkbox";
import useAuthentication from "@services/authentication/useAuthentication";

import { accessTypeButtonStyle } from "./styles";

interface SignupInput {
  terms: boolean;
}

type SignupContext = "brand-signup" | "retailer-signup";

export default function SignUpForm({
  hideContextSwitch = false,
  hideSignInLink = false,
}: {
  hideContextSwitch?: boolean;
  hideSignInLink?: boolean;
}) {
  const navigate = useNavigate();
  const { microsoftSignIn, googleSignIn } = useAuthentication();
  const [searchParams] = useSearchParams();
  const [accessType, setAccessType] = useState<SignupContext>(
    (searchParams.get("context") as SignupContext) || "brand-signup",
  );
  const { control, watch } = useForm<SignupInput>({
    defaultValues: {
      terms: false,
    },
  });
  const termsAreAccepted = watch("terms");

  return (
    <>
      {!hideContextSwitch && (
        <div className="grid grid-cols-2 gap-2 mb-4 p-2 bg-primaryLightElectricBlue rounded-t-lg">
          <button
            type="button"
            className={accessTypeButtonStyle({
              active: accessType === "brand-signup",
            })}
            onClick={() => setAccessType("brand-signup")}
          >
            I am a brand
          </button>
          <button
            type="button"
            className={accessTypeButtonStyle({
              active: accessType === "retailer-signup",
            })}
            onClick={() => setAccessType("retailer-signup")}
          >
            I am a retailer
          </button>
        </div>
      )}
      <div className="rounded-t mb-0 px-6 py-6 space-y-4">
        <div className="text-center mb-3">
          <h6 className="text-sm font-bold">
            Your company doesn&apos;t have an account on ModaResa? Create one
            now!
          </h6>
        </div>
        <Controller
          control={control}
          name="terms"
          render={({ field }) => (
            <div className="flex gap-2 items-center justify-center">
              <InputLabel htmlFor="terms">
                <span className="flex gap-1">
                  Accept the{" "}
                  <a
                    className="text-primaryBlue underline flex gap items-center"
                    href="https://drive.google.com/file/d/1lZWcGCy_nIF9lOM56r5559ti-cblFS_f/view?usp=sharing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <HiExternalLink />
                    terms & conditions
                  </a>
                </span>
              </InputLabel>
              <Checkbox
                name="terms"
                id="terms"
                checked={field.value}
                onChange={field.onChange}
              />
            </div>
          )}
        />
        <div className="flex gap-2 items-center justify-center">
          <button
            className="hover:bg-gray-100 disabled:cursor-not-allowed disabled:bg-slate-200 disabled:text-slate-700 bg-white active:bg-slate-200 text-slate-700 px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
            type="button"
            disabled={!termsAreAccepted}
            onClick={() => googleSignIn(accessType)}
          >
            <FcGoogle />
            Google
          </button>
          <button
            className="hover:bg-gray-100 disabled:cursor-not-allowed disabled:bg-slate-200 disabled:text-slate-700 bg-white active:bg-slate-200 text-slate-700 px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
            type="button"
            disabled={!termsAreAccepted}
            onClick={() => microsoftSignIn(accessType)}
          >
            <TiVendorMicrosoft />
            Microsoft
          </button>
        </div>

        {!hideSignInLink && (
          <>
            <div className="flex gap-2 items-center my-4">
              <hr className="grow border-b-1 " />
              <span>OR</span>
              <hr className="grow border-b-1 " />
            </div>
            <div className="flex flex-col gap-2 items-stretch">
              <h6 className=" text-sm font-bold">
                Your company already has an account on ModaResa?
              </h6>
              <button
                type="button"
                className="hover:bg-gray-100 disabled:cursor-not-allowed disabled:bg-slate-200 disabled:text-slate-700 bg-white active:bg-slate-200 text-slate-700 px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow inline-flex justify-center font-bold text-xs ease-linear transition-all duration-150"
                onClick={() =>
                  navigate(
                    `/login?context=${accessType === "brand-signup" ? "brand-signin" : "retailer-signin"}`,
                  )
                }
              >
                Sign in
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
