import React, { PropsWithChildren } from "react";

import { cn } from "@app/lib/utils";

export function Page({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return <div className={cn("flex flex-col gap-4", className)}>{children}</div>;
}

export function PageSection({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <section className={cn("flex flex-col px-4", className)}>
      {children}
    </section>
  );
}

export function PageTitle({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return <h1 className={cn("heading-1 p-4", className)}>{children}</h1>;
}

export function PageHeader({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <div
      className={cn("flex min-md:grow items-center justify-between", className)}
    >
      {children}
    </div>
  );
}
