import React from "react";

import { useQueries } from "@tanstack/react-query";
import { format } from "date-fns";
import { FaRegHourglassHalf } from "react-icons/fa6";

import { GetOrderDeadline } from "@services/api/order-deadline/GetOrderDeadline";

interface DisplayOrderDeadlineModalProps {
  uniqueBrands: Map<string, { brandName: string; collectionName: string }>;
  season: string;
  year: number;
}

function DisplayOrderDeadlineModal({
  uniqueBrands,
  season,
  year,
}: DisplayOrderDeadlineModalProps) {
  // create a query array with the unique brand IDs and the common season and year
  const queryArray = Array.from(uniqueBrands).map(([brandId]) => ({
    queryKey: GetOrderDeadline.getQueryKeys({
      brandId,
      season,
      year,
    }),
    queryFn: () =>
      GetOrderDeadline.call({
        brandId,
        season,
        year,
      }),
  }));

  // fetch data using useQueries
  const { data: orderDeadlines, error: orderDeadlinesError } = useQueries({
    queries: queryArray,
    combine: (queries) => ({
      data: queries.flatMap((q) => q.data || []),
      error: queries.find((q) => q.error)?.error,
      status:
        queries.find((q) => q.status === "error")?.status ||
        queries.find((q) => q.status === "pending")?.status ||
        "success",
    }),
  });

  if (orderDeadlinesError) {
    return <div>Error loading order deadlines</div>;
  }

  if (!orderDeadlines) {
    return <div>Loading order deadlines...</div>;
  }

  return (
    <div>
      <ul>
        {orderDeadlines.map((deadline) => {
          const brandInfo = uniqueBrands.get(deadline.brandId);
          return (
            <li
              className="flex items-center gap-2"
              key={`${deadline.brandId}-${deadline.season}-${deadline.year}`}
            >
              <span className="text-primaryElectricBlue">
                {brandInfo?.collectionName} - {brandInfo?.brandName}{" "}
              </span>{" "}
              :
              <div className="flex items-center gap-2">
                <FaRegHourglassHalf className="w-2.5 flex ml-0.5" />
                {format(deadline.deadline, "MMM d, yyyy")}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default DisplayOrderDeadlineModal;
